import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ["entries", "pagination"]
  static values = { lazyLoadUrl: String }

  initialize() {
    this.shouldLoad = true;
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    if (this.shouldLoad) {
      this.intersectionObserver.observe(this.paginationTarget)
    }
    this.hasLazyLoadUrlValue && this.lazyLoad(this.lazyLoadUrlValue);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    let hasPagination = this.paginationTarget.querySelector(".pagination");
    if (hasPagination == null) return;
    entries.forEach(entry => {
      if (entry.isIntersecting && this.shouldLoad) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      this.paginationTarget.innerHTML = "<p class='my-3 text-center'>You have reached the end of the page.</p>"
      return;
    }
    let url = next_page.href
    this.shouldLoad = false;

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.shouldLoad = true;
        Alpine.discoverUninitializedComponents(function(el){ Alpine.initializeComponent(el) });
      }
    })
  }

  lazyLoad(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.innerHTML =  data.entries;
        this.paginationTarget.innerHTML = data.pagination;
        Alpine.discoverUninitializedComponents(function(el){ Alpine.initializeComponent(el) });
      }
    });
  }
}
