import { Controller } from "@hotwired/stimulus"

export class Elements{
  createElement(htmlTag, cssClasses, options = {}){
    var htmlElement = document.createElement(htmlTag);
    htmlElement= this.modifyExistingElement(htmlElement, cssClasses, options);
    return htmlElement;
  }

  modifyExistingElement(htmlElement, cssClasses, options = {}){
    htmlElement.classList.add(...cssClasses);
    if(options.style)
      htmlElement.style = options.style;
    if (options.attributes){
      for (var a in options.attributes) {
        htmlElement.setAttribute(a, options.attributes[a])
      }
    }
    if (options.innerText)
      htmlElement.innerText = options.innerText;
    if (options.dataset)
      for (var a in options.dataset) {
        htmlElement.dataset[a]= options.dataset[a];
      }
    return htmlElement
  }

  toggleClassToElements(htmlElements, cssClasses){
    htmlElements.forEach(htmlElement => {
      cssClasses.forEach(cssClass => {
        document.querySelector(htmlElement).classList.toggle(cssClass);

      })
    });
  }

  toggleClassToElements(htmlElements, cssClasses, isAdd){
    htmlElements.forEach(htmlElement => {
      cssClasses.forEach(cssClass => {
        document.querySelector(htmlElement).classList.toggle(cssClasses,isAdd);
      })
    });
  }
}
