import { Controller } from "@hotwired/stimulus";

const Choices = require("choices.js");

export default class extends Controller {
  static targets = ["choice", "toggler"];
  static values = { options: String, removeItemButton: Boolean, searchDisabled: Boolean };

  connect() {
    if (typeof this.choicesInput !== 'object') {
      this.choicesInput = new Choices(this.choiceTarget, {
        classNames: {
          containerOuter: `choices ${this.choiceTarget.getAttribute("data-additional-outer-class") || ""}`,
        },
        searchEnabled: this.searchDisabledValue ? false : true,
        removeItemButton: this.hasRemoveItemButtonValue ? this.removeItemButtonValue : true,
        callbackOnCreateTemplates: function(template) {
          return {
            item: (classNames, data) => {
              if (!data.customProperties) {
                return Choices.defaults.templates.item.call(this, classNames, data, this.config.removeItemButton);
              }
              const  { image_src, status } = JSON.parse(data.customProperties);
              const highlightedClass = data.highlighted ? classNames.highlightedState : classNames.itemSelectable;
              const placeholderClass = data.placeholder ? classNames.placeholder : '';

              return template(`
                <div class="${classNames.item} ${highlightedClass} ${placeholderClass}"
                  data-item
                  data-id="${data.id}"
                  data-value="${data.value}"
                  ${data.active ? 'aria-selected="true"' : ''}
                  ${data.disabled ? 'aria-disabled="true"' : ''}
                >
                  <div class="choice-image-item">
                    ${image_src ? '<image src=' + image_src + ' class="choice-image-item__preview" />' : ""}
                    ${status ? '<span class="planning-periods-status planning-periods-status--' + status.replace(/_/g, '-') + '" ></span>' : ""}
                    <span class="choice-image-item__label">${data.label}</span>
                  </div>
                </div>
              `);
            },
            choice: (classNames, data) => {
              if (!data.customProperties) {
                return Choices.defaults.templates.choice.call(this, classNames, data, this.config.itemSelectText);
              }

              const { image_src, status } = JSON.parse(data.customProperties);
              const disabledClass = data.disabled ? classNames.itemDisabled : classNames.itemSelectable;
              const selectedClass = data.selected ? classNames.selectedState : '';

              return template(`
                  <div class="${classNames.item} ${classNames.itemChoice} ${disabledClass} ${selectedClass}"
                    data-select-text="${this.config.itemSelectText}"
                    data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
                    data-id="${data.id}"
                    data-value="${data.value}"
                    ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
                  >
                    <div class="choice-image-item">
                      ${image_src ? '<image src=' + image_src + ' class="choice-image-item__preview" />' : ""}
                      ${status ? '<span class="planning-periods-status planning-periods-status--' + status.replace(/_/g, '-') + '" ></span>' : ""}
                      <span class="choice-image-item__label">${data.label}</span>
                    </div>
                  </div>
              `);
            }
          }
        }
      });
      this.choicesInput.clearInput();

      if (this.hasTogglerTarget) {
        this.isChecked = this.togglerTarget.ariaChecked;
      }
    }
  }

  toggle(e) {
    const options = JSON.parse(this.optionsValue);
    this.choicesInput.removeActiveItems();
    if (this.isChecked === "false" || !this.isChecked) {
      this.isChecked = true;
      this.choicesInput.clearChoices();
      this.choicesInput.setValue(options);
      this.choicesInput.clearInput();
    } else {
      this.isChecked = false;
      this.choicesInput.setChoices(options, 'value', 'label', true);
    }
  }
}
