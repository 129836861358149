import { Controller } from "@hotwired/stimulus";
import mql from "@microlink/mql";
import validUrl from "valid-url";

export default class extends Controller {
  static targets = [
    "input",
    "status",
    "titleInput",
    "descriptionInput",
    "output",
    "imageInput",
    "titleOutput",
    "descriptionOutput",
    "imageOutput",
    "hostOutput",
  ];

  static values = {
    apiKey: String,
  };

  connect() {
    this.form = this.element.closest("form");
  }

  async fetchData() {
    const url = this.inputTarget.value.startsWith("https://")
      ? this.inputTarget.value
      : `https://${this.inputTarget.value}`;

    this.inputTarget.value = url;
    if (validUrl.isUri(url)) {
      this.disableForm();
      try {
        const { status, data } = await mql(url, { apiKey: this.apiKeyValue });
        if (status === "success") {
          this.setFormData(data);
          this.populateOutput(data);
          this.setStatus("");
          this.dispatchFormEvent("mql:complete");
          this.dispatchFormEvent("mql:success");
        } else {
          this.enableForm();
          this.setStatus("There was an error generating the link preview.");
          this.dispatchFormEvent("mql:complete");
          this.dispatchFormEvent("mql:error");
        }
      } catch (err) {
        this.enableForm();
        this.setStatus("Please enter a valid website.");
        this.dispatchFormEvent("mql:complete");
        this.dispatchFormEvent("mql:error");
      }
    }
  }

  clearFormData() {
    this.titleInputTarget.value = null;
    this.descriptionInputTarget.value = null;
    this.imageInputTarget.value = null;
    this.inputTarget.value = null;
  }

  disableForm() {
    this.setStatus("Fetching preview...");
    this.inputTarget.classList.add("hidden");
    this.form
      .querySelectorAll("input[type='submit'], button[type='submit']")
      .forEach((element) => element.setAttribute("disabled", "disabled"));
  }

  dispatchFormEvent(event) {
    this.form.dispatchEvent(new Event(event));
  }

  enableForm() {
    this.inputTarget.classList.remove("hidden");
    this.clearFormData();
  }

  populateOutput(data) {
    const { hostname } = new URL(data?.url);
    this.outputTarget.style.display = "block";
    this.titleOutputTarget.innerText = data?.title;
    this.descriptionOutputTarget.innerText = data?.description;
    this.hostOutputTarget.innerText = hostname;
    if (data?.image?.url) {
      this.imageOutputTarget.setAttribute("src", data?.image?.url);
    } else {
      this.imageOutputTarget.removeAttribute("src");
    }
  }

  removePreview(e) {
    e.preventDefault();
    this.clearFormData();
    this.enableForm();
    this.outputTarget.style.display = "none";
    this.dispatchFormEvent("mql:preview-removed");
  }

  setFormData(data) {
    this.titleInputTarget.value = data?.title ? data?.title : null;
    this.descriptionInputTarget.value = data?.description
      ? data?.description
      : null;
    this.imageInputTarget.value = data?.image?.url ? data?.image?.url : null;
  }

  setStatus(string) {
    if (this.hasStatusTarget) {
      this.statusTarget.innerText = string;
    }
  }
}
