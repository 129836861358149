import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    setTimeout(() => {
      this.dispatchChangeEvent(this.selectTarget.value);
    }, 1);
  }

  onChange(event) {
    this.dispatchChangeEvent(event.target.value);
  }

  dispatchChangeEvent(categoryName) {
    const dispatchEvent = new CustomEvent(
      "admin--site-setting-name:category:changed",
      { detail: { category: categoryName } }
    );
    window.dispatchEvent(dispatchEvent);
  }
}
