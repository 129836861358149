import { Controller } from "@hotwired/stimulus";

const STAGING_PANEL_CLOSED = "stagingPanelClosed";

export default class extends Controller {
  static targets = ["toggleable"];

  connect() {
    const isPanelOpened = !sessionStorage.getItem(STAGING_PANEL_CLOSED);

    if (isPanelOpened) {
      this.toggleableTarget.classList.remove("hidden");
    }
  }

  toggleVisiblity() {
    this.toggleableTarget.classList.add("hidden");
    sessionStorage.setItem(STAGING_PANEL_CLOSED, true);
  }
}
