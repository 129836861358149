import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "output", "listener" ]

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }
  connect() {
    super.connect()
    this.intersectionObserver.observe(this.outputTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.outputTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadReflex()
      }
    })
  }

  loadReflex(){
    
    if (window.stimulusReady && this.isActionCableConnectionOpen()) {
      this.intersectionObserver.unobserve(this.outputTarget)
      if (!this.outputTarget.id) {
        this.outputTarget.id = this.randomId()
      }
      this.stimulate(this.data.get('reflex'), this.outputTarget).then(()=>{
          this.listenerTarget.dispatchEvent(new CustomEvent("intersection-observer:callReflex:success"));
      })
    } else {
      this.schedule()
    }
  }

  schedule() {
    setTimeout(function () {
      this.loadReflex()
    }.bind(this), 100);
  }

  randomId() {
    return Math.random().toString(32).replace(/[^a-z]+/g, '').substr(2, 10);
  }
}