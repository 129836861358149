import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "removed" ]
  static targets = [ "icon", "wrapper" ]

  handleClick() {
    this.wrapperTarget.classList.toggle(this.removedClass);
    this.iconTargets.forEach(icon => icon.classList.toggle("hidden"));
  }
}
