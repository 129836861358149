import { Controller } from "@hotwired/stimulus";
import Trix from "trix";

export default class extends Controller {
  static targets = ["form", "static"];

  static values = { user: String, userSgid: String };

  connect() {
    this.trixEditor = this.formTarget.querySelector("trix-editor");
    if (this.trixEditor) {
      this.editor = this.trixEditor.editor;
    }
  }

  changeViewMode(event) {
    event.preventDefault();
    this.staticTarget.classList.toggle("hidden");
    this.formTarget.classList.toggle("hidden");
    if (this.formTarget.classList.contains("hidden")) {
      this.clearInput();
    } else {
      this.focusCursor();
    }
  }

  toggle(event) {
    event.preventDefault();
    this.formTarget.classList.toggle("hidden");
    if (this.formTarget.classList.contains("hidden")) {
      this.clearInput();
    } else {
      this.fillReply();
      this.focusCursor();
    }
  }

  focusCursor() {
    if (this.trixEditor == null) {
      return;
    }

    const { length } = this.editor.getDocument().toString();
    this.trixEditor.focus();
    this.editor.setSelectedRange(length - 1);
  }

  fillReply() {
    if (this.hasUserValue && !!this.trixEditor) {
      this.mentionUser(this.userValue, this.userSgidValue);
    }
  }

  mentionUser(content, sgid) {
    const attachment = new Trix.Attachment({
      content,
      sgid,
      contentType: "mention",
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  clearInput() {
    const { length } = this.editor.getDocument().toString();
    this.editor.setSelectedRange([0, length]);
    this.editor.deleteInDirection("forward");
  }
}
