import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["links", "template"];

  static values = {
    count: Number,
  };

  connect() {
    this.nested_count = this.countValue || 0;
  }

  add_association(event) {
    event.preventDefault();

    this.increase_index();

    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      this.index_value()
    );
    this.linksTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_association(event) {
    event.preventDefault();

    const wrapper = event.target.closest(".js-nested-fields");
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }

  index_value() {
    return this.hasCountValue ? this.nested_count : new Date().getTime();
  }

  increase_index() {
    if (this.hasCountValue) {
      this.nested_count += 1;
    }
  }
}
