import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "button", "span"]

  toggleExpansion() {
    if(this.containerTarget.classList.contains('h-400')) {
      this.removeContainerHeight();
      this.seeLessSpan();
      this.expandedBtnState();
    } else {
      this.addContainerHeight();
      this.seeMoreSpan();
      this.collapsedBtnState();
      this.createSvg();
    }
  }

  addContainerHeight() {
    this.containerTarget.classList.add('h-400');
    this.containerTarget.classList.add('overflow-hidden');
  }

  removeContainerHeight() {
    this.containerTarget.classList.remove('h-400');
    this.containerTarget.classList.remove('overflow-hidden');
  }

  expandedBtnState() {
    this.buttonTarget.classList.remove('bg-gradient-to-t');
    this.buttonTarget.classList.remove('from-gray-medium');
    this.buttonTarget.classList.remove('via-gray-medium');
    this.buttonTarget.classList.remove('to-transparent');
    this.buttonTarget.classList.add('bg-transparent');
    this.buttonTarget.classList.add('underline');
  }

  collapsedBtnState() {
    this.buttonTarget.classList.add('bg-gradient-to-t');
    this.buttonTarget.classList.add('from-gray-medium');
    this.buttonTarget.classList.add('via-gray-medium');
    this.buttonTarget.classList.add('to-transparent');
    this.buttonTarget.classList.add('underline');
  }

  seeLessSpan() {
    this.spanTarget.innerText = "See less";
    this.spanTarget.classList.remove('h-28');
    this.spanTarget.classList.remove('bg-gradient-to-t');
    this.spanTarget.classList.remove('from-gray-medium');
    this.spanTarget.classList.remove('via-gray-medium');
  }

  seeMoreSpan() {
    this.spanTarget.innerText = "See more";
    this.spanTarget.classList.add('h-28');
  }

  createSvg() {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    var path1 = document.createElementNS("http://www.w3.org/2000/svg", 'path');

    svg.setAttribute('viewbox', '0 0 11 5');
    svg.setAttribute('width', '11px');
    svg.setAttribute('height', '5px');
    svg.classList.add('ml-2');

    path1.setAttribute('d', 'M1.24265 0L5.28786 2.80635L9.33307 0L10.5757 0.863964L5.28786 4.54041L0 0.863964L1.24265 0Z');
    path1.setAttribute('fill', 'white');

    svg.appendChild(path1);
    this.spanTarget.appendChild(svg);
  }
}
