import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "status",
    "output",
    "outputWrapper",
    "videoPlatform",
    "videoId",
  ];

  connect() {
    this.form = this.element.closest("form");

    // https://embed.ly/tools/generator
    this.videoPlatforms = {
      youtube: {
        pattern: /((http:\/\/(.*youtube\.com\/watch.*|.*\.youtube\.com\/v\/.*|youtu\.be\/.*|.*\.youtube\.com\/user\/.*|.*\.youtube\.com\/.*#.*\/.*|m\.youtube\.com\/watch.*|m\.youtube\.com\/index.*|.*\.youtube\.com\/profile.*|.*\.youtube\.com\/view_play_list.*|.*\.youtube\.com\/playlist.*|www\.youtube\.com\/embed\/.*|youtube\.com\/gif.*|www\.youtube\.com\/gif.*|www\.youtube\.com\/attribution_link.*|youtube\.com\/attribution_link.*|youtube\.ca\/.*|youtube\.jp\/.*|youtube\.com\.br\/.*|youtube\.co\.uk\/.*|youtube\.nl\/.*|youtube\.pl\/.*|youtube\.es\/.*|youtube\.ie\/.*|it\.youtube\.com\/.*|youtube\.fr\/.*))|(https:\/\/(.*youtube\.com\/watch.*|.*\.youtube\.com\/v\/.*|youtu\.be\/.*|.*\.youtube\.com\/playlist.*|www\.youtube\.com\/embed\/.*|youtube\.com\/gif.*|www\.youtube\.com\/gif.*|www\.youtube\.com\/attribution_link.*|youtube\.com\/attribution_link.*|youtube\.ca\/.*|youtube\.jp\/.*|youtube\.com\.br\/.*|youtube\.co\.uk\/.*|youtube\.nl\/.*|youtube\.pl\/.*|youtube\.es\/.*|youtube\.ie\/.*|it\.youtube\.com\/.*|youtube\.fr\/.*)))/i,
        markup(videoId) {
          return `<iframe class="object-cover" width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        },
        getVideoID(url) {
          url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          const id =
            url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
          return id;
        },
      },
      vimeo: {
        pattern: /((http:\/\/(www\.vimeo\.com\/groups\/.*\/videos\/.*|www\.vimeo\.com\/.*|vimeo\.com\/groups\/.*\/videos\/.*|vimeo\.com\/.*|vimeo\.com\/m\/#\/.*|player\.vimeo\.com\/.*))|(https:\/\/(www\.vimeo\.com\/.*|vimeo\.com\/.*|player\.vimeo\.com\/.*)))/i,
        markup(videoId) {
          return `<iframe class="object-cover" src="https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        },
        getVideoID(url) {
          const id = url.split("/").slice(-1)[0].split("?")[0];
          return id;
        },
      },
      vidyard: {
        pattern: /((http:\/\/(.*vidyard\.com\/.*))|(https:\/\/(.*vidyard\.com\/.*))|(\/\/(play.vidyard\.com\/.*))|(http:\/\/(videos\..*\.com\/watch\/.*))|(https:\/\/(videos\..*\.com\/watch\/.*)))/i,
        markup(videoId) {
          return `<iframe class="vidyard_iframe object-cover" src="//play.vidyard.com/${videoId}.html?" width=640 height=360 scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen></iframe>`;
        },
        getVideoID(url) {
          const id = url.split("/").pop().split("?")[0].split(".")[0];
          return id;
        },
      },
    };
  }

  embedVideo() {
    const url = this.inputTarget.value;
    const platforms = this.videoPlatforms;
    const videoPlatform = Object.keys(platforms).filter((videoPlatform) =>
      platforms[videoPlatform].pattern.exec(url)
    );
    if (videoPlatform.length > 0) {
      const videoData = this.getVideoId(url, videoPlatform[0]);
      this.setStatus("");
      this.renderVideo(videoData);
      this.updateFormValues(videoData);
      this.enableForm();
      this.hideInput();
    } else {
      this.setStatus("Not a supported provider");
      this.clearFormData();
      this.clearOutput();
      this.disableForm();
      this.showInput();
    }
  }

  clearOutput() {
    this.outputWrapperTarget.style.display = "none";
    this.outputTarget.innerHTML = "";
  }

  clearFormData() {
    this.videoPlatformTarget.value = null;
    this.videoIdTarget.value = null;
  }

  clearInput() {
    this.inputTarget.value = null;
  }

  disableForm() {
    this.form
      .querySelectorAll("input[type='submit'], button[type='submit']")
      .forEach((element) => element.setAttribute("disabled", "disabled"));
  }

  enableForm() {
    const event = new Event("imp:video_preview:success");
    this.form.dispatchEvent(event);
  }

  getVideoId(url, videoPlatform) {
    const videoId = this.videoPlatforms[videoPlatform].getVideoID(url);
    const data = { videoId, videoPlatform };
    return data;
  }

  hideInput() {
    this.inputTarget.style.display = "none";
  }

  renderVideo(videoData) {
    const { videoId, videoPlatform } = videoData;
    this.outputWrapperTarget.style.display = "block";
    this.outputTarget.innerHTML = this.videoPlatforms[
      videoPlatform
    ].markup(videoId);
  }

  reset() {
    this.clearFormData();
    this.clearOutput();
    this.disableForm();
    this.showInput();
    this.clearInput();
  }

  setStatus(string) {
    if (this.hasStatusTarget) {
      this.statusTarget.innerText = string;
    }
  }

  showInput() {
    this.inputTarget.style.display = "inline";
  }

  updateFormValues(videoData) {
    this.videoIdTarget.value = videoData.videoId;
    this.videoPlatformTarget.value = videoData.videoPlatform;
  }
}
