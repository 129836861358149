import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		string: String,
	}

	updateHash(e) {
		if (this.hasStringValue) {
			window.location.hash = this.stringValue
		}
	}
}
