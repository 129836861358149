const defaultColor = require("./defaultColors");
const generateBtnColors = require("./utils/generateBtnColors");
const defaultKinds = require("./defaultKinds");
const generateDefaultBrandColors = require("./generateDefaultBrandColors");

const generateDefaultColors = () => ({
  ...defaultColor,
  ...generateDefaultBrandColors(),
  ...generateBtnColors(defaultKinds),
});

module.exports = generateDefaultColors;
