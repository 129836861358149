import { Controller } from "@hotwired/stimulus";
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['evaluationTrue', 'evaluationFalse'];
  static values = {
    evaluation: String,
  }

  connect() {
    this.currentEvaluation = "";
    if (this.hasEvaluationValue && eval(this.evaluationValue)) {
      this.evaluationFalseTarget.innerHTML = '';
      this.currentEvaluation = "true";
    } else {
      this.evaluationTrueTarget.innerHTML = '';
      this.currentEvaluation = "false";
    }
  }

  initialize(){
    this.react = debounce(this.react, 500).bind(this)
  }

  react() {
    const url       = window.location.href;
    const type      = "GET";
    const dataType  = "json";
    Rails.ajax({
      url,
      type,
      dataType,
      success: (data) => {
        const { comments } = data;
        this.setComments(comments);
      },
    });
  }

  setComments(comments){
    // this.results.innerHTML = results
    if (this.hasEvaluationValue) {
      if (eval(this.evaluationValue) && this.currentEvaluation != "true") {
        this.evaluationTrueTarget.innerHTML = comments;
        this.evaluationFalseTarget.innerHTML = '';
        this.currentEvaluation = "true";
      } else if (!eval(this.evaluationValue) && this.currentEvaluation == "true") {
        this.evaluationFalseTarget.innerHTML = comments;
        this.evaluationTrueTarget.innerHTML = '';
        this.currentEvaluation = "false";
      }
    }
  }
}
