import Turbolinks from "turbolinks";
import { OnboardingApi } from "../api";
import communitiesRecommendations from "./steps/communities_recommendations";
import coursesRecommendations from "./steps/courses_recommendations";
import invites from "./steps/invites";
import membersRecommendations from "./steps/members_recommendations";
import questionsList from "./steps/questions_list";

const STEPS_MAPPING = {
  questions_list: questionsList,
  courses_recommendations: coursesRecommendations,
  communities_recommendations: communitiesRecommendations,
  members_recommendations: membersRecommendations,
  invites,
};

export default function onboardingPage(currentStepName) {
  const initializeStep = STEPS_MAPPING[currentStepName];

  return initializeStep({
    onNextStep: (event, data, redirectUrl) => {
      event.preventDefault();

      if (!event.target.disable) {
        OnboardingApi.nextStep({
          data,
          onSuccess: () => Turbolinks.visit(redirectUrl),
        });
      }
    },

    onPrevStep: (event, data, redirectUrl) => {
      event.preventDefault();

      if (!event.target.disable) {
        OnboardingApi.previousStep({
          data,
          onSuccess: () => Turbolinks.visit(redirectUrl),
        });
      }
    },

    onFinish: (event, data, redirectUrl) => {
      event.preventDefault();

      if (!event.target.disable) {
        OnboardingApi.finish({
          data,
          onSuccess: () => Turbolinks.visit(redirectUrl),
        });
      }
    },
  });
}
