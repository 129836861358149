import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["position"];

  static values = {
    url: String,
    hideSlideovers: Boolean
  };

  connect() {
    this.slideOvers = document.querySelectorAll(".js-slideover");
    this.handleSort = this.handleSort.bind(this);
    this.hideSlideOvers = this.hideSlideOvers.bind(this);
    this.resetSlideOvers = this.resetSlideOvers.bind(this);
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      animation: 150,
      handle: ".js-sortable-handle",
      draggable: ".js-draggable",
      onChoose: this.hideSlideOvers,
      onUnchoose: this.resetSlideOvers,
      onEnd: this.handleSort,
    });
  }

  hideSlideOvers() {
    if (this.hasHideSlideoversValue && this.hideSlideoversValue == false) {
      return
    }
    else if (this.slideOvers) {
      this.slideOvers.forEach((slideOver) => {
        slideOver.classList.add("hidden");
      });
    }
  }

  resetSlideOvers() {
    if (this.slideOvers) {
      this.slideOvers.forEach((slideOver) => {
        slideOver.classList.remove("hidden");
      });
    }
  }

  setPositionText() {
    if (this.positionTargets.length > 0) {
      this.positionTargets.forEach((positionTarget, index) => {
        positionTarget.value = index + 1;
      });
    }
  }

  handleSort(event) {
    const { id } = event.item.dataset;
    const data = new FormData();
    data.append("position", event.newDraggableIndex + 1);

    this.resetSlideOvers();
    this.setPositionText();

    if (this.hasUrlValue) {
      Rails.ajax({
        url: this.urlValue.replace("id", id),
        type: "PATCH",
        data,
      });
    }
  }
}
