import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    enabled: Boolean,
    event: String,
    properties: Object,
    identifier: String,
    traits: Object
  }

  connect() {
    this.segmentEnabled = this.hasEnabledValue ? this.enabledValue : false
  }

  identify() {
    if (!this.segmentEnabled) { return }

    analytics.identify(this.identifierValue, this.traitsValue);
  }

  track() {
    if (!this.segmentEnabled) { return }

    this.identify();
    analytics.track(this.eventValue, this.propertiesValue);
  }

}
