import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['async','parent']
  static values = { url: String }

  connect () {
    this.showAsyncClasses = (this.data.get('showAsync') || 'block').split(' ')
    this.hideAsyncClasses = (this.data.get('hideAsync') || 'hidden').split(' ')
  }

  show () {
    if (this.hasAsyncTarget) {
      this.addShowClasses()
    } else {
      fetch(this.data.get('url'))
        .then(r => r.text())
        .then(html => {
          // const fragment = document.createRange().createContextualFragment(html)
          this.parentTarget.innerHTML = html
          setInterval(() =>
            this.addShowClasses(),
          100)
        })
    }
  }

  hide () {
    if (this.hasAsyncTarget) {
      this.addHideClasses()
    }
  }

  addShowClasses () {
    this.asyncTarget.classList.remove(...this.hideAsyncClasses)
    this.asyncTarget.classList.add(...this.showAsyncClasses)
  }

  addHideClasses () {
    this.asyncTarget.classList.add(...this.hideAsyncClasses)
    this.asyncTarget.classList.remove(...this.showAsyncClasses)
  }

  // disconnect () {
  //   if (this.hasAsyncTarget) {
  //     this.asyncTarget.remove()
  //   }
  // }
}
