import { Controller } from "@hotwired/stimulus";
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core';
import { siblings } from '@glidejs/glide/src/utils/dom';

const CustomActiveClass = function (Glide, Components, Events) {
  const Component = {
    mount() {
      this.changeActiveSlide();
    },

    changeActiveSlide() {
      const slide = Components.Html.slides[Glide.index];

      slide.classList.remove('is-next', 'is-prev');
      slide.classList.add('is-active');

      siblings(slide).forEach((sibling) => {
        sibling.classList.remove('is-active', 'is-next', 'is-prev');
      });

      if (slide.nextElementSibling) {
        slide.nextElementSibling.classList.add('is-next');
      }

      if (slide.previousElementSibling) {
        slide.previousElementSibling.classList.add('is-prev');
      }
    },
  };

  Events.on('run', () => {
    Component.changeActiveSlide();
  });

  return Component;
};

const DisabledGlide = function (Glide, Components, Events) {
  const Component = {
    mount() {
      this.disableGlide();
    },

    disableGlide() {
      Events.emit('slider.length', Components.Sizes.length);
      if (Components.Sizes.length < 3) {
        Glide.disable();
      }
    },
  };

  Events.on('mount.before', () => {
    Component.disableGlide();
  });

  return Component;
}

export default class extends Controller {
  static targets = ['glide', 'leftArrow'];

  connect() {
    this.glide = this.initializeGlide();
    this.glide.mount();
    this.hasLeftArrowTarget && this.leftArrowTarget.classList.add('hidden');
  }

  initializeGlide() {
    return new Glide(this.glideTarget, {
      type: this.type,
      perView: this.perView,
      gap: this.gap,
      focusAt: this.focusAt,
      bound: this.bound,
      rewind: this.rewind,
      animationDuration: this.animationDuration,
      breakpoints: {
        640: {
          perView: this.perViewSmall,
          animationDuration: this.animationDurationSmall,
        },
        1279: {
          perView: this.perViewMedium,
          animationDuration: this.animationDurationMedium,
        },
      },
    });
  }
  // https://glidejs.com/docs/options/
  get bound() {
    return this.data.get('boundValue') || false;
  }

  get focusAt() {
    return this.data.get('focusAtValue') || 0;
  }

  get gap() {
    return this.data.get('gapValue') || 10;
  }

  get perView() {
    return this.data.get('perViewValue') || 1;
  }

  get rewind() {
    return this.data.get('rewindValue') || true;
  }

  get perViewSmall() {
    return this.data.get('perViewSmallValue') || 2;
  }

  get perViewMedium() {
    return this.data.get('perViewMediumValue') || 2;
  }

  get animationDuration() {
    return this.data.get('animationDurationValue') || 400;
  }
  get animationDurationMedium() {
    return this.data.get('animationDurationMediumValue') || 400;
  }
  get animationDurationSmall() {
    return this.data.get('animationDurationSmallValue') || 1000;
  }

  get type() {
    return this.data.get('typeValue') || 'slider';
  }

  showLeftArrow() {
    setTimeout(() => {
      if (this.glide.index > 0) {
        this.leftArrowTarget.classList.remove('hidden');
      } else {
        this.leftArrowTarget.classList.add('hidden');
      }
    }, 100);
  }

  hideLeftArrow() {
    setTimeout(() => {
      if (this.glide.index == 0) {
        this.leftArrowTarget.classList.add('hidden');
      }
    }, 100);
  }

  reInitialize() {
    this.glide.destroy();
    this.glide = this.initializeGlide();
    this.glide.mount({
      CustomActiveClass: CustomActiveClass,
      DisabledGlide: DisabledGlide
    });
  }
}
