import { useDispatch } from "stimulus-use";
import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["newPriorityButton"];

  connect() {
    super.connect();
    useDispatch(this);
  }

  renderPrioritySuccess() {
    this.dispatchFormReady();
  }

  renderNewPriorityFormSuccess() {
    this.dispatchFormReady();
  }

  renderRecommendedPrioritySuccess() {
    this.dispatchFormReady();
  }

  renderPrioritiesSuccess() {
    this.dispatch("display-priorities");
  }

  onCreateNewPriorityButtonClicked(event) {
    event.preventDefault();

    this.stimulate("CoreCompetencyPrioritiesReflex#render_new_priority_form");
  }

  onRecommendedPriorityClicked(event) {
    event.preventDefault();

    this.stimulate(
      "CoreCompetencyPrioritiesReflex#render_recommended_priority",
      event.target.closest(".period-target__slidover-row")
    );
  }

  onCustomPriorityClicked(event) {
    event.preventDefault();

    if (event.target.classList.contains("skip-custom-priority-clicked")) {
      return;
    }

    this.stimulate(
      "CoreCompetencyPrioritiesReflex#render_priority",
      event.target.closest(".period-target__slidover-row")
    );
  }

  updateCoreCompetencyProgressBar() {
    this.stimulate(
      "CoreCompetencyPrioritiesReflex#render_core_competency_progress_bar"
    );
  }

  renderPriorities() {
    this.stimulate("CoreCompetencyPrioritiesReflex#render_priorities");
  }

  dispatchFormReady() {
    setTimeout(() => {
      this.dispatch("form-ready");
    }, 200);
  }
}
