import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
  }

  assignValue(event) {
    let inputValue = event.currentTarget.innerHTML.trim()
    if (inputValue == "Last") {
      this.fieldTarget.value = Date.now()
    } else {
      this.fieldTarget.value = inputValue
    }

  }
}
