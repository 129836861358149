import ApplicationController from "./application_controller";
import { useDispatch } from "stimulus-use";

export default class extends ApplicationController {
    static targets = ["userRating", "review", "courseRatingCard", "questionerCard", "overviewCard", "backButton", "overviewRating", "submit"]

    connect() {
      super.connect();
      useDispatch(this);
    }

    rate(event) {
        const rated = event.currentTarget.dataset.star

        for (let index = 1; index <= 5; index++) {
            const ratingStar = document.querySelector(`#rating-star-${index}`)
            const userRatingStar = document.querySelector(`#user-rating-star-${index}`)
            ratingStar.firstElementChild.classList.toggle('text-custom-gray-300', index > rated)
            ratingStar.firstElementChild.classList.toggle('text-yellow-rating', index <= rated)
            userRatingStar.classList.toggle('text-custom-gray-300', index > rated)
            userRatingStar.classList.toggle('text-yellow-rating', index <= rated)
        }
        this.userRatingTarget.value = rated
        document.querySelector('#user-review').innerHTML = "a"
        this.reviewTarget.parentElement.classList.remove("hidden")
    }

    back(event) {
        if (!this.overviewCardTarget.classList.contains("hidden")) {
            this.questionerCardTarget.classList.toggle("hidden", false)
            this.overviewCardTarget.classList.toggle("hidden", true)
        }
        else{
            this.questionerCardTarget.classList.toggle("hidden", true)
            this.courseRatingCardTarget.classList.toggle("hidden", false)
        }
    }

    skip(event){
        this.questionerCardTarget.classList.toggle("hidden", true)
        this.overviewCardTarget.classList.toggle("hidden", false)
    }

    checked(event) {
        let childList = event.target.parentElement.children;
        for (let index = 0; index < childList.length; index++) {
            const element = childList[index]
            if (element.type === "button") {
                let active = element == event.currentTarget
                element.firstElementChild.classList.toggle("hidden", !active)
                element.classList.toggle("text-custom-blue-500", active)
                element.classList.toggle("bg-custom-blue-100", active)
                element.classList.toggle("px-1", active)
                element.classList.toggle("text-black", !active)
                element.classList.toggle("bg-custom-gray-100", !active)
                element.classList.toggle("px-3", !active)
            }else if (element.type === "hidden") {
                element.value = event.currentTarget.value
            }
        }
    }

    handleFeedbackAnswer(event) {
        const button        = event.target.closest(".js-rating-feedback-button")
        const buttons       = event.target.closest(".js-rating-feedback-answer-wrapper").querySelectorAll(".js-rating-feedback-button");
        const hiddenField   = event.target.closest(".js-rating-feedback-answer-wrapper").querySelector("input[type='hidden']");

        buttons.forEach((button) => {
            button.classList.remove("text-im-misc-free-dark", "text-progress-off-track");
            button.classList.add("text-custom-gray-300");
        });
        if (button.value == "Yes") {
            button.classList.remove("text-custom-gray-300");
            button.classList.add("text-im-misc-free-dark")
        } else {
            button.classList.remove("text-custom-gray-300");
            button.classList.add("text-progress-off-track")
        }
        hiddenField.value = button.value;
    }

    onSavedSuccess(data) {
        if (!this.courseRatingCardTarget.classList.contains("hidden")) {
            this.courseRatingCardTarget.classList.toggle("hidden", true)
            this.questionerCardTarget.classList.toggle("hidden", false)
            document.querySelector('#user-review').innerHTML = this.reviewTarget.value.trim().length > 0 ? `"${this.reviewTarget.value}"` : ""
        }
        else if (!this.questionerCardTarget.classList.contains("hidden")) {
            this.questionerCardTarget.classList.toggle("hidden", true);
            this.overviewCardTarget.classList.toggle("hidden", false);

        }
    }

    saveAndContinue(event) {
        this.submitTarget.click()

        document.body.addEventListener('ajax:success', (event) => {
            var detail      = event.detail;
            var data        = detail[0]
            data.success ? this.dispatch("saved") : null;
        });
    }

    saveAndExit() {
        this.saveAndContinue();
        this.closeModal();
    }

    closeModal(event) {
        const body  = document.querySelector('body')
        const modal = document.querySelector("#rating-modal")
        modal.classList.add("invisible", "opacity-0", "pointer-events-none");
        body.classList.remove('overflow-hidden');
        this.dispatch("closed");
    }

    toggleModal () {
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('invisible')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('overflow-hidden');
    }
}
