import ApplicationController from './application_controller';
let debounce = require('lodash/debounce');

export default class extends ApplicationController {
  static targets = ['query'];
  
  initialize() {
    this.perform = debounce(this.perform, 250).bind(this);
  }

  getStatusElement() {
    return document.getElementById('group-training-status');
  }

  beforePerform() {
    this.getStatusElement().innerHTML = 'Searching Group Training...';
  }

  perform(event) {
    event.preventDefault();
    this.stimulate('GroupTrainingReflex#perform', this.queryTarget.value);
  }

  afterPerform() {
    this.getStatusElement().innerHTML = 'Group Training';
  }
}
