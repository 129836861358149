import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["panel", "searchIcon", "closeIcon", "searchInput", "searchButton"];
  static values = {
    siteId: String,
  };

  connect() {
    this.initConfig();
    this.toggleSearchButton();
    this.bodyTag = document.querySelector("body");
  }

  initConfig() {
    window.ss360Configs ||= {};
    window.ss360Configs.globalSearchConfig = {
      siteId: this.siteIdValue,
      searchBox: {
        selector: "#globalSearchBox",
        injectTo: "header",
        injectPosition: "pre",
        placeholder: "Search…",
        style: {},
      },
      results: {
        embedConfig: {
          contentBlock: "#search-results",
          url: "/search",
        },
        showSearchBoxEmbed: true,
        infiniteScroll: true,
        placeholderImage:
          "https://www.impactplus.com/hubfs/Learning%20Center%20(8-2021)/Assets/Blue-IM-Pattern%20-%20Image%20Placeholder.jpg",
        fullScreenConfig: {},
        integrationType: "embed",
      },
      contentGroups: {
        include: [
          "Courses & Trainings",
          "Events",
          "Communities",
          "Members",
          "Coaches",
          "Discussions",
          "Other",
        ],
      },
      layout: {
        mobile: {
          showUrl: true,
        },
        desktop: {
          showUrl: true,
        },
        navigation: {
          position: "left",
          showGroupResultCount: false,
          showAllResultsTab: false,
        },
      },
      filters: {
        enabled: false,
      },
    };
  }

  openPanel() {
    this.searchIconTarget.classList.add("hidden");
    this.closeIconTarget.classList.remove("hidden");
    this.panelTarget.classList.remove("hidden");

    this.searchInputTarget.focus();

    this.bodyTag.classList.add("search-bar-opened");
  }

  closePanel() {
    this.closeIconTarget.classList.add("hidden");
    this.searchIconTarget.classList.remove("hidden");
    this.panelTarget.classList.add("hidden");

    this.bodyTag.classList.remove("search-bar-opened");
  }

  toggleSearchButton() {
    this.searchButtonTarget.classList.toggle("hidden", window.location.pathname === "/search");
  }
}
