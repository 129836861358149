const ProgressBar = require('progressbar.js')

document.addEventListener("turbolinks:load", () => {
    init();
});

window.addEventListener("teamScoreTotal:valueChanged", () => {
    init();
});

function init() {
    const arch = document.getElementById("progress-bar-arch");
    const scoreElement = document.getElementById("progress-bar-current-total-score")
    const score = scoreElement ? scoreElement.dataset.currentTotalScore : null;
    const calculateScore = score ? (score / 100.0) : 0;

    if (!arch) {
        return;
    }

    const bar = new ProgressBar.Path(arch);
    bar.path.style.strokeLinecap = 'round';
    bar.animate(calculateScore);  // Number from 0.0 to 1.0
}
