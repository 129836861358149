import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "discussableType", "discussableId", "communityId", "dropDownSelect", "dropDownOptions" ]

  dispatchChangeEvent() {
    this.communityIdTarget.dispatchEvent(new Event('change'));
    this.discussableTypeTarget.dispatchEvent(new Event('change'));
    this.discussableIdTarget.dispatchEvent(new Event('change'));
  }

  setSelected(e) {
    let selected = e.currentTarget;
    let memberCount = document.querySelectorAll("#remove-for-select");
    memberCount.forEach(element => element.classList.add("hidden"));
    for (let dropDownSelect of this.dropDownSelectTargets) {
      let selectedWrapper = dropDownSelect.querySelector(".discussion-form-dropdown--selected")
      selectedWrapper && (selectedWrapper.innerHTML = selected.innerHTML);
    }
  }

  showMemberCounts() {
    let memberCount = document.querySelectorAll("#remove-for-select");
    memberCount.forEach(element => element.classList.remove("hidden"));
  }

  setFormValues(e) {
    const communityId = e.currentTarget.dataset.communityId
    const discussableType = e.currentTarget.dataset.discussableType
    const discussableId = e.currentTarget.dataset.discussableId

    if(communityId) {
        this.communityIdTarget.value = communityId;
        this.discussableTypeTarget.value = null;
        this.discussableIdTarget.value = null;
        this.dispatchChangeEvent();
        this.dispatchDropdownEvent(communityId, 'Community');
      } else if (discussableType && discussableId) {
        this.communityIdTarget.value = null;
        this.discussableTypeTarget.value = discussableType;
        this.discussableIdTarget.value = discussableId;
        this.dispatchChangeEvent();
        this.dispatchDropdownEvent(null, null);
      } else {
        this.communityIdTarget.value = null;
        this.discussableTypeTarget.value = null;
        this.discussableIdTarget.value = null;
        this.dispatchChangeEvent();
        this.dispatchDropdownEvent(null, null);
      }
    }

  dispatchDropdownEvent(id, type) {
    document.dispatchEvent(new CustomEvent('dropdown:changed', { detail: { id: id, type: type } }));
  }
}
