import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["disabledField"];

  toggleDisabledFields() {
    this.disabledFieldTargets.forEach((disabledFieldTarget) => {
      const isFieldDisabled = disabledFieldTarget.getAttribute("disabled");
      isFieldDisabled
        ? disabledFieldTarget.removeAttribute("disabled")
        : disabledFieldTarget.setAttribute("disabled", "disabled");
    });
  }
}
