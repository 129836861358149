import { useClickOutside } from "stimulus-use";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    reactedEmoji: String,
    type: String,
    id: String,
  };

  static targets = ["reactionsList"];

  connect() {
    super.connect();
    useClickOutside(this, { element: this.reactionsListTarget.parentNode });
  }

  clickOutside(event) {
    if (
      !this.hasReactionsListTarget ||
      this.reactionsListTarget.classList.contains(
        "reaction-hover-target--hidden"
      )
    ) {
      return;
    }

    this.toggleReactions(event);
  }

  toggleReactions(event) {
    event.preventDefault();
    this.reactionsListTarget.classList.toggle("reaction-hover-target--hidden");
  }

  react(event) {
    event.preventDefault();

    const emoji = event.currentTarget.getAttribute("data-emoji");

    this.reactionsListTarget.classList.toggle("reaction-hover-target--hidden");

    if (this.reactedEmojiValue && this.reactedEmojiValue === emoji) {
      this.stimulate("Reactions#delete_reaction", this.typeValue, this.idValue);
      this.reactedEmojiValue = null;
    } else {
      this.stimulate(
        "Reactions#put_reaction",
        this.typeValue,
        this.idValue,
        emoji
      );
      this.reactedEmojiValue = emoji;
    }
  }
}
