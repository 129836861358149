import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  initialized() {
    this.update = this.update.bind(this)
  },
  connected() {
    // Called when the subscription is ready for use on the server
    this.install();
    this.update();
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    this.uninstall();
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    const { markup }      = data;
    const { drop_down, desktop_unread_badge, mobile_unread_badge, flash_message } = markup
    drop_down             && this.addNotificationsToDropDown(drop_down);
    desktop_unread_badge  && this.updateDesktopUnreadBadge(desktop_unread_badge)
    mobile_unread_badge   && this.updateMobileUnreadBadge(mobile_unread_badge)
    flash_message         && this.renderFlashMessage(flash_message);
    Alpine.discoverUninitializedComponents(function(el){ Alpine.initializeComponent(el) });
  },
  install() {
    document.addEventListener("turbolinks:load", this.update);
  },
  uninstall() {
    document.removeEventListener("turbolinks:load", this.update);
  },
  update() {
    this.perform("load_unread_notifications");
    Alpine.discoverUninitializedComponents(function(el){ Alpine.initializeComponent(el) });
  },
  addNotificationsToDropDown(markup) {
    let notificationsList = document.querySelector(`#notifications-wrapper .notifications-list`)
    if (notificationsList !== null) {
      notificationsList.innerHTML = markup;
      document.querySelector(`button[data-notifications-target="unread"]`).classList.add('unread-notifications');
    }
  },
  renderFlashMessage(markup) {
    let notificationFlashMessageWrapper = document.querySelector("[data-notification-element='flash-message-wrapper']");
    if (notificationFlashMessageWrapper !== null) {
      notificationFlashMessageWrapper.insertAdjacentHTML("afterbegin", markup);
    }
  },
  updateDesktopUnreadBadge(markup) {
    let desktopUnreadBadge = document.querySelector("[data-notification-element='desktop-unread-badge']");
    if (desktopUnreadBadge !== null) {
      desktopUnreadBadge.innerHTML = markup;
    }
  },
  updateMobileUnreadBadge(markup) {
    let mobileUnreadBadge = document.querySelector("[data-notification-element='mobile-unread-badge']");
    if (mobileUnreadBadge !== null) {
      mobileUnreadBadge.innerHTML = markup;
    }
  }  

});
