import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets  = [ "output" ]
  static values   = { klass: String }

  addZIndex() {
    if (!this.hasKlassValue || !this.hasOutputTarget) { return }
    this.outputTarget.classList.add(this.klassValue)
  }

  removeZIndex() {
    if (!this.hasKlassValue || !this.hasOutputTarget) { return }
    this.outputTarget.classList.remove(this.klassValue)
  }
}
