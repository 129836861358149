import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "select", "button" ]

    connect() {

    }

    showButton(event) {
        this.buttonTarget.classList.remove('hidden')
    }
}
