
import { Controller } from "@hotwired/stimulus";
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ["form", "input", "output", "pagination", "resultCount"];
  static values = {
    setHistory: Boolean,
  }

  connect() {
    this.formInputs = this.inputTargets;
    this.results    = this.hasOutputTarget ? this.outputTarget : null;
    this.pagination = this.paginationTargets.length > 0 ? this.paginationTarget : null;
    if (this.hasOutputTarget) {
      this.results.classList.add("transition-opacity" ,"duration-500" ,"ease-in-out")
    }
  }

  initialize(){
    this.handleChange = debounce(this.handleChange, 500).bind(this)
  }

  handleChange(e) {
    const url       = this.setUrl(e);
    const type      = "GET";
    const dataType  = "json";
    this.setLoader();
    this.dispatchCustomEvent('live-search-started');
    Rails.ajax({
      url,
      type,
      dataType,
      success: (data) => {
        const { entries, resultCount, pagination } = data;
        this.removeLoader();
        this.setResults(entries);
        this.setPagination(pagination);
        if (this.hasSetHistoryValue === false && this.setHistoryValue == false) {}
        else {
          this.setHistoryState(url);
        }
        this.dispatchCustomEvent('live-search-success');
        this.dispatchCustomEvent('result-count', {
          detail: {
            resultCount
          }
        });
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  setResults(results){
    if (this.hasOutputTarget) {
      this.results.innerHTML = results
    }
  }

  setResultCount(event) {
    if(event.type == "result-count" && this.hasResultCountTarget) {
      this.resultCountTargets.forEach(resultCountTarget => resultCountTarget.innerHTML = event.detail.resultCount);
    }
  }

  setPagination(pagination) {
    if(pagination) {
      this.pagination.innerHTML = pagination;
    } else {
      //this call error on old pages, missing this.pagination , strange change by Collin
      this.pagination.innerHTML = "<p class='my-3 text-center'>You have reached the end of the page.</p>"
    }
  }

  // I refactored this to handle a case where
  // There are multiple forms on the same page
  // Such as mobile filters
  setUrl(e) {
    const form = e.target.closest("form");
    if (form != null) {
      let url = new URL(form.action);
      const formFields = Array.from(form.querySelectorAll("[data-live-search-target='input']"));
      formFields.forEach((formField) => {
          if (formField.getAttribute("disabled") != "disabled") {
            url.searchParams.append(formField.name, formField.value)
          }
        }
      );
      return url.href;
    }
  }

  setLoader() {
    this.results.classList.add("opacity-25", "cursor-wait");
  }

  removeLoader() {
    this.results.classList.remove("opacity-25", "cursor-wait");
  }

  setHistoryState(urlPath) {
    window.history.pushState({turbolinks: true, url: urlPath}, '', urlPath);
  }

  dispatchCustomEvent(event, detailObj) {
    window.dispatchEvent(new CustomEvent(event, detailObj));
  }

}
