const defaultTailwindColors = require("tailwindcss/colors");

const colors = {
  // TODO: Remove this default colors after migrating to v3
  orange: defaultTailwindColors.orange,
  teal: defaultTailwindColors.teal,
  cyan: defaultTailwindColors.cyan,
  lightblue: defaultTailwindColors.lightBlue, // "sky" in the v3
  rose: defaultTailwindColors.rose,
  "true-gray": defaultTailwindColors.trueGray, // "neutral-500" in the v3

  primary: { 100: "#f7fafb" },
  secondary: { 200: "#AE9DFB", 600: "#6F0082" },
  tertiary: {
    100: "#F2F2F2",
    200: "#CCCCCC",
    500: "#666666",
    600: "#444444",
  },
  danger: {
    200: "#EB84AC",
    500: "#D7263B",
    600: "#870002",
  },
  warning: {
    500: "#FF7043",
    600: "#CC2200",
    700: "#FF6842",
  },
  "sidebar-divider": "#D9D9D9",
  success: {
    500: "#2DCD5E",
    600: "#0D8219",
  },
  code: {
    400: "#fefcf9",
    600: "#3c455b",
  },
  "red-gmail": "#CF4332",
  "blue-fb": "#3C66C4",
  "stripe-error": "#EB1C26",
  "progress-off-track": "#FF6B4F",
  "progress-risky": "#FDCC44",
  "imp-purple": "#4222F1",
  "imp-purple-dark": "#271592",
  "imp-video": "#222B35",
  "yellow-rating": "#FFD74B",
  "gray-dropdown": "#E2E2E2",
  "gray-pipe": "#D2D2D2",
  "gray-inactive": "#A4A6A8",
  "gray-button-inactive-text": "#C2C5C9",
  "gray-button-inactive": "#E4E5EA",
  "gray-form-inactive": "#8F9194",
  "gray-active": "#E5E6EB",
  "gray-business": "#D1E5F2",
  "gray-signin": "#F5F6F7",
  "gray-signin-border": "#DDDFE2",
  "green-checkbox": "#59C76B",
  "gray-medium": "#292a2b",
  "gray-light": "#b0b2b7",
  "link-preview-host-gray": "#636060",
  "carousel-caption": "#606770",
  "carousel-arrow": "#636060",
  "gray-lesson-hover": "#3D3E3E",
  "gray-dark-border": "#3D3D3D",
  "primary-video-hover": "#2C3640",
  "theater-mode": "#1B1B1B",
  "gray-profile-link-icon-hover": "#4e4f50",
  "gray-course-icons": "#515255",
  "gray-bar": "#d3d3d3",
  "gray-light-notifications": "#E4E6EB",
  "gray-notification-button-hover-state": "#494A4A",
  "notification-red": "#FF0000",
  "required-red": "#DC2625",
  "purple-gradient-start": "#7730be",
  "purple-gradient-end": "#3d1f5b",
  "teal-gradient-start": "#44c6d7",
  "teal-gradient-end": "#0097a7",
  "create-button-hover": "#dce8f0",
  "custom-orange": {
    100: "#fffaf0",
    400: "#f6ad55",
    500: "#ed8936",
    600: "#dd6b20",
    800: "#9c4221",
  },
  "custom-yellow": {
    100: "#fffff0",
    200: "#fefcbf",
    300: "#faf089",
    400: "#f6e05e",
    500: "#ecc94b",
    600: "#d69e2e",
    800: "#975a16",
  },
  "custom-teal": {
    500: "#38b2ac",
  },
  "custom-purple": {
    600: "#805ad5",
  },
  "custom-blue": {
    100: "#ebf8ff",
    200: "#bee3f8",
    300: "#90cdf4",
    400: "#63b3ed",
    450: "#44ABE3",
    500: "#4299e1",
    600: "#3182ce",
    700: "#2b6cb0",
    800: "#2c5282",
    900: "#2a4365",
  },
  "custom-red": {
    100: "#fff5f5",
    300: "#feb2b2",
    400: "#fc8181",
    500: "#f56565",
    600: "#e53e3e",
    700: "#c53030",
    800: "#9b2c2c",
    900: "#742a2a",
  },
  "custom-indigo": {
    100: "#ebf4ff",
    200: "#c3dafe",
    300: "#a3bffa",
    500: "#667eea",
    600: "#5a67d8",
    700: "#4c51bf",
    800: "#434190",
    900: "#3c366b",
  },
  "custom-green": {
    100: "#f0fff4",
    200: "#c6f6d5",
    300: "#9ae6b4",
    400: "#68d391",
    500: "#48bb78",
    600: "#38a169",
    700: "#2f855a",
    800: "#276749",
  },
  "custom-gray": {
    100: "#f4f5f7",
    300: "#d2d6dc",
    400: "#9fa6b2",
    800: "#252f3f",
    900: "#1a202c",
  },
  //
  // DESIGN SYSTEM COLORS
  // https://www.figma.com/file/UGLmtnG2u1EpCIHQ8m764H/IM%2B-Web-App-Branding
  //
  //
  "im-misc-pro": "#4965F6",
  "im-misc-pro-dark": "#3d56d4",
  "im-misc-pro-hover": "#3549B2",
  "im-misc-free": "#59C86B",
  "im-misc-free-dark": "#69C585",
  "im-misc-green": {
    300: "#43A160",
  },
  "im-misc-white": "#FFFFFF", // colors.white
  "im-misc-black": "#000000", // colors.black
  "im-dark": {
    100: "#3D3E3E",
    200: "#292A2B",
    300: "#2C3640",
    400: "#1B1B1B",
  },
  "im-primary-blue": {
    100: "#F8FAFB",
    200: "#E4F0F8",
    300: "#1082C1",
    400: "#0C6292",
  },
  "im-primary-gray": {
    100: "#F0F2F5",
    200: "#E4E5E9",
    300: "#B0B2B7",
    400: "#87888C",
    500: "#65676B",
    600: "#222222",
  },
  "im-gradient-blue-end": "#44ABE3",
};

module.exports = colors;
