import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "output" ]

  handleSubmit(event) {
    const [_data, _status, xhr] = event.detail;
    if(_status == "OK" || xhr.status == 200) {
      this.outputTarget.innerHTML = _data;
    }
  }
}
