import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["course", "lesson", "resource", "completableType", "completableId"];

  connect() {
    this.courseTarget.classList.add("hidden");
    this.lessonTarget.classList.add("hidden");
    this.resourceTarget.classList.add("hidden");
  }

  showDropdownAndSetCompletableType(event) {
    if (event.currentTarget.value == "Course") {
      this.showCourses(event)
    } else if (event.currentTarget.value == "Lesson") {
      this.showLessons(event)
    } else if (event.currentTarget.value == "Resource") {
      this.showResources(event)
    }
  }

  setCompletableId(event) {
    this.completableIdTarget.value = event.currentTarget.value;
    console.log(this.completableIdTarget.value);
  }

  showCourses(event) {
    this.completableIdTarget.value = "";
    this.courseTarget.classList.remove("hidden");
    this.courseTarget.value = "";
    this.lessonTarget.classList.add("hidden");
    this.resourceTarget.classList.add("hidden");
    this.completableTypeTarget.value = event.currentTarget.value;
    console.log(this.completableTypeTarget.value);
    console.log(this.completableIdTarget.value);
  }

  showLessons(event) {
    this.completableIdTarget.value = "";
    this.lessonTarget.classList.remove("hidden");
    this.lessonTarget.value = "";
    this.courseTarget.classList.add("hidden");
    this.resourceTarget.classList.add("hidden");
    this.completableTypeTarget.value = event.currentTarget.value;
    console.log(this.completableTypeTarget.value);
    console.log(this.completableIdTarget.value);
  }

  showResources(event) {
    this.completableIdTarget.value = "";
    this.resourceTarget.classList.remove("hidden");
    this.resourceTarget.value = "";
    this.lessonTarget.classList.add("hidden");
    this.courseTarget.classList.add("hidden");
    this.completableTypeTarget.value = event.currentTarget.value;
    console.log(this.completableTypeTarget.value);
    console.log(this.completableIdTarget.value);
  }

}
