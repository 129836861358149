import { makeRequest } from "./helpers";

const finish = (args = {}) => {
  const data = {
    job_role_id: args.data.jobRole,
    employees_id: args.data.employees,
    industry_id: args.data.industry,
    pronouns_id: args.data.pronouns,
    avatar_signed_id: args.data.avatarSignedId,
  };
  const sanitizedArgs = { ...args, data };

  makeRequest({ url: "/users/registrations/finish", ...sanitizedArgs });
};

export default { finish };
