import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "group"];

  connect() {
    this.findDropdown();
    this.handleChange();
  }

  handleChange() {
    this.groupTargets.forEach((groupTarget) => {
      const groupName = groupTarget.getAttribute(
        "data-form-group-select-group-name"
      );
      if (groupName === this.dropdown.value) {
        groupTarget.classList.remove("hidden");
      } else {
        groupTarget.classList.add("hidden");
      }
    });
  }

  findDropdown() {
    if (this.dropdownTarget.tagName === "SELECT") {
      this.dropdown = this.dropdownTarget;
    } else {
      [this.dropdown] = this.dropdownTarget.getElementsByTagName("select");
    }
  }
}
