const kinds = [
  {
      type: "create",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "general",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "confirmation",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "secondary",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "pro",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "free",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "dark-mode",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "delete",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "blaze",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "simple-delete",
      
      
      
      
      
      
      
      
      
      
      
      
  },
  {
      type: "simple-secondary",
      
      
      
      
      
      
      
      
      
      
      
      
  },
];
module.exports = kinds;
