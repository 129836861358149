import StimulusReflex from 'stimulus_reflex';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [ "totalAmount", "totalDescription", "monthAmount", "yearAmount", "quantity", "plan", "submit", "summary" ]

  static values = {
    detailsUrl: String,
    planId: String,
    quantity: Number,
    subscriptionId: String,
  }

  connect() {
    StimulusReflex.register(this);
    this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    this.checkSubmitButton();
  }

  changePlanAmounts(event) {
    const quantity = this.quantityTarget.value;
    const planAmounts = this.monthAmountTargets;
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

    for (const plan of this.planTargets) {
      if (plan.disabled) { continue; }

      const amount = planAmounts.find(planAmount => plan.dataset.interval == planAmount.dataset.interval);

      if (plan.dataset.interval == 'year') {
        const yearAmount = quantity * plan.dataset.price
        amount.innerHTML = '$' + formatter.format(yearAmount / 12);
        this.yearAmountTarget.innerHTML = ' ' + this.currencyFormatter.format(yearAmount);
      } else {
        amount.innerHTML = '$' + formatter.format(quantity * plan.dataset.monthPrice);
      }
    }
  }

  changeTotal(event) {
    const price = this.currentPlan().dataset.price;
    const quantity = this.quantityTarget.value;
    const interval = this.subscriptionInterval(this.currentPlan().dataset.interval);
    const seat_language = quantity == 1 ? ' Pro Seat' : ' Pro Seats';

    if (this.totalAmountTarget.dataset.newSubscription == 'true') {
      this.totalAmountTarget.innerHTML = 'Total amount billed now: ' + this.currencyFormatter.format(price * quantity);
      this.totalDescriptionTarget.innerHTML = interval + ' subscription (' + quantity + seat_language + ' x ' + this.currencyFormatter.format(price) + '/seat)';
    }
  }

  changeSummary(event) {
    const plan = this.planTargets.find(plan => plan.checked === true);
    this.stimulate('StripeReflex#preview', this.subscriptionIdValue, plan.value, this.quantityTarget.value);
  }

  subscriptionInterval(interval) {
    if (interval == 'month') {
      return 'Monthly';
    } else {
      return 'Yearly';
    }
  }

  checkSubmitButton(event) {
    this.submitTarget.toggleAttribute("disabled", !this.areChangesMade());
  }

  areChangesMade() {
    return this.isPlanChanged() || this.isQuantityChanged()
  }

  isQuantityChanged() {
    return this.quantityValue != this.quantityTarget.value
  }

  isPlanChanged() {
    return this.planIdValue != this.currentPlan().value
  }

  currentPlan() {
    return this.planTargets.find(plan => plan.checked === true);
  }
}
