import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "closeButton", "closeButton", "filterCount", "input", "formWrapper" ]

  // TODO: Set these classes through the Value API
  close() {
    this.containerTarget.classList.add("opacity-0", "delay-300", "pointer-events-none");
    this.containerTarget.classList.remove("opacity-100", "delay-150");

    this.formWrapperTarget.classList.add("translate-y-full", "delay-150");
    this.formWrapperTarget.classList.remove("translate-y-0", "delay-300");
  }

  // TODO: Set these classes through the Value APIs
  open() {
    this.containerTarget.classList.add("opacity-100", "delay-150");
    this.containerTarget.classList.remove("opacity-0", "delay-300", "pointer-events-none");

    this.formWrapperTarget.classList.add("translate-y-0", "delay-300");
    this.formWrapperTarget.classList.remove("translate-y-full", "delay-150");
  }

  tallyFilters(){
    const enabledFiltersCount =  this.inputTargets.filter(input => input.checked).length
    this.filterCountTargets.forEach((filterCountTarget)=>{
      filterCountTarget.innerHTML = enabledFiltersCount;
    });
  }
}
