import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import Turbolinks from "turbolinks";


export default class extends Controller {
  static targets = ["checkbox"]
  static values = { id: String, nextVideoUrl: String, quizCompletableType: String, quizCompletableId: String, quizCourseVideoId: String }

  connect() {
  }

  showQuiz() {
    const options = {
      size: 75,
      onSubmit: () => {
        this.markQuizCheckbox(),
        this.markQuizComplete()
      }
    }

    const { toggle } = createPopup(this.idValue, options);
    toggle();
  };

  markQuizComplete() {
    const nextUrl = this.nextVideoUrlValue;
    const completeUrl = this.data.get('complete-url');
    Rails.ajax({
      url: completeUrl,
      type: "POST",
      data: `completable_id=${this.quizCompletableIdValue}&completable_type=${this.quizCompletableTypeValue}&completed=true&completion_percentage=100.00&course_video=${this.quizCourseVideoIdValue}`,
      success: function(data) {
        Turbolinks.visit(nextUrl);
      }
    });
  };

  markQuizCheckbox() {
    this.checkboxTarget.checked = true;
  };
}
