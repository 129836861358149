import { Controller } from "@hotwired/stimulus"
import MessageController from  './message_controller'

export default class extends Controller {
  static targets = ['query', 'users', 'conversationUsers']

  connect() {
    var self = this;
    this.message_controller = new MessageController();
    document.getElementsByClassName("msg-body")[0].addEventListener("keypress", function submitOnEnter(event) {

      var msgBody = document.getElementsByClassName("msg-body")[0];
      if (event.which === 13 && !event.shiftKey) {
        if (msgBody.innerText.trim().length > 0 || msgBody.querySelectorAll('img').length > 0 || self.isFileAttached()) {

          document.getElementsByClassName("msg-body-text")[0].value = document.getElementsByClassName("msg-body")[0].innerHTML;

          document.getElementById("send").click();
          document.getElementsByClassName("msg-body")[0].innerHTML = "";
          self.message_controller.removeImagePreview();
        }
        event.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
      }
    });
    document.getElementsByClassName("msg-body")[0].addEventListener("input", function resizeHeight(e) {
      if (this.scrollHeight < 70) {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
      }
    });
    document.getElementsByClassName("msg-body")[0].addEventListener("keydown", function (e) {
      var key = e.keyCode || e.charCode;
      if (!(key == 8 || key == 46 || key == 13) && this.innerText.length > 250 && !self.isFileAttached()) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
      }
    })
    document.getElementById("conversation-send-button").addEventListener("click", function submitOnClick(event) {
      var msgBody = document.getElementsByClassName("msg-body")[0];
      if (msgBody.innerText.trim().length > 0 || msgBody.querySelectorAll('img').length > 0 || self.isFileAttached()) {
        document.getElementsByClassName("msg-body-text")[0].value = document.getElementsByClassName("msg-body")[0].innerHTML;
        document.getElementById("send").click();
        document.getElementsByClassName("msg-body")[0].innerHTML = "";
        self.message_controller.removeImagePreview();
      }
    });
    document.getElementById("conversation_main").scrollTop = document.getElementById("conversation_main").scrollHeight;
    setTimeout(function () {
      Turbolinks.clearCache()
      document.getElementById("conversation_main").scrollTop = document.getElementById("conversation_main").scrollHeight;
    }, 1000);
  }

  submitSearch() {
    const value = this.queryTarget.value
    fetch(`/conversation_user_search?query=${value}`)
      .then((response) => response.json())
      .then(data => {
        this.conversationUsersTarget.innerHTML = data.conversation_list;
      })
  }

  isFileAttached() {
    var filePreviewContainer = document.getElementById('file_preview_container');
    if (filePreviewContainer && !filePreviewContainer.classList.contains('hidden')) {
      return true;
    }
    return false;
  }
}
