/* eslint-disable no-undef */

import { useDispatch } from "stimulus-use";
import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    useDispatch(this);
    document.addEventListener(
      "turbo:frame-render",
      this.dispatchFormReady.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      "turbo:frame-render",
      this.dispatchFormReady.bind(this)
    );
  }

  dispatchFormReady() {
    Alpine.discoverUninitializedComponents((el) => {
      Alpine.initializeComponent(el);
    });

    setTimeout(() => {
      this.dispatch("form-ready");
    }, 200);
  }
}
