import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  connect() {
    super.connect();

    const scrollContainer = this.flatpickrElement.closest(".overflow-y-scroll");

    if (scrollContainer) {
      this.fp.config.onOpen.push(() => {
        scrollContainer.addEventListener(
          "scroll",
          this.scrollEvent.bind(this),
          { passive: true }
        );
      });
    }
  }

  scrollEvent() {
    this.fp._positionCalendar(); // eslint-disable-line
  }
}
