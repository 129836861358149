import { makeRequest } from "./helpers";

const constructSearchParam = (emails) =>
  emails.reduce((acc, email, index) => {
    if (index === 0) {
      return `${acc}filters[emails][]=${email}`;
    }
    return `${acc}&filters[emails][]=${email}`;
  }, "");

const getExistEmails = async (emails) => {
  if (emails.length === 0) {
    return { items: [], meta: { count: 0 } };
  }
  const request = () =>
    new Promise((resolve, reject) => {
      makeRequest({
        url: `/users.json?${constructSearchParam(emails)}`,
        type: "GET",
        onSuccess: (data) => resolve(data),
        onError: reject,
      });
    });
  try {
    const response = await request();
    return response;
  } catch (error) {
    return false;
  }
};

export default { getExistEmails };
