import Rails from "@rails/ujs";
import { compactObject } from "../helpers";

export function makeRequest(args = {}) {
  const requestData = compactObject({
    type: args.type || "POST",
    dataType: "json",
    url: args.url,
    data: args.data,
    success: args.onSuccess,
    error: args.onError,
    beforeSend(xhr, options) {
      if (options.dataType === "json") {
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

        if (options.data instanceof Object) {
          options.data = JSON.stringify(options.data);
        }
      }
      return true;
    },
  });

  Rails.ajax(requestData);
}
