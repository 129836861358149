import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel", "searchInput", "results"];

  static values = {
    siteId: String,
    communityId: String,
  };

  connect() {
    this.initConfig();
    window.initializeSs360();
    this.bodyTag = document.querySelector("body");
  }

  initConfig() {
    window.ss360Configs ||= {};
    window.ss360Configs.localCommunitySearchConfig = {
      siteId: this.siteIdValue,
      searchBox: {
        selector: "#localCommunitySearchBox",
        placeholder: "Implementing They Ask, You Answer",
      },
      results: {
        embedConfig: {
          contentBlock: "#localCommunitySearchResults",
        },
        showSearchBoxEmbed: false,
        searchQueryParamName: "",
        infiniteScroll: true,
        filters: [
          {
            name: "Community ID",
            values: [
              {
                name: this.communityIdValue,
              },
            ],
          },
        ],
      },
      contentGroups: {
        include: ["Discussions", "Private Discussions"],
      },
      suggestions: {
        show: false,
      },
      layout: {
        mobile: {
          showUrl: true,
        },
        desktop: {
          showUrl: true,
        },
        navigation: {
          position: "top",
        },
      },
      filters: {
        enabled: false,
      },
    };
  }

  openPanel() {
    this.panelTarget.classList.remove("hidden");

    this.searchInputTarget.focus();
    this.bodyTag.classList.add("overflow-hidden");
  }

  closePanel() {
    this.panelTarget.classList.add("hidden");
    this.bodyTag.classList.remove("overflow-hidden");
    this.searchInputTarget.value = "";
    if (this.resultsTarget.firstChild) {
      this.resultsTarget.firstChild.innerHTML = "";
    }
  }
}
