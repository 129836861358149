import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    var id=this.element.id.split('-').pop();
    this.load(id)
    if (this.data.has("refreshInterval")) {
      this.startRefreshing(id)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load(id) {
      fetch(`/user_online?id=${id}`, {
        headers: { accept: 'application/json'}
      }).then((response) => response.json())
      .then(data => {
        var onlineStatus = document.querySelector(`#online-status-user-${id}`)
        var onlineSideStatus = document.querySelector(`#online-side-status-user-${id}`)
        if (onlineStatus != undefined) {
          onlineStatus.firstElementChild.classList.remove('bg-custom-green-400', 'bg-custom-red-500')
          onlineStatus.firstElementChild.classList.add(data.status ? 'bg-custom-green-400' : 'bg-custom-red-500')
          onlineStatus.lastElementChild.innerText = data.status ? 'Online' : 'Offline'
        }
        if (onlineSideStatus != undefined) {
          onlineSideStatus.firstElementChild.classList.remove('bg-custom-green-400', 'bg-custom-red-500')
          onlineSideStatus.firstElementChild.classList.add(data.status ? 'bg-custom-green-400' : 'bg-custom-red-500')
        }
      });
  }

  startRefreshing(id) {
    this.refreshTimer = setInterval(() => {
      this.load(id)
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
  // connect() {
  //   // this.channel = consumer.subscriptions.create('AppearancesChannel', {
  //   //   connected: this._cableConnected.bind(this),
  //   //   disconnected: this._cableDisconnected.bind(this),
  //   //   received: this._cableReceived.bind(this),
  //   // });
  // }

  // _cableConnected() {
  //   // Called when the subscription is ready for use on the server
  // }

  // _cableDisconnected() {
  //   // Called when the subscription has been terminated by the server
  // }

  // _cableReceived(data) {
  //   // Called when there's incoming data on the websocket for this channel
  //   // var onlineStatus = document.querySelector(`#online-status-user-${data['user_id']}`)
  //   // var onlineSideStatus = document.querySelector(`#online-side-status-user-${data['user_id']}`)
  //   // if (onlineStatus != undefined) {
  //   //   onlineStatus.firstElementChild.classList.remove('bg-custom-green-500', 'bg-custom-red-500')
  //   //   onlineStatus.firstElementChild.classList.add(data.online ? 'bg-custom-green-500' : 'bg-custom-red-500')
  //   //   onlineStatus.lastElementChild.innerText = data.online ? 'Online' : 'Offline'
  //   // }
  //   // if (onlineSideStatus != undefined) {
  //   //   onlineSideStatus.firstElementChild.classList.remove('bg-custom-green-500', 'bg-custom-red-500')
  //   //   onlineSideStatus.firstElementChild.classList.add(data.online ? 'bg-custom-green-500' : 'bg-custom-red-500')
  //   // }
  // }
}
