import * as yup from "yup";
import uniqueId from "lodash/uniqueId";
import capitalize from "lodash/capitalize";
import { ValidationApi } from "../../api";

const processState = {
  valid: "valid",
  invalid: "invalid",
  initial: "initial",
};

const validateEmail = (value, emails = []) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Must be valid email")
      .notOneOf(emails, "This person is already in list"),
  });

  try {
    schema.validateSync({ email: value });
    return "";
  } catch (e) {
    return e.message;
  }
};

export default function invites(props) {
  const emptyEmail = {
    id: uniqueId(),
    email: "",
    error: "",
    isValidated: false,
  };
  return {
    fields: [{ ...emptyEmail }],
    processState: processState.initial,
    isCanAddField: true,
    isFinishSending: false,

    change({ target: { value } }, id) {
      const emails = this.fields
        .filter((field) => field.id !== id && field.email !== "")
        .map(({ email }) => email);

      const currentField = this.fields.find((field) => field.id === id);
      const error = validateEmail(value, emails);

      currentField.email = value;
      currentField.error = error;

      const isHaveErrors = this.fields.some((field) => field.error !== "");
      this.processState = isHaveErrors
        ? processState.invalid
        : processState.valid;
    },

    init(dataset, _watch) {
      const currentStepValue = JSON.parse(dataset.currentStepValue);

      if (currentStepValue.emails.length > 0) {
        this.fields = currentStepValue.emails.map((email) => {
          return {
            id: email.id,
            email: email.body,
            error: "",
            isValidated: false,
          };
        });
      }
    },

    isValidField(id) {
      const currentField = this.fields.find((field) => field.id === id);
      const result = currentField.error === "";
      return result;
    },

    addEmail() {
      const newField = { ...emptyEmail, id: uniqueId() };
      this.fields = [...this.fields, newField];
      this.isCanAddField = this.fields.length <= 4;
    },

    getFinishText(applicationName) {
      const isCanSend = this.fields.every(({ email }) => email === "");
      return isCanSend
        ? `Jump into ${applicationName}`
        : `<span class="hidden sm:inline">Send Invites and </span> jump into ${applicationName}`;
    },

    removeEmail(event, id) {
      this.fields = this.fields.filter((el) => el.id !== id);
      this.isCanAddField = this.fields.length <= 4;
      const isNotHaveError = this.fields.every(({ error }) => error === "");
      this.processState = isNotHaveError
        ? processState.valid
        : processState.invalid;
      this.isCanAddField = this.fields.length <= 4;
    },

    prevStep(event, redirectUrl) {
      const sanitizedFields = this._getSanitizedFields();
      props.onPrevStep(
        event,
        {
          emails: sanitizedFields.map(({ id, email }) => ({
            id,
            body: email,
          })),
        },
        redirectUrl
      );
    },

    async finish(event, redirectUrl, applicationName) {
      const sanitizedFields = this._getSanitizedFields();
      const emails = sanitizedFields.map(({ email }) => email);
      const response = await ValidationApi.getExistEmails(emails);
      const validEmails = response.items.map(({ email }) => email);
      this.fields = this.fields.map((field) => {
        if (field.email === "") {
          return {
            ...field,
            isValidated: false,
            error: "",
          };
        }
        const isExistEmail = validEmails.includes(field.email);
        return {
          ...field,
          isValidated: !isExistEmail,
          error: isExistEmail ? `This person is already an ${applicationName} member` : "",
        };
      });

      if (
        this.fields.every(
          ({ isValidated, email }) => isValidated || email === ""
        )
      ) {
        if (this.isFinishSending) {
          return;
        }
        this.isFinishSending = true;
        props.onFinish(
          event,
          {
            emails: sanitizedFields.map(({ id, email }) => ({
              id,
              body: email,
            })),
          },
          redirectUrl
        );
      }
    },

    _getSanitizedFields() {
      return this.fields.filter(({ email }) => email !== "");
    },
  };
}
