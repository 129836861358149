import { Controller } from "@hotwired/stimulus";

const Choices = require("choices.js");

export default class extends Controller {
  static targets = ["choice", "toggler"];

  static values = { options: String, removeItemButton: Boolean };

  connect() {
    this.choicesInput = new Choices(this.choiceTarget, {
      classNames: {
        containerOuter: `choices ${
          this.choiceTarget.getAttribute("data-additional-outer-class") || ""
        }`,
      },
      searchEnabled: false,
      searchChoices: false,
      shouldSort: false,

      callbackOnCreateTemplates(template) {
        return {
          item: (_, data) => {
            const { score, scoreClass } = JSON.parse(data.customProperties);
            return template(`
                <div class="choice-item">
                  <div class="choice-item__score ${scoreClass}">${score}</div>
                  <div class="choice-item__label">${data.label}</div>
                </div>
            `);
          },
          choice: (classNames, data) => {
            if (data.selected) {
              return template("<div></div>");
            }
            const { score, scoreClass } = JSON.parse(data.customProperties);
            const disabledClass = data.disabled
              ? classNames.itemDisabled
              : classNames.itemSelectable;
            const selectedClass = data.selected ? classNames.selectedState : "";

            return template(`
                <div class="${classNames.item} ${
              classNames.itemChoice
            } ${disabledClass} ${selectedClass}"
                  data-select-text="${this.config.itemSelectText}"
                  data-choice ${
                    data.disabled
                      ? 'data-choice-disabled aria-disabled="true"'
                      : "data-choice-selectable"
                  }
                  data-id="${data.id}"
                  data-value="${data.value}"
                  ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
                >
                  <div class="choice-item">
                    <div class="choice-item__score ${scoreClass}">${score}</div>
                    <div class="choice-item__label">${data.label}</div>
                  </div>
                </div>
            `);
          },
        };
      },
    });

    this.choicesInput.passedElement.element.addEventListener(
      "choice",
      (event) => {
        const { url } = JSON.parse(
          event.detail.choice.customProperties
        );
        window.location.href = url;
      }
    );

  }
}
