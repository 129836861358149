import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["customerName", "customerEmail", "customerSaveButton"];

  toggleBillingDetails(e) {
    e.preventDefault();

    this.customerNameTarget.toggleAttribute("disabled");
    this.customerEmailTarget.toggleAttribute("disabled");
    this.customerSaveButtonTarget.classList.toggle("hidden");
    this.customerNameTarget.focus();
  }
}
