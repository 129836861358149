import ApplicationController from "../application_controller";
import Turbolinks from "turbolinks";

const COMPLETION_THRESHOLD = 0.8;
const RATING_POPUP_ON_COURSE_COMPLETION_PERCENTAGE = 75;
const RATING_CLOSED = "ratingClosed";
const LAST_WATCHED_COURSE_ID = "lastWatchedCourseId";

export default class extends ApplicationController {
  static targets = ["continueButton", "rating", "progressBar"];

  static values = {
    id: Number,
    courseId: Number,
    videoCompletionUrl: String,
    nextQuiz: Boolean,
    quizSelector: String,
    showContinueButton: Boolean,
    completed: Boolean,
    courseCompletion: Number,
    rated: Boolean,
    someVideoWatched: Boolean,
  };

  connect() {
    super.connect();

    document.addEventListener("turbolinks:load", () => {
      setTimeout(() => {
        this.alignByCenter();
      }, 500);
    });

    const lastCourseId = sessionStorage.getItem(LAST_WATCHED_COURSE_ID);
    if (lastCourseId != this.courseIdValue.toString()) {
      sessionStorage.removeItem(RATING_CLOSED);
    }

    sessionStorage.setItem(LAST_WATCHED_COURSE_ID, this.courseIdValue);
  }

  beforeReflex() {
    this.completionInProgress = true;
  }

  afterReflex() {
    this.completionInProgress = false;
  }

  reflexSuccess() {
    this.completedValue = true;
    this.courseCompletionValue = this.progressBarTarget.dataset.progress;
  }

  reflexHalted() {
    this.completedValue = true;
    this.courseCompletionValue = this.progressBarTarget.dataset.progress;
  }

  onVideoEnded(_event) {
    this.someVideoWatchedValue = true;
    const ratingClosed = sessionStorage.getItem(RATING_CLOSED);

    if (this.nextQuizValue) {
      const quizButton = document.querySelector(this.quizSelectorValue);
      if (quizButton) {
        quizButton.click();
      }
    } else if (
      this.hasRatingTarget &&
      !this.ratedValue &&
      this.courseCompletionValue >=
        RATING_POPUP_ON_COURSE_COMPLETION_PERCENTAGE &&
      !ratingClosed
    ) {
      this.ratingTarget.click();
    } else if (this.videoCompletionUrlValue.length > 0) {
      Turbolinks.visit(this.videoCompletionUrlValue);
    }

    setTimeout(() => {
      this.showContinueButtonValue = true;
    }, 5000);
  }

  onVideoPlay(_event) {
    this.showContinueButtonValue = false;
  }

  onVideoTimeUpdate(event) {
    const percent = event.detail.percent;

    if (
      percent >= COMPLETION_THRESHOLD &&
      !this.completedValue &&
      !this.completionInProgress
    ) {
      this.completeLesson();
    }
  }

  onRatingClose(event) {
    event.preventDefault();

    const ratingClosed = sessionStorage.getItem(RATING_CLOSED);

    if (this.someVideoWatchedValue && !ratingClosed) {
      sessionStorage.setItem(RATING_CLOSED, "true");
    }
  }

  completeLesson() {
    this.stimulate("VideoCompletionsReflex#mark_as_completed", this.idValue);
  }

  alignByCenter() {
    const activeLesson = document.querySelector(".js-active-lesson");

    if (!!activeLesson) {
      const sidebar = activeLesson.offsetParent;
      const sidebarHeight = sidebar.clientHeight;
      const activeLessonOffset = activeLesson.offsetTop;
      const activeLessonHeight = activeLesson.offsetHeight;

      sidebar.scrollTo({
        top: activeLessonOffset + activeLessonHeight - sidebarHeight / 2,
        behavior: "smooth",
      });
    }
  }

  showContinueButtonValueChanged(value) {
    if (this.hasContinueButtonTarget) {
      if (value) {
        this.continueButtonTarget.classList.remove("hidden");
      } else {
        this.continueButtonTarget.classList.add("hidden");
      }
    }
  }
}
