import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previousPrincipleButton", "previousPrincipleLink"];

  connect() {
    this.form = this.element;
    this.requireReason = JSON.parse(this.data.get("require-reason-for-score"));
    if(this.requireReason == true) this.form.classList.add('scorecard-modal__form--required');

    const textarea = this.form.querySelector("textarea");
    this.reasonSet = !!textarea.value;

    const inputs = Array.from(this.form.querySelectorAll("input[type='radio"));
    this.scoreSet = inputs.map(item => item.checked).some(item => item == true);

    this.togglePrevious();
    this.toggleSubmitButton();
  }

  handleChange(e) {
    this.setActiveLabel(e);
    this.scoreSet = true;
    this.togglePrevious();
    this.toggleSubmitButton();
  }

  handleInput(e) {
    if(this.requireReason == false) return;

    this.reasonSet = !!e.target.value.trim();
    this.toggleSubmitButton();
  }

  onPreviousPrincipleLinkClicked(event) {
    event.preventDefault();
    const templateWrapper = event.target.closest(".scorecard-modal");
    const templateTag = templateWrapper.querySelector("template");
    const previousTemplateId = `#core_competency_${templateWrapper.dataset.previousCoreCompetency}_wrapper template`;
    const previousTemplate = document.querySelector(previousTemplateId);

    templateTag.nextElementSibling.remove();
    previousTemplate.after(previousTemplate.content.cloneNode(true));
  }

  setActiveLabel(e) {
    const value = parseInt(e.target.value);
    const label = e.target.offsetParent;
    const labels = this.form.querySelectorAll("li label");
    labels && labels.forEach(label => label.classList.remove(
      "text-white",
      "bg-im-misc-free-dark",
      "bg-progress-risky",
      "bg-progress-off-track",
      "border-im-misc-free-dark",
      "border-progress-risky",
      "border-progress-off-track",
    ));
    labels && labels.forEach(label => label.classList.add(
      "border-custom-gray-100",
    ));
    if(value >= 0 && value <= 6) {
      label.classList.add("text-white", "bg-progress-off-track", "border-progress-off-track");
      label.classList.remove("text-black")
    } else if (value > 6 && value < 9) {
      label.classList.add("text-white", "bg-progress-risky", "border-progress-risky");
      label.classList.remove("text-black")
    } else {
      label.classList.add("text-white", "bg-im-misc-free-dark", "border-im-misc-free-dark");
      label.classList.remove("text-black")
    }
  }

  togglePrevious() {
    if (this.hasPreviousPrincipleButtonTarget) {
      this.previousPrincipleButtonTarget.classList.toggle(
        "hidden",
        !this.scoreSet
      );

      this.previousPrincipleLinkTarget.classList.toggle(
        "hidden",
        this.scoreSet
      );
    }
  }

  toggleSubmitButton() {
    const submitButton = this.form.querySelector("input[type='submit']");
    const submitPopup = this.form.querySelector(".scorecard-modal__submit-popup");

    if(this.requireReason) {
      if(this.scoreSet && this.reasonSet) {
        submitButton && submitButton.removeAttribute("disabled");
        submitPopup && submitPopup.classList.add("scorecard-modal__submit-popup--hidden")
      } else {
        submitButton && submitButton.setAttribute("disabled", "disabled");
        submitPopup && submitPopup.classList.remove("scorecard-modal__submit-popup--hidden")

      }
    } else {
      if(this.scoreSet) {
        submitButton && submitButton.removeAttribute("disabled");
        submitPopup && submitPopup.classList.add("scorecard-modal__submit-popup--hidden")
      }
    }
  }
}
