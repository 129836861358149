import Turbolinks from "turbolinks";
import ApplicationController from "../application_controller";

const svgOpenedClassName = "sidebar-filter__trigger-icon--opened";
const searchParamName = "user_ids";

// this helps to not reload page twice after switching filter options
// More info: https://github.com/turbolinks/turbolinks/issues/102
document.addEventListener("turbolinks:before-visit", () => {
  Turbolinks.clearCache();
});

export default class extends ApplicationController {
  static targets = ["filterItem", "filterList", "clearFilters", "trigger"];

  static classes = ["active"];

  static values = { itemUserId: Number };

  connect() {
    super.connect();

    if (!this.filterItemTargets.length) {
      return;
    }
    this.markActiveFilterOption();
    this.setControlsVisibility();
  }

  markActiveFilterOption() {
    const url = new URL(window.location);
    const activeUserId = url.searchParams.get(searchParamName);

    if (!activeUserId) {
      return;
    }

    this.filterItemTargets.forEach((item) => {
      if (item.dataset.userId === activeUserId) {
        item.classList.add(this.activeClass);
      }
    });
  }

  setControlsVisibility() {
    if (
      !this.hasClearFiltersTarget ||
      !this.hasFilterListTarget ||
      !this.hasTriggerTarget
    ) {
      return;
    }

    const url = new URL(window.location);
    const isFilterActive = url.searchParams.has(searchParamName);

    if (isFilterActive) {
      this.clearFiltersTarget.classList.remove("hidden");
      this.filterListTarget.classList.remove("hidden");
      this.triggerTarget.querySelector("svg").classList.add(svgOpenedClassName);
    } else {
      this.clearFiltersTarget.classList.add("hidden");
      this.filterListTarget.classList.add("hidden");
      this.triggerTarget
        .querySelector("svg")
        .classList.remove(svgOpenedClassName);
    }
    this.triggerTarget.querySelector("svg").classList.remove("hidden");
  }

  onPeopleFilterClicked(event) {
    event.preventDefault();

    const { userId } = event.currentTarget.dataset;
    const url = new URL(window.location);
    if (url.searchParams.get(searchParamName) === userId) {
      return;
    }

    url.searchParams.set(searchParamName, userId);

    Turbolinks.visit(url);
  }

  onPeopleFilterClearClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    const url = new URL(window.location);
    url.searchParams.delete(searchParamName);

    Turbolinks.visit(url);
  }

  toggleOptionsVisibility() {
    this.filterListTarget.classList.toggle("hidden");
    this.triggerTarget
      .querySelector("svg")
      .classList.toggle(svgOpenedClassName);
  }
}
