import Trix from "trix";
import debounce from "lodash/debounce";

const { lang } = Trix.config;
lang.attachFiles = "Upload an Image";
lang.embedVideo = "Upload a Video";

Trix.config.toolbar = {
  getDefaultHTML() {
    return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      </span>
      <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attach-video" data-trix-attribute="video-href" data-trix-action="link" title="${lang.embedVideo}" tabindex="-1">${lang.embedVideo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="flex">
            <input type="button" class="btn btn-secondary btn-small mr-1" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="btn btn-tertiary outline btn-small" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new">
            Would you like to embed media from this site?
            <input class="btn btn-tertiary outline btn-small ml-3" type="button" data-behavior="embed_url" value="Yes, embed it">
          </div>
        </div>
      </div>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="video-href" data-trix-dialog-attribute="video-href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="video-href" class="trix-input trix-input--dialog" placeholder="Link to YouTube, Vimeo, Loom, or Vidyard" aria-label="${lang.url}" required data-trix-input>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new">
            Would you like to embed media from this site?
            <input class="btn btn-tertiary outline btn-small ml-3" type="button" data-behavior="embed_url" value="Yes, embed it">
          </div>
        </div>
      </div>
    </div>
  `;
  },
};

class EmbedController {
  constructor(element) {
    this.patterns = undefined;
    this.element = element;
    this.editor = element.editor;
    this.toolbar = element.toolbarElement;
    this.hrefElement = this.toolbar.querySelector(
      "[data-trix-input][name='href']"
    );
    this.videoHrefElement = this.toolbar.querySelector(
      "[data-trix-input][name='video-href']"
    );
    this.embedContainerElements = this.toolbar.querySelectorAll(
      "[data-behavior='embed_container']"
    );
    this.embedElements = this.toolbar.querySelectorAll(
      "[data-behavior='embed_url']"
    );

    this.reset();
    this.installEventHandlers();
  }

  installEventHandlers() {
    this.hrefElement.addEventListener("input", this.didInput.bind(this));
    this.hrefElement.addEventListener("focusin", this.didInput.bind(this));
    this.videoHrefElement.addEventListener("input", this.didInput.bind(this));
    this.videoHrefElement.addEventListener("focusin", this.didInput.bind(this));
    this.embedElements.forEach((embedElement) => {
      embedElement.addEventListener("click", this.embed.bind(this));
    });
  }

  didInput(event) {
    const value = event.target.value.trim();

    // Load patterns from server so we can dynamically update them
    if (this.patterns === undefined) {
      this.loadPatterns(value);
      // When patterns are loaded, we can just fetch the embed code
    } else if (this.match(value)) {
      this.fetch(value);
      // No embed code, just reset the form
    } else {
      this.reset();
    }
  }

  loadPatterns(value) {
    Rails.ajax({
      type: "get",
      url: "/embeds/patterns.json",
      success: (response) => {
        this.patterns = response.map(
          (pattern) => new RegExp(pattern.source, pattern.options)
        );
        if (this.match(value)) {
          this.fetch(value);
        }
      },
    });
  }

  // Checks if a url matches an embed code format
  match(value) {
    return this.patterns.some((regex) => regex.test(value));
  }

  fetch(value) {
    Rails.ajax({
      url: `/embeds?id=${encodeURIComponent(value)}`,
      type: "post",
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this),
    });
  }

  embed() {
    if (this.currentEmbed == null) {
      return;
    }

    const attachment = new Trix.Attachment(this.currentEmbed);

    this.editor.insertString("");
    this.editor.insertAttachment(attachment);
    this.editor.insertString("");
    this.element.focus();
  }

  showEmbed(embed) {
    this.currentEmbed = embed;
    this.embedContainerElements.forEach((embedContainerElement) => {
      embedContainerElement.style.display = "block";
    });
  }

  reset() {
    this.embedContainerElements.forEach((embedContainerElement) => {
      embedContainerElement.style.display = "none";
    });
    this.currentEmbed = null;
  }
}

document.addEventListener("trix-initialize", (event) => {
  new EmbedController(event.target);
});

document.addEventListener("trix-toolbar-dialog-show", (event) => {
  const toolbarAttribute = event.dialogName;

  if (toolbarAttribute !== "href") {
    return;
  }

  const element = event.target;
  const { editor } = element;
  const dialogSelector = `[data-trix-dialog='${toolbarAttribute}']`;
  const dialog = element.toolbarElement.querySelector(dialogSelector);
  const linkButton = dialog.querySelector("[data-trix-method='setAttribute'");
  const unlinkButton = dialog.querySelector(
    "[data-trix-method='removeAttribute'"
  );

  if (editor.attributeIsActive(toolbarAttribute)) {
    linkButton.value = "Update Link";
    unlinkButton.style.display = "block";
  } else {
    linkButton.value = "Link";
    unlinkButton.style.display = "none";
  }
});

const linkBlock = Object.assign(document.createElement("div"), {
  className: "link-opener",
});

document.addEventListener("trix-selection-change", (event) => {
  const { editor } = event.target;

  if (!editor.attributeIsActive("href")) {
    return linkBlock.remove();
  }

  const position = editor.getPosition();
  const { left, top } = editor.getClientRectAtPosition(position);
  const { href } = editor.composition.currentAttributes;
  const linkOpener = Object.assign(document.createElement("a"), {
    target: "_blank",
    className: "link-opener-link",
  });

  linkOpener.href = linkOpener.textContent = href;

  const maxWidthLinkBlock = 350;
  const isAvailableWidth = window.innerWidth - left < maxWidthLinkBlock;
  if (isAvailableWidth) {
    linkBlock.style.right = `0px`;
    linkBlock.style.left = `auto`;
  } else {
    linkBlock.style.left = `${left - 24}px`;
  }

  linkBlock.style.top = `${top + window.pageYOffset + 24}px`;
  linkBlock.textContent = "Open Link: ";
  linkBlock.append(linkOpener);

  document.body.append(linkBlock);
});

const debouncedTrixBlur = debounce(() => linkBlock.remove(), 500);
document.addEventListener("trix-blur", debouncedTrixBlur);
