import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  static values = { withMembersId: String, withMembersType: String };

  static debounces = ["fetchUsers"];

  connect() {
    useDebounce(this, { wait: 500 });
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "name",
      values: this.fetchUsers.bind(this),
      menuItemTemplate: this.itemTemplate,
    });
    this.tribute.attach(this.fieldTarget);
    this.fieldTarget.addEventListener(
      "tribute-replaced",
      this.replaced.bind(this)
    );
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);

    document.addEventListener("dropdown:changed", (event) => {
      this.withMembersIdValue = event.detail.id || "";
      this.withMembersTypeValue = event.detail.type || "";
    });
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
  }

  fetchUsers(text, callback) {
    // workaround for loadingItemTemplate https://github.com/zurb/tribute/issues/573#issuecomment-1280042521
    callback([{ name: text, content: "<span>Loading...</span>" }]);

    let mentionsUrl = `/users/mentions.json?query=${text}`;

    if (!!this.withMembersTypeValue) {
      mentionsUrl = `/users/mentions.json?query=${text}&with_members_type=${this.withMembersTypeValue}&with_members_id=${this.withMembersIdValue}`;
    }
    fetch(mentionsUrl)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch(() => callback([]));
  }

  itemTemplate(item) {
    return item.original.content;
  }

  replaced(e) {
    const mention = e.detail.item.original;
    this.mentionUser(mention.content, mention.sgid);
  }

  _pasteHtml(html, startPos, endPos) {
    const range = this.editor.getSelectedRange();
    const position = range[0];
    const length = endPos - startPos;

    this.editor.setSelectedRange([position - length, position]);
    this.editor.deleteInDirection("backward");
  }

  mentionUser(content, sgid) {
    const attachment = new Trix.Attachment({
      content,
      sgid,
      contentType: "mention",
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }
}
