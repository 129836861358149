import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["button", "collapsible", "expandable", "hidable"]

  connect() {
    this.bodyTag = document.getElementsByTagName("body")[0]
    this.sidebarState = Cookies.get("sidebar_state") || "expanded"
    if (this.sidebarState == "collapsed") {
      this.collapse()
    } else if (this.sidebarState == "expanded") {
      this.bodyTag.classList.add("sidebar-expanded")
      this.bodyTag.classList.remove("sidebar-collapsed")
      this.toggleMessageNotification(true);
    }
    if (window.location.href.indexOf("/courses/") != -1) {
      this.collapse()
    }

  }

  toggle(event) {
    event.preventDefault()

    if (this.sidebarState == "collapsed") {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand() {
    this.hidableTargets.forEach(target => {
      target.classList.remove("hidden")
      target.classList.add('block')
      target.classList.toggle("nav-text")
      target.classList.add("ml-4")
    })

    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add("nav-left-arrow")
      this.buttonTarget.classList.remove("nav-right-arrow")
    }
    this.collapsibleTarget.classList.add("w-64")
    this.collapsibleTarget.classList.remove("w-24")

    this.toggleMessageNotification(true);

    this.expandableTargets.forEach(target => {
      target.classList.add("lg:ml-80")
      target.classList.remove("lg:ml-24")
    })

    this.bodyTag.classList.add("sidebar-expanded")
    this.bodyTag.classList.remove("sidebar-collapsed")

    Cookies.set("sidebar_state", "expanded", { expires: 365, path: "/" })
    this.sidebarState = "expanded"
  }

  collapse() {
    this.hidableTargets.forEach(target => {
      target.classList.add("hidden")
      target.classList.remove('block')
      target.classList.toggle("nav-text")
      target.classList.remove("ml-4")
    })

    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove("nav-left-arrow")
      this.buttonTarget.classList.add("nav-right-arrow")
      this.collapsibleTarget.classList.remove("w-64")
      this.collapsibleTarget.classList.add("w-24")
      this.expandableTargets.forEach(target => {
        target.classList.remove("lg:ml-80")
        target.classList.add("lg:ml-24")
      })
    } else {
      this.collapsibleTarget.classList.add("w-64")
      this.collapsibleTarget.classList.remove("w-24")
    }

    this.toggleMessageNotification(false);

    this.bodyTag.classList.add("sidebar-collapsed")
    this.bodyTag.classList.remove("sidebar-expanded")

    Cookies.set("sidebar_state", "collapsed", { expires: 365, path: "/" })
    this.sidebarState = "collapsed"
  }

  toggleMessageNotification(isExpand) {
    var messageNotification = document.querySelector(".conversation-link > .message-notification-circle");
    if (messageNotification) {

      if (parseInt(messageNotification.innerText) == 0){
        messageNotification.classList.add('hidden');
        messageNotification.classList.remove('inline-flex');
      }
      else{
        messageNotification.classList.remove('hidden');
        messageNotification.classList.add('inline-flex');
      }
    }
  }
}
