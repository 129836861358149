const defaultColor = require("./defaultColors");

const generateDefaultBrandColors = () => ({
  "brand-primary-100": defaultColor["im-primary-blue"]["100"],
  "brand-primary-200": defaultColor["im-primary-blue"]["200"],
  "brand-primary-300": defaultColor["im-primary-blue"]["300"],
  "brand-primary-400": defaultColor["im-primary-blue"]["400"],
  "brand-surface-100": defaultColor["im-primary-gray"]["100"],
  "brand-surface-200": defaultColor["im-primary-gray"]["200"],
  "brand-surface-300": defaultColor["im-primary-gray"]["300"],
  "brand-surface-400": defaultColor["im-primary-gray"]["400"],
  "brand-surface-500": defaultColor["im-primary-gray"]["500"],
  "brand-surface-600": defaultColor["im-primary-gray"]["600"],
});

module.exports = generateDefaultBrandColors;
