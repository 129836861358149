import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets  = [ "input" ]
  static values   = { key: String }

  connect() {
    this.setFormData();
  }

  clearFormData() {
    if ((this.keyValue != null) && (localStorage.getItem(this.keyValue) != null)) {
      localStorage.removeItem(this.keyValue);
    }
  }

  getDataFromInputs() {
    let items = []
    this.inputTargets.forEach(input => items.push([input.name, input.value]))
    return Object.fromEntries(items);
  }

  saveFormData() {
    if(this.keyValue != null) {
        let data            = this.getDataFromInputs();
        let formattedData   = JSON.stringify(data);
        localStorage.setItem(this.keyValue, formattedData)
    }
  }

  setFormData() {
      if ((this.keyValue != null) && (localStorage.getItem(this.keyValue) != null)) {
          let formData = JSON.parse(localStorage.getItem(this.keyValue));
          Object.entries(formData).forEach((entry)=>{
            let name    = entry[0]
            let value   = entry[1]
            let input   = document.getElementsByName(name)[0];
            input && (input.value = value);
          })
      }
  }

}
