import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player';

export default class extends Controller {
  static targets = [ "player" ]

  connect() {
    this.player = new Player(this.playerTarget);
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

}
