import tippy, { followCursor } from "tippy.js";
import ClipboardJS from "clipboard";

function clipboardTooltip(element, message) {
  tippy(element, {
    content: message,
    showOnCreate: true,
    onHidden: (instance) => {
      instance.destroy();
    },
  });
}

document.addEventListener("turbolinks:load", () => {
  tippy(document.querySelectorAll("[data-tippy-content]"), {
    plugins: [followCursor],
  });

  // Clipboard buttons should show tooltip showing result
  const clipboard = new ClipboardJS("[data-clipboard-text]");
  clipboard.on("success", (e) => {
    clipboardTooltip(e.trigger, "Copied!");
  });
  clipboard.on("error", (e) => {
    clipboardTooltip(e.trigger, "Failed!");
  });
});

document.addEventListener("cable-ready:after-morph", () => {
  tippy(document.querySelectorAll("[data-tippy-content]"), {
    plugins: [followCursor],
  });
});

window.addEventListener("teamScoreTotal:valueChanged", () => {
  tippy(document.querySelectorAll("[data-tippy-content]"));
});
