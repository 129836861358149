import { useDispatch } from "stimulus-use";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    id: String,
    ready: Boolean,
    hideCompleted: Boolean,
    filters: Object,
    sorting: Object,
  };

  connect() {
    super.connect();
    useDispatch(this);
    this.readyValue = true;
  }

  disconnect() {
    this.readyValue = false;
  }

  renderTaskListSuccess() {
    this.dispatch("display-tasks");
  }

  toggleCompletedItems(event) {
    event.preventDefault();
    this.hideCompletedValue = !this.hideCompletedValue;
  }

  changeSorting(event) {
    event.preventDefault();
    this.sortingValue = this._buildSorting(
      event.currentTarget.dataset["fieldName"]
    );
  }

  refresh() {
    if (this.readyValue) {
      this.stimulate(
        "TeamTasksReflex#render_task_list",
        this.idValue,
        this.filtersValue,
        this.sortingValue
      );
    }
  }

  hideCompletedValueChanged() {
    this.filtersValue = this._buildFilters();
  }

  filtersValueChanged() {
    this.refresh();
  }

  sortingValueChanged() {
    this.refresh();
  }

  _buildFilters() {
    let filters = { ...this.filtersValue };

    if (this.hideCompletedValue) {
      filters.completed = false;
    } else {
      delete filters.completed;
    }

    return filters;
  }

  _buildSorting(sortingField) {
    if (this.sortingValue.field == sortingField) {
      const nextDir = this._nextDirection(this.sortingValue.direction);
      if (nextDir) {
        return { field: sortingField, direction: nextDir };
      } else {
        return {};
      }
    } else {
      return { field: sortingField, direction: "asc" };
    }
  }

  _nextDirection(prevDirection) {
    if (prevDirection) {
      if (prevDirection == "asc") {
        return "desc";
      } else if (prevDirection == "desc") {
        return null;
      }
    } else {
      return "asc";
    }
  }
}
