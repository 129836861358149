import { useDebounce } from "stimulus-use";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    ready: Boolean,
    title: String,
    assigneeId: String,
    dueDate: String,
    wrapper: String,
    index: String,
  };

  static debounces = ["titleValueChanged"];

  connect() {
    super.connect();
    useDebounce(this, { wait: 500 });

    this.readyValue = true;
  }

  fieldChanged(event) {
    const { target } = event;
    const valueToChange = `${target.dataset.valueToChange}Value`;
    this[valueToChange] = target.value;
  }

  titleValueChanged(newName) {
    if (this.readyValue) {
      this.stimulate(
        "PriorityTaskReflex#change_name",
        this.wrapperValue,
        this.indexValue,
        newName
      );
    }
  }

  assigneeIdValueChanged(newAssigneeId) {
    if (this.readyValue) {
      this.stimulate(
        "PriorityTaskReflex#change_assignee_id",
        this.wrapperValue,
        this.indexValue,
        newAssigneeId
      );
    }
  }

  dueDateValueChanged(newDueDate) {
    if (this.readyValue) {
      this.stimulate(
        "PriorityTaskReflex#change_due_date",
        this.wrapperValue,
        this.indexValue,
        newDueDate
      );
    }
  }
}
