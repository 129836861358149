import moment from "moment";

export const eventCard = () => {
  return {
    remaining: null,
    days: 0,
    hours: 0,
    minutes: 0,
    init(timeLeft) {
      this.remaining = moment.duration(moment(timeLeft).diff(moment(Date.now())));
      const intervalID = setInterval(() => {
        this.remaining = this.remaining.subtract(1, 'seconds');
        if (this.remaining.asSeconds() <= 0) {
          clearInterval(intervalID);
        } else {
          this.days = Math.floor(this.remaining.asDays());
          this.hours = this.remaining.get('hours');
          this.minutes = this.remaining.get('minutes');
        }
      }, 1000);
    },
  }
}
