// See the Tailwind default theme values here:
// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js

const plugin = require("tailwindcss/plugin");
const formsPlugin = require("@tailwindcss/forms");
const typographyPlugin = require("@tailwindcss/typography");
const aspectRatioPlugin = require("@tailwindcss/aspect-ratio");
const lineClampPlugin = require("@tailwindcss/line-clamp");
const themer = require("tailwindcss-themer");

const generateDefaultColors = require("./tw_configs/generateDefaultColors");
const generateTheme1Colors = require("./tw_configs/generateTheme1Colors");
const generateDefaultBrandColors = require("./tw_configs/generateDefaultBrandColors");
// eslint-disable-next-line import/no-unresolved, import/extensions
const theme1Brand = require("./tw_configs/theme1Brand");
const generateTheme1BrandColors = require("./tw_configs/generateTheme1BrandColors");

module.exports = {
  // All the default values will be compiled unless they are overridden below
  mode: "jit",
  purge: ["./app/**/*.{js,rb,html.erb,html.slim,html}"],
  darkMode: "class",
  theme: {
    // Extend (add to) the default theme in the `extend` key
    screens: {
      xs: "420px",
      sm: "640px",
      md: "768px",
      lg: "1024px",
      columns: "1100px",
      xl: "1280px",
      "2xl": "1500px",
      "1625px": "1625px",
      "max-xl": { max: "1279px" },
    },
    fontSize: {
      xxs: "10px",
      legal: "12px",
      xs: "0.8125rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1xl": "1.375rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "4.25xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      h2: "18px",
    },
    minWidth: {
      5: "1.25rem",
      52: "13rem",
      64: "18rem",
    },
    extend: {
      lineHeight: {
        11: "2.75rem",
        12: "3rem",
        14: "3.5rem",
        16: "4rem",
      },
      width: {
        2.25: "9px",
        2.75: "11px",
        3.25: "13px",
        3.75: "15px",
        4.25: "17px",
        4.5: "18px",
        4.75: "19px",
        9: "2.25rem",
        13: "3.375rem",
        14: "3.5rem",
        15: "3.75rem",
        25: "6.25rem",
        26: "6.5rem",
        64: "20rem",
        100: "30rem",
        content: "680px",
        notification: "384px",
        registration: "458px",
        "registration-copy": "649px",
        "invite-input": "calc(100% - 3.5rem)",
      },
      height: {
        2.25: "9px",
        2.75: "11px",
        3.25: "13px",
        3.75: "15px",
        4.25: "17px",
        4.5: "18px",
        4.75: "19px",
        400: "405px",
        13: "3.375rem",
        14: "3.5rem",
        15: "3.75rem",
        84: "24rem",
        100: "25rem",
        104: "26rem",
        120: "32.5rem",
        170: "42.5rem",
        190: "47.5rem",
        220: "55rem",
        "message-window": "calc(50%)",
        "conversation-window": "calc(100vh - 20rem)",
        "conversation-sideBar": "calc(100vh - 4rem)",
        "screen-nav": "calc(100vh - 3.5rem)",
        "screen-minus-nav": "calc(100vh - 3.5rem)",
        "screen-3/4": "75vh",
        "mobile-footer": "87px",
        win: "calc(100vh - 30rem)",
      },
      lineClamp: {
        20: "20",
      },
      maxWidth: {
        104: "31rem",
        116: "34rem",
        content: "680px",
        "cover-photo": "940px",
        "post-show-md": "772px",
        "post-show-lg": "880px",
      },
      top: {
        "1/2": "50%",
        full: "100%",
      },
      right: {
        "1/2": "50%",
        full: "100%",
      },
      left: {
        "1/2": "50%",
        full: "100%",
      },
      bottom: {
        "1/2": "50%",
        "mobile-bar": "4.75rem",
        full: "100%",
      },
      margin: {
        15: "3.75rem",
        26: "6.5rem",
        28: "7rem",
        80: "20rem",
      },
      padding: {
        0.25: "0.0625rem",
        "mobile-footer": "87px",
      },
      transitionProperty: {
        colors: "background-color,color,border-color",
        height: "height",
      },
      inset: {
        76: "19rem",
        "header-offset": "76px",
        "mobile-footer": "87px",
      },
      borderRadius: {
        5: "5px",
        lg: "0.625rem",
        xl: "1.5rem",
      },
      overflow: {
        overlay: "overlay",
      },
      gridTemplateColumns: {
        triple: "minmax(280px, 1fr) minmax(0, 680px) minmax(280px, 1fr)",
        double: "minmax(280px, 360px) 1fr",
        "double-left-sidebar": "minmax(280px, 360px) minmax(300px, 1fr)",
        "double-right-sidebar": "minmax(0, 1fr) minmax(280px, 360px)",
        single: "minmax(250px, 680px)",
        "single-lg": "minmax(250px, 940px)",
        "single-extra-lg": "minmax(250px, 1024px)",
        "single-xl": "minmax(250px, 1350px)",
        "left-sidebar": "minmax(280px, 360px) 1fr",
        homescreen_content:
          "minmax(300px, 1fr) minmax(0, 768px) minmax(300px, 1fr)",
        your_feed_content: "minmax(280px, 360px) minmax(720px, 1fr)",
      },
      opacity: {
        15: "0.15",
      },
      zIndex: {
        "-1": "-1",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        60: "60",
        100: "100",
        200: "200",
        201: "201",
        max: "2147483003",
      },
      keyframes: {
        notification: {
          "0%": { transform: "scale(1)" },
          "14%": { transform: "scale(1.3)" },
          "28%": { transform: "scale(1)" },
          "42%": { transform: "scale(1.3)" },
          "70%": { transform: "scale(1)" },
        },
        fadeIn: {
          from: {
            transform: "scale(0.9)",
            opacity: "0.5",
          },
          to: {
            transform: "scale(1)",
            opacity: "1",
          },
        },
      },
      animation: {
        notification: "notification calc(1s * 1.3) ease-in-out 2",
        fadeIn: "fadeIn 150ms cubic-bezier(0, 0, 0.2, 1)",
      },
      gap: {
        0.5: "0.125rem",
      },
    },
    // override the default theme using the key directly
    fontFamily: {
      sans: [
        "proxima-nova",
        "Lato",
        "-apple-system",
        "BlinkMacSystemFont",
        "'Segoe UI'",
        "Roboto",
        "'Helvetica Neue'",
        "Arial",
        "'Noto Sans'",
        "sans-serif",
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
        "'Noto Color Emoji'",
      ],
    },
    maxHeight: {
      40: "12rem",
      80: "20rem",
      96: "24rem",
      192: "48rem",
      "screen-1/3": "33vh",
      "screen-1/2": "50vh",
      "screen-3/4": "75vh",
      full: "100%",
    },
    minHeight: {
      0: "0px",
      10: "2.5rem",
      12: "3rem",
      32: "8rem",
    },
  },
  variants: {
    extend: {
      borderWidth: ["responsive", "last", "hover", "focus"],
      display: ["responsive", "hover", "focus", "group-hover", "dark"],
      opacity: ["responsive", "hover", "focus", "group-hover", "group-focus"],
      scale: ["responsive", "hover", "focus", "active", "group-hover"],
      zIndex: ["responsive", "hover", "focus", "group-hover", "group-focus"],
      backgroundColor: ["active"],
      divideColor: ["dark"],
      saturate: ["hover", "focus"],
    },
  },
  plugins: [
    formsPlugin,
    typographyPlugin,
    aspectRatioPlugin,
    lineClampPlugin,
    plugin(({ addUtilities }) => {
      const newUtilities = {
        ".ratio-16x9": {
          width: "100%",
          height: "0",
          "padding-bottom": "56.25%",
        },
        ".ratio-4x3": {
          width: "100%",
          height: "0",
          "padding-bottom": "75%",
        },
        ".ratio-3x2": {
          width: "100%",
          height: "0",
          "padding-bottom": "56.25%",
        },
        ".ratio-880x520": {
          width: "100%",
          height: "0",
          "padding-bottom": "59.09%",
        },
        ".mt-30": {
          "margin-top": "7.5rem",
        },
        ".ml-001": {
          "margin-left": "1px",
        },
        ".max-h-200px": {
          "max-height": "200px",
        },
        ".top-shadow-lg": {
          "box-shadow":
            "0 -4px 15px -3px rgba(0, 0, 0, 0.1), 0 -2px 6px -2px rgba(0, 0, 0, 0.05)",
        },
        ".no-scrollbar::-webkit-scrollbar": {
          display: "none",
        },
        ".no-scrollbar": {
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        },
      };

      addUtilities(newUtilities);
    }),
    themer({
      defaultTheme: {
        extend: {
          colors: generateDefaultColors(),
          fill: {
            ...generateDefaultBrandColors(),
            white: "#FFFFFF",
            "im-misc-pro": "#4965F6",
            "im-orange": "#FF6842",
            // TODO: Try to remove 'fill-custom-*' colors after migration complete
            "custom-orange": {
              400: "#fb923c",
            },
            "custom-yellow": {
              500: "#eab308",
            },
            "custom-green": {
              400: "#68d391",
            },
            "custom-gray": {
              400: "#9fa6b2",
              500: "#6b7280",
              600: "#4b5563",
            },
          },
        },
      },
      themes: [
        {
          name: "brand1",
          extend: {
            colors: generateTheme1Colors(),
            fill: {
              ...generateTheme1BrandColors(theme1Brand),
            },
          },
        },
      ],
    }),
  ],
};
