import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"

export default class extends Controller {
    static targets = [ "source", "button" ]

    connect() {
        if (this.hasButtonTarget) {
            this.clipboard = new ClipboardJS(this.buttonTarget);
        }
    }

    copy(event) {
        event.preventDefault()
        this.sourceTarget.select()
        document.execCommand("copy")
    }
}
