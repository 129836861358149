import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "checkbox" ]
  static values = {
    enableConfirmationText: String,
    disableConfirmationText: String
  }

  showConfirmation() {
    var isChecked = this.checkboxTarget.checked;
    var confirmationText = isChecked ? this.enableConfirmationTextValue : this.disableConfirmationTextValue;
    var confirmationResult = confirm(confirmationText);

    if (!confirmationResult) {
      this.checkboxTarget.checked = !isChecked;
    }
  }
}
