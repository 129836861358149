import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import Turbolinks from "turbolinks";
import { RegistrationApi } from "api";

const inlineCloseSvg = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4912 0.485387C16.2269 0.491624 15.9759 0.602221 15.7929 0.793004L8.99996 7.58597L2.20699 0.793004C2.11369 0.697092 2.00209
0.620879 1.8788 0.568878C1.7555 0.516877 1.62303 0.490147 1.48922 0.49027C1.29037 0.490516 1.09611 0.550035 0.93125 0.661223C0.76639
0.772411 0.638419 0.93022 0.563683 1.11449C0.488947 1.29876 0.47084 1.50113 0.511675 1.69574C0.552509 1.89035 0.650432 2.06838
0.792933 2.20707L7.5859 9.00004L0.792933 15.793C0.696959 15.8851 0.620336 15.9955 0.56755 16.1176C0.514765 16.2398 0.486879
16.3712 0.485525 16.5043C0.484172 16.6373 0.509377 16.7693 0.559666 16.8924C0.609956 17.0156 0.684317 17.1275 0.778396
17.2216C0.872475 17.3157 0.98438 17.39 1.10756 17.4403C1.23074 17.4906 1.36271 17.5158 1.49575 17.5145C1.62879 17.5131
1.76022 17.4852 1.88235 17.4324C2.00448 17.3797 2.11485 17.303 2.20699 17.2071L8.99996 10.4141L15.7929 17.2071C15.8851
17.303 15.9954 17.3797 16.1176 17.4325C16.2397 17.4852 16.3711 17.5131 16.5042 17.5145C16.6372 17.5158 16.7692 17.4906
16.8924 17.4403C17.0156 17.39 17.1275 17.3157 17.2215 17.2216C17.3156 17.1275 17.39 17.0156 17.4403 16.8924C17.4906 16.7693
17.5158 16.6373 17.5144 16.5043C17.5131 16.3712 17.4852 16.2398 17.4324 16.1176C17.3796 15.9955 17.303 15.8851 17.207
15.793L10.414 9.00004L17.207 2.20707C17.3525 2.06753 17.4525 1.88728 17.4937 1.68995C17.535 1.49261 17.5157 1.28742 17.4383
1.10126C17.3609 0.915106 17.229 0.756689 17.06 0.646785C16.891 0.536881 16.6927 0.480622 16.4912 0.485387Z" fill="white"/>
</svg>`;

Dropzone.autoDiscover = false;
class Component {
  constructor(element, props = {}) {
    this.element =
      element instanceof Element ? element : document.querySelector(element);
    this._props = Object.assign({}, this.constructor.props, props);
    this._state = this.constructor.state || {};
    this.refs = this._initRefs(this.element, this.constructor.refs || {});
    this.initialize();
    this.componentDidMount();
  }

  _initRefs(element, refs) {
    return Object.entries(refs).reduce((result, [name, config_or_selector]) => {
      let config =
        config_or_selector instanceof Object
          ? config_or_selector
          : { selector: config_or_selector };
      let item = config.array
        ? Array.from(element.querySelectorAll(config.selector))
        : element.querySelector(config.selector);

      return Object.assign(result, { [name]: item });
    }, {});
  }

  initialize() {
    //template method
  }

  componentDidMount() {
    //template method
  }

  componentDidUpdate(_newState, _oldState) {
    //template method
  }

  propsDidUpdate(_newState, _oldState) {
    //template method
  }

  unmount() {
    //template method
  }

  get props() {
    return this._props;
  }

  set props(newProps) {
    let oldProps = Object.assign({}, this._props);
    this._props = newProps;

    this.propsDidUpdate(newProps, oldProps);
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    let oldState = Object.assign({}, this._state);
    this._state = newState;

    this.componentDidUpdate(newState, oldState);
  }
}

class ButtonComponent extends Component {
  static props = {
    disabled: false,
  };

  initialize() {
    this._state = {
      disabled: this.props.disabled,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.element.addEventListener("click", this.handleClick);
  }

  componentDidUpdate(newState, oldState) {
    if (newState.disabled != oldState.disabled) {
      newState.disabled
        ? this.element.setAttribute("disabled", "disabled")
        : this.element.removeAttribute("disabled");
    }
  }

  propsDidUpdate(newProps, oldProps) {
    if (newProps.disabled != oldProps.disabled) {
      this.state = Object.assign({}, this.state, {
        disabled: newProps.disabled,
      });
    }
  }

  unmount() {
    this.element.removeEventListener("click", this.handleClick);
  }

  handleClick(event) {
    this.props.onClick(event);
  }
}

class SelectComponent extends Component {
  static props = {
    value: null,
  };

  initialize() {
    this._state = {
      value: this.props.value,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      placeholder: this.props.placeholder,
      showSearch: this.props.showSearch,
      onChange: this.handleChange,
    });
    let selectedValue = this.slimSelect.selected();

    if (selectedValue) {
      this.state = { ...this.state, value: selectedValue };
    }
  }

  componentDidUpdate(newState, oldState) {
    if (newState.value != oldState.value) {
      this.props.onChange(newState.value);
    }
  }

  handleChange(info) {
    this.state = { ...this.state, value: info.value };
  }

  unmount() {
    this.slimSelect.destroy();
  }
}

class AvatarInputComponent extends Component {
  static props = {
    maxFiles: 1,
    maxFilesize: 2,
    acceptedFiles: "image/*",
    thumbnailWidth: 512,
    thumbnailHeight: 512,
  };

  initialize() {
    this.dropzone = new Dropzone(this.element, {
      url: this.props.uploadUrl,
      maxFiles: this.props.maxFiles,
      maxFilesize: this.props.maxFilesize,
      acceptedFiles: this.props.acceptedFiles,
      autoQueue: true,
      thumbnailWidth: this.props.thumbnailWidth,
      thumbnailHeight: this.props.thumbnailHeight,
      uploadMultiple: false,
      addRemoveLinks: false,
      init: function () {
        this.on("addedfile", (file) => {
          const removeButton = Dropzone.createElement("<div></div>");
          const closeSvg = Dropzone.createElement(inlineCloseSvg);
          const text = Dropzone.createElement("<div>Remove</div>");
          removeButton.appendChild(closeSvg);
          removeButton.appendChild(text);
          removeButton.classList.add(
            "dropzone__removeButton",
            "d-flex",
            "flex-col"
          );

          var _this = this;

          removeButton.addEventListener("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.removeFile(file);
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          });
          file.previewElement.appendChild(removeButton);
        });
      },
    });

    const addFileClone = this.dropzone.addFile.bind(this.dropzone);

    this.dropzone.addFile = (file) => {
      this.dropzone.removeAllFiles();
      addFileClone(file);
    };

    this.dropzone.processFiles = (files) => {
      for (let file of files) {
        file.processing = true; // Backwards compatibility
        file.status = Dropzone.UPLOADING;

        this.dropzone.emit("processing", file);
      }

      if (this.dropzone.options.uploadMultiple) {
        this.dropzone.emit("processingmultiple", files);
      }

      return this.uploadFiles(files);
    };
  }

  componentDidUpdate(newState, oldState) {
    if (newState.file != oldState.file) {
      this.fileChanged(newState.file, oldState.file);
    }
  }

  unmount() {
    this.dropzone.disable();
  }

  fileChanged(newFile) {
    this.dropzone.accept(newFile, (error) => {
      if (error) {
        newFile.accepted = false;
        this.dropzone._errorProcessing([newFile], error);
      } else {
        newFile.accepted = true;
        this.upload(this.props.uploadUrl);
      }
      this.dropzone._updateMaxFilesReachedClass();
    });
  }

  uploadFiles(files) {
    for (let file of files) {
      file.status = Dropzone.UPLOADING;
      this.dropzone.emit("processing", file);

      const directUpload = new DirectUpload(file, this.props.uploadUrl, {
        directUploadWillStoreFileWithXHR: (request) => {
          this.onProgress(file, request);
        },
      });

      directUpload.create((error, blob) => {
        if (error) {
          file.status = Dropzone.ERROR;
          this.dropzone.emit("error", file, error);
          this.dropzone.emit("complete", file);
        } else {
          file.status = Dropzone.SUCCESS;
          this.dropzone.emit("success", file);
          this.dropzone.emit("complete", file);
          this.handleChange(blob);
        }
      });
    }
  }

  handleChange(blob) {
    this.props.onChange(blob);
  }

  onProgress(file, request) {
    request.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      this.dropzone.emit("uploadprogress", file, progress, event.loaded);
    });
  }
}

class FormComponent extends Component {
  static refs = {
    jobRolesSelect: "[data-form-refs='jobRolesSelect']",
    employeesSelect: "[data-form-refs='employeesSelect']",
    industrySelect: "[data-form-refs='industrySelect']",
    pronounsSelect: "[data-form-refs='pronounsSelect']",
    avatarInput: "[data-form-refs='avatarInput']",
  };

  static props = {
    formData: {
      jobRole: null,
      employees: null,
      industry: null,
      pronouns: null,
      avatarSignedId: null,
    },
  };

  initialize() {
    this._state = Object.assign({}, { formData: this.props.formData || {} });
  }

  componentDidMount() {
    this.jobRolesSelect = new SelectComponent(this.refs.jobRolesSelect, {
      placeholder: "Select One",
      showSearch: false,
      onChange: (newValue) => {
        this._updateFormData("jobRole", newValue);
      },
    });

    this.employeesSelect = new SelectComponent(this.refs.employeesSelect, {
      placeholder: "Select One",
      showSearch: false,
      onChange: (newValue) => {
        this._updateFormData("employees", newValue);
      },
    });

    this.industriesSelect = new SelectComponent(this.refs.industrySelect, {
      placeholder: "Select One",
      showSearch: true,
      onChange: (newValue) => {
        this._updateFormData("industry", newValue);
      },
    });

    this.avatarInput = new AvatarInputComponent(this.refs.avatarInput, {
      uploadUrl: this.props.directUploadUrl,
      onChange: (blob) => {
        this._updateFormData("avatarSignedId", blob.signed_id);
      },
    });

    this.pronounsSelect = new SelectComponent(this.refs.pronounsSelect, {
      placeholder: "Select One",
      showSearch: false,
      onChange: (newValue) => {
        this._updateFormData("pronouns", newValue);
      },
    });
  }

  unmount() {
    this.jobRolesSelect.unmount();
    this.employeesSelect.unmount();
    this.industriesSelect.unmount();
    this.avatarInput.unmount();
    this.pronounsSelect.unmount();
  }

  _updateFormData(fieldName, value) {
    const formData = { ...this.state.formData, [fieldName]: value };
    this.state = { ...this.state, formData: formData };
    this.props.onChange(this.state.formData);
  }
}

export default class extends Controller {
  static targets = ["form", "button"];

  static values = {
    formData: Object,
  };

  initialize() {
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  connect() {
    this.button = new ButtonComponent(this.buttonTarget, {
      disabled: true,
      onClick: this.handleButtonClick,
    });

    this.form = new FormComponent(this.formTarget, {
      directUploadUrl: this.uploadsUrl,
      onChange: this.handleFormChange,
    });
  }

  disconnect() {
    this.form.unmount();
  }

  handleFormChange(formData) {
    this.formDataValue = formData;
    const disabled = !formData.jobRole || !formData.industry || !formData.employees
    this.button.props = { ...this.button.props, disabled };
  }

  handleButtonClick(event) {
    event.preventDefault();

    const target = event.target;

    if (!target.disabled) {
      RegistrationApi.finish({
        data: this.formDataValue,
        onSuccess: () => Turbolinks.visit(this.afterFinishUrl),
      });
    }
  }

  get finishRegistrationUrl() {
    return this.element.getAttribute("data-finish-registration-url");
  }

  get uploadsUrl() {
    return this.element.getAttribute("data-uploads-url");
  }

  get afterFinishUrl() {
    return this.element.getAttribute("data-after-finish-url");
  }

  get defaultPronouns() {
    return this.element.getAttribute("data-default-pronouns");
  }
}
