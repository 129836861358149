import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "titleInput"]

  setValue(event) {
    const element = event.target.closest("[data-discussion-format]");
    if(element !== null) {
      this.inputTarget.value = element.dataset.discussionFormat;
      this.inputTarget.dispatchEvent(new Event('change'));
    }
  }

  focusTitleInput(event) {
    this.titleInputTarget.focus();
  }
}
