import { Controller } from "@hotwired/stimulus";
import consumer from '../channels/consumer';
import { Elements } from '../helpers/elements_helper'

export default class MessageController extends Controller {
  static targets = ['input', 'messages', 'pagination', 'file', 'fileLabel'];

  connect() {
    this.channel = consumer.subscriptions.create('MessageChannel', {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
    if (this.hasInputTarget){ this.inputTarget.focus(); }
    this.selectedMessageId = undefined
  }

  scroll() {
    var next_url = document.querySelector("a[rel='prev']")
    if (next_url == null){ return }
    var url = next_url.href
    if (document.getElementsByClassName('page active')[0].textContent=='2')
    { url = next_url.href+'?&pagy_auto=1'}
    else
    { url = next_url.href+'&pagy_auto=1'}
    var conversation = document.querySelector('#conversation_main')
    if (conversation.scrollTop==0) {
      this.loadMore(url)
    }

  }

  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.messagesTarget.insertAdjacentHTML('afterbegin', data.messages)
        this.paginationTarget.innerHTML = data.pagination
        document.getElementById("user-msg").setAttribute("id", "user-msg-2");
        document.getElementById("conversation_main").scrollTop=document.getElementById("user-msg").scrollHeight
      }
    })
  }

  hideAllModals() {
    var elementHelper = new Elements();
    elementHelper.toggleClassToElements(['#delete_content', '#users_reaction_content', '#avatar_selection', '#group_user_list'], ['hidden'], true);
  }

  showFilePopup(event) {
    this.fileLabelTarget.click();
  }

  setModalType(event) {
    this.hideAllModals();
    var isDelete = event.currentTarget.classList.contains('deleteButton');
    if(isDelete){
      this.selectedMessageId = event.target.closest('.deleteButton').dataset.messageId
      document.getElementById('delete_content').classList.remove('hidden');
      document.getElementById('modalOpener').click();
    }
    else {
      window.location.href = "mailto:support@impactplus.com";
    }
  }

  copyMessage(event) {
    var messageContainer = event.currentTarget.closest('.message-container')
    var text = messageContainer.querySelector('.message-text').innerText
    var copyHex = document.createElement('input');
    copyHex.value = text
    document.body.appendChild(copyHex);
    copyHex.select();
    document.execCommand('copy');
    document.body.removeChild(copyHex);
    event.currentTarget.closest('.menu-div').classList.toggle('hidden', true)
  }

  hideOptions(event) {
    if (event.target.closest(".hiddenOptions"))
      return;
    var options = Array.prototype.slice.call(document.getElementsByClassName("hiddenOptions"));
    var popoverContents = Array.prototype.slice.call(document.getElementsByClassName("popover-content"));
    var menuBars = Array.prototype.slice.call(document.getElementsByClassName("menu-div"));
    options = options.concat(popoverContents).concat(menuBars)
    for (var i = 0; i < options.length; i++) {
      options[i].classList.add('hidden');
    }
    document.removeEventListener("click", this.hideOptions);
  }

  showOptions(event) {
    if(event.type == 'touchstart')
      event.preventDefault();
    var options = Array.prototype.slice.call(document.getElementsByClassName("hiddenOptions"));
    var menuBars = Array.prototype.slice.call(document.getElementsByClassName("menu-div"));
    options = options.concat(menuBars)
    for (var i = 0; i < options.length; i++) {
      options[i].classList.add('hidden');
    }
    event.currentTarget.querySelector(".hiddenOptions").classList.remove('hidden');
    document.addEventListener("click", this.hideOptions);
  }

  toggleMenu(event) {
    event.currentTarget.parentElement.getElementsByClassName('menu-div')[0].classList.remove('hidden');
    document.addEventListener("click", this.hideOptions);
  }

  openAvatarModal(event) {
    var Element = new Elements();
    this.hideAllModals();
    Element.toggleClassToElements(['#avatar_selection'], ['hidden']);
    document.querySelector('#modalOpener').click();
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    var response = JSON.parse(data.response)
    var parser = new DOMParser();
    var nodes = document.querySelectorAll('.text-center.my-4.text-gray-500')
    var htmlDoc = parser.parseFromString(response.message_html, 'text/html')
    if (nodes.length > 0 && nodes[nodes.length-1].innerText.trim() == htmlDoc.body.firstElementChild.innerText.trim()) {
      htmlDoc.body.firstElementChild.remove()
      var messageHtml = htmlDoc.body.innerHTML
    }
    if (this.data.get("conversationId") == response.conversation_id) {
      this.messagesTarget.innerHTML += messageHtml != undefined ? messageHtml: response.message_html
      var htmlDoc = parser.parseFromString(response.list_item, 'text/html')
      htmlDoc.body.firstElementChild.classList.toggle("bg-custom-gray-100", true)
      htmlDoc.body.firstElementChild.classList.toggle("active-class", true)
      var unread = htmlDoc.querySelector(`#conversation-id-count-${response.conversation_id}`)
      if (unread) {
        unread.remove()
      }
      var listItemHtml = htmlDoc.body.innerHTML
      if (response.recipient == "receiver") {
        this.updateLastSeen(response.conversation_id, response.message_id)
      }
    }
    var listItem = document.querySelector(`#conversation-list-item-${response.conversation_id}`)
    if (listItem) {
      listItem.remove()
    }
    var conversationList = document.querySelector('#conversation-list')
    if (conversationList) {
      listItemHtml = listItemHtml ? listItemHtml : response.list_item
      conversationList.innerHTML = listItemHtml + conversationList.innerHTML
    }

    if (this.hasInputTarget && response.recipient == "sender"){
      this.inputTarget.value = '';
      this.inputTarget.focus();
      this.fileTarget.value = ''
      var fileLabel = document.querySelector(`label[for="${this.fileTarget.id}"] span`)
      if (fileLabel) {
        fileLabel.innerHTML = ''
      }
    }
    var conversation = document.querySelector('#conversation_main')
    setTimeout(function(){
      conversation.scrollTo(0,conversation.scrollHeight);
    }, 500);
  }

  updateLastSeen(conversationId, message_id){
    fetch(`/update_last_seen?id=${conversationId}&message_id=${message_id}`)
    .then((response) => response.json())
    .then(data => { })
  }

  deleteMessage(event) {
    fetch(`/messages/${this.selectedMessageId}/delete`)
    .then((response) => response.json())
    .then(data => {
      if (data.success) {
        document.querySelector(`#message_${this.selectedMessageId}`).remove()
        if (this.messagesTarget.lastElementChild.classList.contains('date-string')) {
          this.messagesTarget.lastElementChild.remove()
        }
        event.target.parentElement.querySelector('#close').click()
      }
     })
  }

  showAllUsers(event) {
    var Element = new Elements();
    this.hideAllModals();
    Element.toggleClassToElements(['#group_user_list'], ['hidden']);
    document.querySelector('#modalOpener').click();
  }

  fileUploaded(event) {
    var Element = new Elements();
    var reader = new FileReader();
    reader.onload = function () {

      var containerDiv = document.getElementById("file_preview_container");
      if (!containerDiv) {
        containerDiv = Element.createElement('div', ["rounded", "ml-4", "mb-1", "flex", "relative"], { attributes: { id: 'file_preview_container' } })
      }
      else {
        containerDiv.innerHTML = "";
        containerDiv.classList.remove('hidden');
      }
      var img = Element.createElement('img', ['h-48', 'rounded-lg', 'max-w-xl'], { attributes: { src: reader.result, draggable: false } });
      containerDiv.append(img);
      containerDiv.innerHTML += "<span class='bg-gray-500 absolute flex h-6 items-center justify-center rounded-full text-3xl text-white w-6 cursor-pointer' data-action='click->message#removeImagePreview'>&times;<span>"
      document.getElementById("conversation_input_div").prepend(containerDiv);
      document.getElementById("conversation_main").scrollTo(0, document.getElementById("conversation_main").scrollHeight);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  removeImagePreview() {
    var Element = new Elements();
    Element.toggleClassToElements(['.file-input-button'], ['hidden'], false);
    Element.toggleClassToElements(['#file_preview_container'], ['hidden'], true);
    document.querySelector(".file-input-button input").value = "";
    document.getElementById("conversation_main").scrollTo(0, document.getElementById("conversation_main").scrollHeight)
  }
}
