import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["toggleable"]

  connect() {
    useClickOutside(this)
    this.toggleClass = this.data.get("class") || "hidden"
    this.alreadyToggled = Cookies.get(this.data.get('name'))
    if ( this.alreadyToggled.length > 0 ) {
      this.handleToggleClass(this.toggleClass)
    }
  }
  toggle(event) {
    if(event.target.classList.contains("ignore__toggle")){
      return;
    }
    this.element.classList.toggle("toggled");
    event.preventDefault();
    this.handleToggleClass(this.toggleClass)
    if (Cookies.get(this.data.get("name"))) {
      Cookies.remove(this.data.get('name'))
    } else {
      Cookies.set(this.data.get('name'), 'toggled', { expires: 365, path: '/' })
    }
  }
  handleToggleClass(toggledClass) {
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(toggledClass)
    })
  }
  close(event) {
    const isOpen = this.element.classList.contains("toggled")
    isOpen && this.toggle(event);
  }
}
