/* eslint-disable no-param-reassign */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    collapsibleType: String,
  };

  get togglers() {
    return this.context.bindingObserver.bindings
      .filter((binding) => binding.action.methodName === "toggle")
      .map((binding) => binding.action.element);
  }

  get openedTogglers() {
    return this.togglers.filter((toggler) => this.isOpened(toggler));
  }

  connect() {
    this.init();
  }

  init() {
    this.updateState();
  }

  updateState() {
    this.togglers.forEach((toggler) => {
      const content = this.findContent(toggler);
      if (this.isOpened(toggler)) {
        this.show(toggler, content, false);
      } else {
        this.hide(toggler, content, false);
      }
    });
  }

  toggle(e) {
    if (this.collapsibleTypeValue === "single") {
      if (this.isOpened(e.target)) {
        this.close(e.target);
      } else {
        this.open(e.target);
      }

      e.preventDefault();
      return;
    }

    this.togglers.forEach((toggler) => {
      if (toggler.contains(e.target)) {
        if (this.isOpened(toggler)) {
          this.close(toggler);
        } else {
          this.open(toggler);
        }
      } else if (this.isOpened(toggler)) {
        this.close(toggler);
      }
    });

    e.preventDefault();
  }

  open(toggler) {
    const content = this.findContent(toggler);
    this.show(toggler, content);
  }

  close(toggler) {
    const content = this.findContent(toggler);
    this.hide(toggler, content);
  }

  show(toggler, content, transition = true) {
    if (transition) {
      content.style.height = "0px";
      content.removeEventListener("transitionend", this.transitionEnd);
      content.addEventListener("transitionend", this.transitionEnd);
      setTimeout(() => {
        content.style.height = `${content.scrollHeight}px`;
      });
    }

    this.toggleClass(toggler, content, true);
  }

  hide(toggler, content, transition = true) {
    if (transition) {
      content.style.height = `${content.scrollHeight}px`;
      content.removeEventListener("transitionend", this.transitionEnd);
      content.addEventListener("transitionend", this.transitionEnd);
      setTimeout(() => {
        content.style.height = "0px";
      });
    }

    this.toggleClass(toggler, content, false);
  }

  transitionEnd(e) {
    e.target.style.height = "";
  }

  toggleClass(toggler, content, opened) {
    if (opened) {
      toggler.classList.add("st-accordion__icon--opened");
      content.classList.add("st-accordion__content--visible");
    } else {
      toggler.classList.remove("st-accordion__icon--opened");
      content.classList.remove("st-accordion__content--visible");
    }
  }

  isOpened(toggler) {
    return toggler.matches(".st-accordion__icon--opened");
  }

  findContent(toggler) {
    const id = this.getID(toggler);
    return this.scope.findElement(`[data-accordion-id="${id}"]`);
  }

  getID(toggler) {
    return toggler.getAttribute("href").replace(/^#/, "");
  }
}
