import ApplicationController from './application_controller'

export default class extends ApplicationController {

  beforeDestroy(element) {
    element.innerText = 'Removing...';
    element.style.opacity = 1;
  }

  destroyError(element) {
    element.innerText = 'Oops, try again';
  }

}