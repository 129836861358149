import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets  = [ "form", "submit" ]
  static values   = { url: String, enabled: Boolean }

  initialize(){
    this.handleChange = debounce(this.handleChange, 500).bind(this)
  }

  handleChange() {
    if (!this.enabledValue) { return }
    const url       = this.urlValue;
    const type      = "POST";
    const data      = new FormData(this.formTarget);
    Rails.ajax({
      url,
      type,
      data,
      success: () => {
        this.enableForm();
      },
      error: () => {
        this.disableForm();
      }
    });
  }

  disableForm() {
    this.submitTargets.forEach(submit => submit.setAttribute("disabled", "disabled"));
  }

  enableForm() {
    this.submitTargets.forEach(submit => submit.removeAttribute("disabled"));
  }


}
