/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//= cable

// Rails functionality
window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("chartkick")
require("richtext")

// ChartJS
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);
global.Chart = Chart;

// Tailwind CSS
import "stylesheets/application"

import "src/actiontext"
import "src/confirm"
import "src/direct_uploads"
import "src/forms"
import "src/timezone"
import "src/tooltips"
import "src/progress_bar"

import LocalTime from "local-time"
LocalTime.start()

// Alpine.js
import "alpinejs"

import * as AlpineComponents from "alpine_components"

// Clipboard.js
import "clipboard"

// Stimulus controllers
import "controllers"

// CableReady
import CableReady from "cable_ready"

import "@fortawesome/fontawesome-free/css/all"

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Start Rails UJS
Rails.start()

window.AlpineComponents = AlpineComponents;

// This snippet ensures Rewardful attaches to forms
document.addEventListener("turbolinks:load", () => {
  if (typeof Rewardful !== 'undefined') {
    Rewardful.Forms.attach();
  }
});

document.addEventListener('stimulus-reflex:ready',() => {
  window.stimulusReady = true
})

document.addEventListener('cable-ready:after-morph',() => {
  if (window.addeventatc) {
    window.addeventatc.refresh();
  }
})

// Reinit alpine components after morph (https://cableready.stimulusreflex.com/customization#shouldmorph-callbacks)
const reinitAlpine = (options, fromEl, toEl) => {
  if (fromEl.__x) { window.Alpine.clone(fromEl.__x, toEl) }
  return true
}

CableReady.shouldMorphCallbacks.push(reinitAlpine);
