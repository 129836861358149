import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  connect() {
    if (this.hasSubmitTarget) {
      this.inputTarget.addEventListener("trix-change", (event) => {
        const inputValue = event.target.value
          .replace(/<[^>]+>|\&nbsp;/g, "")
          .trim();

        if (inputValue) {
          this.submitTarget.removeAttribute("disabled", "");
        } else {
          this.submitTarget.setAttribute("disabled", "");
        }
      });
    }
  }
}
