import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    siteId: String,
  };

  connect() {
    this.initConfig();
    window.initializeSs360();
  }

  initConfig() {
    window.ss360Configs ||= {};
    window.ss360Configs.localSearchConfig = {
      siteId: this.siteIdValue,
      searchBox: {
        selector: "#localSearchBox",
      },
      results: {
        searchQueryParamName: "ss360LocalQuery",
      },
      suggestions: {
        show: false,
      },
      queryTerm: {
        highlightColor: "#E4E5E9",
      },
      layout: {
        mobile: {
          showUrl: true,
        },
        desktop: {
          showUrl: true,
        },
        navigation: {
          position: "top",
        },
      },
      filters: {
        enabled: true,
        position: "left",
        showCounts: false,
      },
    };
  }
}
