import { Controller } from "@hotwired/stimulus";
import { Elements } from '../helpers/elements_helper'
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {

  connect() {
    this.picker = new EmojiButton({
      style: 'twemoji',
      showCategoryButtons: false,
      emojisPerRow: 6,
      rows: 5,
      recentsCount: 5
    });
    this.picker.on('emoji', this.addEmojiToTextArea);
  }
  //remove Emoji popover
  removeEmojiPopover(event){
    var popoverContents = Array.prototype.slice.call(document.getElementsByClassName("popover-content"));
    for (var i = 0; i < popoverContents.length; i++) {
      popoverContents[i].classList.add('hidden');
    }
    event.currentTarget.querySelector('.popover-content').classList.remove('hidden');
  }

  //disable emoji from being selecting again
  disableEmoji(event){
    //Remove disable-emoji class from previously selected
    if(event.currentTarget.parentElement.querySelector('.disable-emoji') != null)
      event.currentTarget.parentElement.querySelector('.disable-emoji').classList.remove('disable-emoji')

    //add disable-emoji to current one
    event.currentTarget.classList.add('disable-emoji');
  }

  //Remove current users previously selected reaction
  removeEmoji(wrapperSpan){
    var existingEmojiParent = wrapperSpan.querySelector('[data-my-reaction="true"]');
      if(existingEmojiParent) {
        var emojiCount = parseInt(existingEmojiParent.querySelector('span').textContent);
        if(emojiCount == 1)
          existingEmojiParent.remove();
        else{
          existingEmojiParent.querySelector('span').innerText = emojiCount - 1;
          existingEmojiParent.classList.remove('border', 'border-solid');
          existingEmojiParent.dataset.myReaction = false;

          if(emojiCount == 2)
            existingEmojiParent.children[1].classList.add('hidden');
        }
      }
  }

  //Add emoji reaction
  selectEmoji(event){
    //copying reaction image from emojis and adding it in a span under it
    var emojiParentDiv = event.currentTarget;
    if(emojiParentDiv.classList.contains('disable-emoji'))
      return;
    var reaction = emojiParentDiv.dataset.value
    var id = emojiParentDiv.closest('.message-container').dataset.messageId
    this.userReaction(id, reaction, this.bindUsersReaction)
    var existingEmoji = [];
    var emojiReactionDiv = emojiParentDiv.closest('.message-span').parentElement.querySelector('.emojiReactionDiv');
    var wrapperSpan;
    var Element = new Elements();

    //create a new span if one doesn't already exists for adding reactions
    if(emojiReactionDiv.children.length > 0){
      wrapperSpan = emojiReactionDiv.children[0];
      existingEmoji = wrapperSpan.getElementsByClassName(reaction);
      this.removeEmoji(wrapperSpan);
    }
    else{
      var justifyDirection = emojiParentDiv.closest('.message-span').querySelector('.receiver') ? 'justify-end' : 'justify-start';
      emojiReactionDiv.classList.add('flex', justifyDirection, 'rounded-xl', 'flex-row');
      wrapperSpan = Element.createElement('span', ['flex', justifyDirection, 'rounded-xl', 'flex-row', 'mt-1', 'hover:bg-gray-200'], {style:'max-width: max-content'});
      emojiReactionDiv.appendChild(wrapperSpan);
    }

    //If there is already same emoji exists
    if(existingEmoji.length == 0){
      var emoji = Element.createElement('span',['rounded-xl', 'w-11', 'flex', 'justify-center','border-solid', 'border'],{dataset:{"myReaction": true}});
      emoji.appendChild(Element.createElement('img',['h-8', 'w-8', 'p-1'], {attributes:{"src": emojiParentDiv.children[0].src, "title": emojiParentDiv.children[0].title, "alt": emojiParentDiv.children[0].alt}}));
      emoji.appendChild(Element.createElement('span', ['text-xs', 'font-bold', 'text-gray-500', 'hidden'], { innerText: 1}));
      wrapperSpan.appendChild(emoji);
    }
    else{
      this.modifyExistingElement(existingEmoji[0].parentElement, ['border','border-solid'], {dataset:{"myReaction": true}});
      var countSpan= existingEmoji[0].parentElement.children[1];
      countSpan = this.modifyExistingElement(countSpan, '', {innerText: parseInt(countSpan.innerText) + 1});
      countSpan.classList.remove('hidden');
    }

    //Disabling currently selected emoji to be selected again
    this.disableEmoji(event);
  }

  //For adding emoji via emoji panel to current message
  addEmojiToTextArea(event){
    var msgTextArea = document.getElementsByClassName('msg-body')[0];

    if(msgTextArea.querySelectorAll('img').length > 5){
      return false;
    }
    msgTextArea.innerHTML = msgTextArea.innerHTML.replace('<br><br>','<br>') + '<img src="'+event.url+'" alt="'+event.emoji+'" draggable="false" class="w-5 h-5 inline" />';
    setTimeout(function () {
      msgTextArea.focus();

      //Set caret at the end
      if (typeof window.getSelection != "undefined"
        && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(msgTextArea);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
      else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(msgTextArea);
        textRange.collapse(false);
        textRange.select();
      }
    }, 0);
  }

  //Opens up emoji panel to be added
  showEmojiPanel(event){
    this.picker.togglePicker();
  }

  //Adds emoji reaction under message
  userReaction(messageId, reaction, callback) {
    fetch(`/messages/user_reaction?id=${messageId}&reaction=${reaction}`)
    .then((response) => response.json())
    .then(data => callback(data))
  }

  //Remove reaction via modal
  deleteReaction(event) {
    var messageId = event.currentTarget.dataset.messageId
    this.userReaction(messageId, 'none', this.bindUsersReaction)
  }

  //Binds user reaction
  bindUsersReaction(data){
    var reactions = document.querySelector(`#message_${data.messageId}  .users--reactions`)
    var reactionList = document.querySelector(`#message_${data.messageId}  .users-reaction--list`)
    reactions.parentElement.insertAdjacentHTML('beforeend', data.reactionHtml)
    reactionList.parentElement.insertAdjacentHTML('beforeend', data.usersReactionHtml)
    reactions.remove()
    reactionList.remove()
  }

  //Hides all modal contents in message_popup partial
  hideAllModals() {
    var elementHelper = new Elements();
    elementHelper.toggleClassToElements(['#delete_content', '#users_reaction_content', '#avatar_selection', '#group_user_list'], ['hidden'], true);
  }

  //Opens up modal when reaction clicked
  openUsersReaction(event) {
    this.hideAllModals()
    var messageContainer = event.currentTarget.closest('.message-container')
    var usersReaction = messageContainer.querySelector('.users-reaction--list')
    document.querySelector('#users_reaction_content').innerHTML = usersReaction.innerHTML
    document.querySelector('#users_reaction_content').classList.remove('hidden');
    document.querySelector('#modalOpener').click()
  }
}
