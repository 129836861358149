import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "total", "quantity" ]

    connect() {
        this.totalBase = parseInt(this.data.get('base'))
        this.sumTotal()
    }

    sumTotal(event) {
        this.inputQuantity = 1
        if (this.hasQuantityTarget) {
            this.inputQuantity = parseInt(this.quantityTarget.value)
        }
        if (event) {
            this.inputQuantity = parseInt(event.currentTarget.value.trim())
        }
        if (isNaN(this.inputQuantity)) {
            this.totalTargets.forEach( target => {
                target.innerText = "No seats :("
            })
        } else {
            this.totalTargets.forEach( target => {
                target.innerText = (this.totalBase * this.inputQuantity).toLocaleString()
            })
        }
    }
}
