import ApplicationController from './application_controller'
import tippy from 'tippy.js'

export default class extends ApplicationController {
  static values = { saveableId: String, file: String };

  toggle() {
    const fileValue = this.hasFileValue ? this.fileValue : null;

    this.stimulate('SavesReflex#toggle', this.saveableIdValue, fileValue).then( () => {
      tippy(document.querySelectorAll('[data-tippy-content]'))
    })
  }
}
