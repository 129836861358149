import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "collapsed", "full" ]

  showMore(event) {
    event.preventDefault();
    this.collapsedTarget.classList.add("hidden");
    this.fullTarget.classList.remove("hidden");
  }

  showLess(event) {
    event.preventDefault();
    this.collapsedTarget.classList.remove("hidden");
    this.fullTarget.classList.add("hidden");
  }
}
