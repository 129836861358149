import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

    handleChange(e) {
        const url = this.element.action;
        const type = this.element.method;
        const data = new FormData(this.element);

        Rails.ajax({
            url,
            type,
            data,
        });
    }
}
