import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {

  connect() {
    super.connect();
    // Prevent modal close action on disconnecting. When controller is disconnected from the DOM
    this.preventCloseActionDisconnecting = (this.data.get('preventCloseActionDisconnecting') || 'false') === 'true';
    // Left sidebar
    this.leftSidebar = document.querySelector('.left-sidebar');
  }

  disconnect() {
    if (!this.preventCloseActionDisconnecting) {
      super.disconnect();
    }
  }

  lockScroll() {
    super.lockScroll();
    //Hide left sidebar on scroll lock
    if (this.leftSidebar) {
      this.leftSidebar.style.display = 'none';
    }
  }

  unlockScroll() {
    super.unlockScroll();
    // Show left sidebar on scroll unlock
    if (this.leftSidebar) {
      this.leftSidebar.style.display = '';
    }
  }
}
