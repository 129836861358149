import { Controller } from "@hotwired/stimulus";
import { startCase } from "lodash-es/string";

export default class extends Controller {
  static targets = ["select"];

  static values = {
    options: Object,
    selected: String,
  };

  refreshOptions(event) {
    this.selectTarget.innerHTML = "";
    this.optionsValue[event.detail.category].forEach((item) => {
      const opt = document.createElement("option");
      opt.value = item;
      opt.innerHTML = startCase(item);
      opt.selected = item === this.selectedValue;
      this.selectTarget.appendChild(opt);
    });
  }
}
