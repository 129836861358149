import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
	getMetaValue,
	toArray,
	findElement,
	removeElement,
	insertAfter
} from "helpers";

export default class extends Controller {
	static targets = ["input", "template", "caption", "previewsContainer"];
  static values = { id: String }

	connect() {
		this.dropZone = createDropZone(this);
		this.hideFileInput();
		this.bindEvents();
		Dropzone.autoDiscover = false;
	}

	// Private
	hideFileInput() {
		this.inputTargets.forEach((input)=>{
			input.disabled = true;
			input.style.display = "none";
		});
	}

	bindEvents() {
		this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
			}, 500);
      this.element.dispatchEvent(new CustomEvent("dropzone:processing"));
		});

		this.dropZone.on("removedfile", file => {
			file.controller && removeElement(file.controller.hiddenInput);
      this.element.dispatchEvent(new CustomEvent("dropzone:complete"));
		});

		this.dropZone.on("canceled", file => {
			file.controller && file.controller.xhr.abort();
      this.element.dispatchEvent(new CustomEvent("dropzone:complete"));
		});
	}

	removeAttachedClass() {
		this.dropZone.element.classList.remove(this.attachedClass)
	}

	get headers() {
		return { "X-CSRF-Token": getMetaValue("csrf-token") };
	}

	get url() {
		return this.inputTarget.getAttribute("data-direct-upload-url");
	}

	get maxFiles() {
		return this.data.get("maxFiles") || 1;
	}

	get maxFileSize() {
		return this.data.get("maxFileSize") || 256;
	}

	get acceptedFiles() {
		return this.data.get("acceptedFiles");
	}

	get addRemoveLinks() {
    if (this.hasIdValue) {
      return false;
    } else {
      return this.data.get("addRemoveLinks") || true;
    }
	}

	get previewTemplate() {
		return this.hasTemplateTarget == true ? this.templateTarget.innerHTML : false
	}

	get thumbnailWidth() {
		return this.data.get("thumbnailWidth") || 120;
	}

	get thumbnailHeight() {
		return this.data.get("thumbnailHeight") || 120;
	}

	get nestedFormChildIndexId() {
		return this.data.get("nestedFormChildIndexId") || null;
	}

	get attachedClass() {
		return this.data.get("attachedClass") || "dropzone--attached";
	}
}

class DirectUploadController {
	constructor(source, file) {
		this.directUpload 		= createDirectUpload(file, source.url, this);
		this.source 			    = source;
		this.file 				    = file;
		this.fileFieldName 		= this.source.inputTarget.name;
		this.captionFieldName = this.source.hasCaptionTarget ? this.source.captionTarget.name : null;
		this.uniqueIdentifier	= new Date().getTime();
	}

	start() {
		this.file.controller 	= this;
		this.hiddenInput 		  = this.createHiddenInput(this.nameHiddenInput(this.fileFieldName, this.uniqueIdentifier));
    this.emitDropzoneUploading();
		this.directUpload.create((error, attributes) => {
			if (error) {
				removeElement(this.hiddenInput);
				this.emitDropzoneError(error);
			} else {
				this.hiddenInput.value = attributes.signed_id;
        if (this.source.hasCaptionTarget) {
          this.file.previewElement.appendChild(this.createCaptionInput(this.nameHiddenInput(this.captionFieldName, this.uniqueIdentifier)));
        }
				this.emitDropzoneSuccess();
			}
		});
	}

	createHiddenInput(name) {
		const input = document.createElement("input");
		input.type 	= "hidden";
		input.name 	= name;
		insertAfter(input, this.source.inputTarget);
		return input;
	}

	createCaptionInput(name) {
		const input 		= document.createElement("input");
		input.type 			= "text";
		input.name 			= name;
		input.placeholder	= "Add a caption...";
		return input;
	}

	nameHiddenInput(fieldName, uniqueIdentifier) {
		const fieldIdentifier 	= this.source.nestedFormChildIndexId;
		return 	fieldIdentifier ?
				fieldName.replace(fieldIdentifier, uniqueIdentifier) :
				fieldName;
	}

	directUploadWillStoreFileWithXHR(xhr) {
		this.bindProgressEvent(xhr);
		this.emitDropzoneUploading();
	}

	bindProgressEvent(xhr) {
		this.xhr = xhr;
		this.xhr.upload.addEventListener("progress", event =>
			this.uploadRequestDidProgress(event)
		);
	}

	uploadRequestDidProgress(event) {
		const element = this.source.element;
		const progress = (event.loaded / event.total) * 100;
		findElement(
			this.file.previewTemplate,
			".dz-upload"
		).style.width = `${progress}%`;
	}

	emitDropzoneUploading() {
		this.file.status = Dropzone.UPLOADING;
		this.source.dropZone.emit("processing", this.file);
    this.source.element.dispatchEvent(new CustomEvent("dropzone:processing"));
	}

	emitDropzoneError(error) {
		this.file.status = Dropzone.ERROR;
		this.source.dropZone.emit("error", this.file, error);
		this.source.dropZone.emit("complete", this.file);
    this.source.element.dispatchEvent(new CustomEvent("dropzone:complete"));
	}

	emitDropzoneSuccess() {
		this.file.status = Dropzone.SUCCESS;
		this.source.dropZone.emit("success", this.file);
		this.source.dropZone.emit("complete", this.file);
    this.source.element.dispatchEvent(new CustomEvent("dropzone:complete"));
	}


}

function createDirectUploadController(source, file) {
	return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
	return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
	const options = {
		url: controller.url,
		headers: controller.headers,
		maxFiles: controller.maxFiles,
		maxFilesize: controller.maxFileSize,
		acceptedFiles: controller.acceptedFiles,
		addRemoveLinks: controller.addRemoveLinks,
		autoQueue: false,
		thumbnailWidth: controller.thumbnailWidth,
		thumbnailHeight: controller.thumbnailHeight,
	}
	controller.previewTemplate ? options.previewTemplate = controller.previewTemplate : null;
	controller.hasPreviewsContainerTarget ? options.previewsContainer = controller.previewsContainerTarget : null;
	return new Dropzone(controller.element, { ...options });
}
