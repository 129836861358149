import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { message: String }

  handleClick(e) {
    e.preventDefault();
    if (typeof Intercom != "undefined") {
      Intercom("showNewMessage", this.messageValue);
    }
  }
}
