import { useDispatch } from "stimulus-use";
import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["newTaskListButton"];

  static values = {
    currentTaskId: Number,
  };

  connect() {
    super.connect();
    useDispatch(this);

    if (this.hasCurrentTaskIdValue) {
      window.addEventListener(
        "stimulus-reflex:connected",
        this.openCurrentTask.bind(this)
      );
    }
  }

  renderNewTaskListFormSuccess() {
    this.dispatchFormReady();
  }

  renderNewTaskFormSuccess() {
    this.dispatchFormReady();
  }

  renderEditTaskListFormSuccess() {
    this.dispatchFormReady();
  }

  renderTaskSuccess() {
    this.dispatchFormReady();
  }

  onNewTaskListButtonClicked(event) {
    event.preventDefault();

    this.stimulate("TeamTasksReflex#render_new_task_list_form");
  }

  onNewTaskButtonClicked(event) {
    event.preventDefault();

    this.stimulate("TeamTasksReflex#render_new_task_form", event.target);
  }

  onDuplicateTaskButtonClicked(event) {
    event.preventDefault();

    this.stimulate("TeamTasksReflex#render_new_task_form", event.target);
  }

  onTaskListHeaderClicked(event) {
    event.preventDefault();

    this.stimulate("TeamTasksReflex#render_edit_task_list_form", event.target);
  }

  onTaskClicked(event) {
    event.preventDefault();

    this.pushToHistory(event.target.dataset);
    this.stimulate("TeamTasksReflex#render_task", event.target);
  }

  dispatchFormReady() {
    setTimeout(() => {
      this.dispatch("form-ready");
    }, 200);
  }

  pushToHistory(dataset) {
    const url = new URL(window.location);
    url.searchParams.set("task_id", dataset.taskId);
    window.history.pushState({}, "", url);
  }

  restoreUrl() {
    const url = new URL(window.location);
    url.searchParams.delete("task_id");
    window.history.pushState({}, "", url);
  }

  openCurrentTask() {
    const elem = document.querySelector(
      `[data-task-id="${this.currentTaskIdValue}"]`
    );
    if (elem) {
      elem.scrollIntoView();
      elem.click();
    }
  }
}
