import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["followLink", "unfollowLink", "unfollowText"];

  connect () {
    super.connect();
  }

  follow (event) {
    event.preventDefault();
    this.stimulate('FollowersReflex#follow', this.followLinkTarget).then(() => {
      this.unfollowLinkTarget.blur();
    });
  }

  unfollow (event) {
    event.preventDefault();
    this.stimulate('FollowersReflex#unfollow', this.unfollowLinkTarget).then(() => {
      this.followLinkTarget.blur();
    });
  }
  mouseOver() {
    this.unfollowTextTarget.innerHTML = 'Unfollow';
  }

  mouseOut() {
    this.unfollowTextTarget.innerHTML = 'Following';
  }
}
