const generateColor = require("./colors");

const generateField = (name, fieldColor, mainColor, opacity, direction) =>
  fieldColor || mainColor
    ? {
        [name]: fieldColor || generateColor(mainColor, opacity, direction),
      }
    : {};

const generateBtnColors = (kinds) => ({
  btn: {
    ...kinds.reduce((acc, kind) => {
      const {
        type,
        text,
        textFocus,
        textHover,
        bg,
        bgFocus,
        bgHover,
        border,
        borderHover,
        borderFocus,
        disabledText,
        disabledTextFocus,
        disabledTextHover,
        disabledBg,
        disabledBgFocus,
        disabledBgHover,
      } = kind;

      if (!type) return acc;

      return {
        ...acc,
        [type]: {
          ...(text ? { text } : {}),
          ...generateField("text-hover", textHover, text, 0.2, "lighten"),
          ...generateField("text-focus", textFocus, text, 0.8, "darken"),
          ...(bg ? { bg } : {}),
          ...generateField("bg-hover", bgHover, bg, 0.85, "darken"),
          ...generateField("bg-focus", bgFocus, bg, 0.85, "darken"),
          ...(border ? { border } : {}),
          ...(borderHover ? { "border-hover": borderHover } : {}),
          ...(borderFocus ? { "border-focus": borderFocus } : {}),
          ...(disabledText ? { "disabled-text": disabledText } : {}),
          ...(disabledTextFocus
            ? { "disabled-text-focus": disabledTextFocus }
            : {}),
          ...(disabledTextHover
            ? { "disabled-text-hover": disabledTextHover }
            : {}),
          ...(disabledBg ? { "disabled-bg": disabledBg } : {}),
          ...(disabledBgFocus ? { "disabled-bg-focus": disabledBgFocus } : {}),
          ...(disabledBgHover ? { "disabled-bg-hover": disabledBgHover } : {}),
        },
      };
    }, {}),
  },
});
module.exports = generateBtnColors;
