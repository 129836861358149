import ApplicationController from "./application_controller";
import Cookies from "js-cookie";
import Player from "@vimeo/player";
import { useDispatch } from "stimulus-use";

const PLAYBACK_RATE_SPEED = "playbackRateSpeed";

export default class extends ApplicationController {
  static targets = ["container"];

  static values = {
    videoId: String,
    videoHash: String,
  };

  connect() {
    super.connect();
    useDispatch(this);

    const videoUrl = `https://player.vimeo.com/video/${this.videoIdValue}?h=${this.videoHashValue}`;

    const player = new Player(this.containerTarget, {
      url: videoUrl,
      pip: false,
      autoplay: true,
      autopause: false,
      dnt: true,
    });

    player.ready().then(() => {
      this.onReady(player);
    });
    player.on("ended", this.onEnded.bind(this));
    player.on("play", this.onPlay.bind(this));
    player.on("timeupdate", this.onTimeUpdate.bind(this));
    player.on("playbackratechange", this.onPlaybackRateChange.bind(this));
  }

  onReady(player) {
    if (Cookies.get(PLAYBACK_RATE_SPEED)) {
      const playback_speed = Cookies.get(PLAYBACK_RATE_SPEED);
      player.setPlaybackRate(playback_speed);
    }
    this.dispatch("ready");
  }

  onEnded() {
    this.dispatch("ended");
  }

  onPlay() {
    this.dispatch("play");
  }

  onPlaybackRateChange(data) {
    const playback_speed = data["playbackRate"];
    Cookies.set(PLAYBACK_RATE_SPEED, playback_speed, {
      expires: 365,
      path: "/",
    });

    this.dispatch("playbackratechange", data);
  }

  onTimeUpdate(data) {
    this.dispatch("timeupdate", data);
  }
}
