/* eslint-disable no-undef */

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["newUserCard"];

  hideNewUserCard() {
    this.newUserCardTarget.remove();
  }
}
