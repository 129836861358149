import ApplicationController from "./../application_controller";
import SlimSelect from "slim-select";

export default class extends ApplicationController {
  static targets = ["input"];
  static values = {
    confirmText: String,
    initial: String,
  };

  connect() {
    new SlimSelect({
      select: this.inputTarget,
      beforeOnChange: (event) => {
        if (
          this.initialValue != "Draft" &&
          event.value == "Draft" &&
          !confirm(this.confirmTextValue)
        ) {
          return false;
        }
      },
    });
  }
}
