import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['canvas'];
  static values = { type: String, data: Object, options: Object };

  connect() {
    this.setDefaults();
    this.init();
  }

  setDefaults() {
    Chart.defaults.color = '#000';
    Chart.defaults.font.color = '#000';
    Chart.defaults.font.size = 16;
    Chart.defaults.font.family = 'proxima-nova, Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  }

  getChartOptions() {
    return this.optionsValue;
  }

  init() {
    const ctx = this.canvasTarget.getContext('2d');

    const legendMargin = {
      id: 'legendMargin',
      beforeInit(chart, legend, options) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return this.height += 20;
        };
      },
    }

    this.myLineChart = new Chart(ctx, {
      type: this.typeValue,
      data: this.dataValue,
      options: this.getChartOptions(),
      plugins: [legendMargin]
    });
  }

  disconnect() {
    this.myLineChart.destroy();
  }
}
