// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Toggle, Dropdown, Modal, Tabs, Popover } from "tailwindcss-stimulus-components"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('toggle',Toggle)
application.register('popover', Popover)

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

StimulusReflex.initialize(application, { consumer })

if (process.env.RAILS_ENV === 'development') {
  application.debug = true
  StimulusReflex.debug = true
  import('radiolabel').then(Radiolabel =>
    application.register('radiolabel', Radiolabel.default)
  )
}
