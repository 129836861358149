import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "card" ]

  connect () {
    super.connect()
  }

  afterToggleAnnouncement() {
    this.cardTarget.remove();
    Alpine.discoverUninitializedComponents(function(el){ Alpine.initializeComponent(el) });
  }
}
