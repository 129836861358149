import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selected", "dropdown", "input", "fill", "user", "template" ]

  connect() {
    this.userTemplate = this.templateTarget.content.firstElementChild.cloneNode(true)
    this.enableSearch()
    document.addEventListener("keydown", this.removeSelection.bind(this))
    // document.addEventListener("keydown", this.moveFocus.bind(this))
  }

  disconnect() {

  }

  enableSearch() {
      this.inputTarget.focus();
  }

  disableSearch() {
      this.inputTarget.blur();
      this.dropdownTarget.classList.add("hidden");
  }

  // selectionChanged(e) {
  //   this.selectedTarget.value = this.slimSelect.selected()
  // }

  updateDropdown() {
      this.dropdownTarget.innerHTML = (this.fetchUsers(this.inputTarget.value) == undefined ? '' : this.fetchUsers(this.inputTarget.value) )
  }

  fetchUsers(text, callback) {
    let userTemplate = this.userTemplate
    let component = this.data.get("component");
    component = (component == undefined) ? '' : component
    fetch(`${this.data.get("url")}.json?query=${text}&except=${this.selectedTarget.value}&component=${component}`)
      .then(response => response.json())
      .then(users => {
        let usersList = users.map(function (user) {
          let userWrapper = userTemplate.cloneNode(true);
          userWrapper.innerHTML = user.text;
          return userWrapper;
        });
        this.dropdownTarget.innerHTML = usersList.map(node => node.outerHTML).join("");
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  togglePlacement(e) {
    let el = (e.target.nodeName == "BUTTON" ? e.target : e.target.closest("button") );
    if (el.parentNode.dataset.userSearchTarget == "dropdown") {
      this.fillTarget.innerHTML += el.outerHTML
      this.inputTarget.value = ''
      this.inputTarget.focus();
      this.dropdownTarget.innerHTML = ''
    } else {
      el.remove();
    }
    this.setSelectedValues()
  }

  setSelectedValues() {
    let currentSelected = [...this.fillTarget.childNodes];
    this.selectedTarget.value = currentSelected.map(s => s.children[0].dataset.userId).join(",");
  }

  removeSelection(e) {
    if ((document.activeElement == this.inputTarget) && e.keyCode == 8 && (this.inputTarget.value == '') && (this.fillTarget.childNodes.length > 0)) {
      this.fillTarget.removeChild(this.fillTarget.lastChild);
      this.setSelectedValues();
    }
  }
}
