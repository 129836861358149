import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "source" ]

    linkToMeeting(event) {
        event.preventDefault()
        window.location.replace(event.currentTarget.dataset.url);
      }

    seeMore(event)
    {
      document.getElementById("dots").classList.add("hidden")
      document.getElementsByClassName("show-more")[0].classList.add("hidden")
      document.getElementsByClassName("show-less")[0].classList.remove("hidden")
      document.getElementById("more-agenda").classList.remove("hidden")
      document.getElementById("less-agenda").classList.add("hidden")
    }

    seeLess(event)
    {
      document.getElementById("dots").classList.remove("hidden")
      document.getElementsByClassName("show-more")[0].classList.remove("hidden")
      document.getElementsByClassName("show-less")[0].classList.add("hidden")
      document.getElementById("more-agenda").classList.add("hidden")
      document.getElementById("less-agenda").classList.remove("hidden")
    }
}
