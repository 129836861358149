import ApplicationController from "./application_controller";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

export default class extends ApplicationController {
  static targets = ["form", "annualFormContainer"];

  static values = {
    subscriptionId: String,
    subscriptionInterval: String,
    formId: String,
    email: String,
    name: String,
    teamId: String,
    teamName: String,
    customerId: String,
  };

  handleCancel(event) {
    event.preventDefault();

    if (this.subscriptionIntervalValue == "monthly") {
      this.handleMonthlySubscriptionCancelation();
    } else {
      this.handleAnnualSubscriptionCancelation();
    }
  }

  handleMonthlySubscriptionCancelation() {
    if (typeof profitwell !== 'undefined') {
      profitwell("init_cancellation_flow", {
        subscription_id: this.subscriptionIdValue,
      }).then((result) => {
        // This means the customer either aborted the flow (i.e.
        // they clicked on "never mind, I don't want to cancel"), or
        // accepted a salvage attempt or salvage offer.
        // Thus, do nothing since they won't cancel.
        if (result.status === "retained" || result.status === "aborted") {
          return;
        }

        // At this point, the customer ended deciding to cancel (i.e.
        // they rejected the salvage attempts and the salvage offer).
        // It could also be the case the widget couldn't be shown properly for
        // some reason (for which case, `result.status` will be 'error'), but that
        // shouldn't stop them from cancelling.
        this.formTarget.submit();
      });
    } else {
      this.formTarget.submit();
    }
  }

  handleAnnualSubscriptionCancelation() {
    const container = this.annualFormContainerTarget;

    if (this.hasFormIdValue && this.formIdValue !== "") {
      createWidget(this.formIdValue, {
        container: container,
        hidden: {
          stripe_subscription_id: this.subscriptionIdValue,
          stripe_customer_id: this.customerIdValue,
          email: this.emailValue,
          team_name: this.teamNameValue,
          team_id: this.teamIdValue,
          name: this.nameValue,
        },
        onSubmit: (_response) => {
          this.formTarget.submit();
        },
      });
      this.formTarget.dispatchEvent(new Event('handleCancel'));
    } else {
      this.formTarget.submit();
    }
  }
}
