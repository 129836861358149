const generateBtnColors = require("./utils/generateBtnColors");
const generateTheme1BrandColors = require("./generateTheme1BrandColors");

/* eslint-disable import/no-unresolved, import/extensions */
const theme1Kinds = require("./theme1Kinds");
const theme1Brand = require("./theme1Brand");
/* eslint-enable import/no-unresolved, import/extensions */

const generateTheme1Colors = () => ({
  ...generateTheme1BrandColors(theme1Brand),
  ...generateBtnColors(theme1Kinds),
});

module.exports = generateTheme1Colors;
