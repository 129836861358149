import ChartsController from './charts_controller';
import moment from "moment";

export default class extends ChartsController {
  getChartOptions() {
    function toggleLabel(ctx, hovered) {
      const chart = ctx.chart;
      const annotationOpts = chart.options.plugins.annotation.annotations.annotation;
      annotationOpts.label.backgroundColor = hovered ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 1)';
      annotationOpts.label.color = hovered ? 'rgba(135, 136, 140, 0.4)' : 'rgba(135, 136, 140, 1)';
      annotationOpts.label.borderColor = hovered ? 'rgba(135, 136, 140, 0.4)' : 'rgba(135, 136, 140, 1)';
      annotationOpts.borderColor = hovered ? 'rgba(135, 136, 140, 0.2)' : 'rgba(135, 136, 140, 1)';
      chart.update();
    }

    const annotation = {
      annotations: {
        annotation: {
          ...this.optionsValue.plugins.annotation.annotations.annotation,
          enter(ctx) {
            toggleLabel(ctx, true);
          },
          leave(ctx) {
            toggleLabel(ctx, false);
          }
        }
      }
    };

    const chartOptions = { ...this.optionsValue }
    chartOptions.plugins.annotation = annotation;

    const title = ( titles ) => {
      const { label } = titles[0];
      return moment(label).format('MMMM YYYY');
    };

    chartOptions.plugins.tooltip = {
      callbacks: {
        title: title
      }
    }

    chartOptions.scales.x.ticks = {
      callback: function(value) {
        const label = moment(this.getLabelForValue(value)).format('MMM')

        return label.toUpperCase();
      },
      color: "#87888C",
      font: {
        size: 14,
        weight: 600,
      }
    }

    return chartOptions;
  }
}
