addEventListener("trix-initialize", function (event) {
    new RichText(event.target)
})

class RichText {
  constructor(element) {
    this.element = element

    this.formatLink()
  }

  formatLink() {
    let linkTool = document.getElementsByClassName("btn btn-secondary btn-small mr-1")[0];
    let urlInput = document.getElementsByClassName('trix-input trix-input--dialog')[0];

    linkTool.addEventListener("click", function() {
      if (urlInput.value.startsWith("https://") || urlInput.value.startsWith("http://")) {

      } else {
        let formattedUrl = ("https://" + urlInput.value);
        urlInput.value = formattedUrl;
        console.log(urlInput.value);
      }
    })

    urlInput.addEventListener("keyup", function(event) {
      if (event.key === 'Enter') {
        if (urlInput.value.startsWith("https://") || urlInput.value.startsWith("http://")) {

        } else {
          let formattedUrl = ("https://" + urlInput.value);
          urlInput.value = formattedUrl;
          console.log(urlInput.value);
          linkTool.click();
        }
      }
    })
  }

  get toolbarElement() {
    return this.element.toolbarElement
  }
}
