import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['tab', 'select', 'option', 'panel']

    initialize() {
        this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
        this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
        this.showTab()
    }

    updateTab(event) {
        event.preventDefault()
        if (event.type === "click") {
            this.index = this.tabTargets.indexOf(event.currentTarget)
        } else if (event.type === "change") {
            this.index = this.selectTarget.selectedIndex
        }
    }

    showTab() {
        this.tabTargets.forEach((tab, index) => {
            const panel = this.panelTargets[index]
            panel.classList.toggle('hidden', index != this.index)

            if (index === this.index) {
                tab.classList.add(...this.activeTabClasses)
                tab.classList.remove(...this.inactiveTabClasses)
            } else {
                tab.classList.remove(...this.activeTabClasses)
                tab.classList.add(...this.inactiveTabClasses)
            }
        })
    }

    get index() {
        return parseInt(this.data.get('index') || 0)
    }

    set index(value) {
        this.data.set('index', value)
        this.showTab()
    }
}
