import { Controller } from "@hotwired/stimulus"
import slugify  from "slugify"

export default class extends Controller {
  static targets = [ "formOutput", "displayOutput" ]
  connect() {
    this.options = {
      lower: true
    }
  }
  handleChange(event) {
    const input                         = event.target;
    const slug                          = slugify(input.value, this.options);
    this.formOutputTarget.value         = slug;
    this.displayOutputTarget.innerHTML  = slug;
  }
}
