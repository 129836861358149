import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "listener" ]
  static values = { url: String }

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.outputTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.outputTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadRecords()
      }
    })
  }

  loadRecords() {
    Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      success: (data, status, xhr) => {
        if (xhr.status == 200) {
          this.intersectionObserver.unobserve(this.outputTarget)
          this.outputTarget.innerHTML = data.data.records;
          this.hasListenerTarget && this.reInitializeGlideCarousel();
        }
      }
    });
  }

  reInitializeGlideCarousel() {
    this.listenerTarget.dispatchEvent(new CustomEvent("skeleton:load_records:success"));
  }
}
