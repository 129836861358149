export default function communitiesRecommendations(props) {
  return {
    nextStepEnabled: false,
    nextStepSending: false,
    communities: [],

    init(dataset, watch) {
      const currentStepValue = JSON.parse(dataset.currentStepValue);

      watch("communities", this.communitiesChanged.bind(this));

      this.communities = currentStepValue.communities;
    },

    communitiesChanged(_newCommunities) {
      const communitiesCount = this.communities.length;

      if (communitiesCount < 2) {
        this.nextStepEnabled =
          this._getSelectedCommunitiesIds().length >= communitiesCount;
      } else {
        this.nextStepEnabled = this._getSelectedCommunitiesIds().length >= 2;
      }
    },

    membersCountText(membersCount) {
      return membersCount === 1 ? "1 member" : `${membersCount} members`;
    },

    buttonContent(selected) {
      return selected
        ? `<div class="flex justify-center items-baseline">
        <svg width="15" height="11" viewBox="0 0 15 11" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path fill="white" d="M13.8202 0.993489C13.647 0.99865 13.4826 1.07104 13.3619 1.19531L5.16656 9.39062L1.63791 5.86198C1.57648 5.798 1.5029 5.74692 1.42148 5.71173C1.34007 5.67654 1.25244 5.65795 1.16375 5.65704C1.07506 5.65614 0.987074 5.67294 0.904956 5.70647C0.822838 5.74 0.748235 5.78957 0.685516 5.85229C0.622797 5.91501 0.573223 5.98961 0.539697 6.07173C0.506171 6.15385 0.489367 6.24183 0.490269 6.33052C0.491171 6.41922 0.509762 6.50684 0.544951 6.58826C0.580141 6.66968 0.631223 6.74326 0.695205 6.80469L4.6952 10.8047C4.82023 10.9297 4.98978 10.9999 5.16656 10.9999C5.34334 10.9999 5.51288 10.9297 5.63791 10.8047L14.3046 2.13802C14.4009 2.04438 14.4667 1.92382 14.4934 1.79214C14.52 1.66046 14.5062 1.5238 14.4539 1.40008C14.4015 1.27635 14.313 1.17134 14.1999 1.09878C14.0868 1.02623 13.9545 0.989529 13.8202 0.993489Z"/>
          </svg>
          <span class="ml-1">Joined</span>
          </div>
      `
        : "Join";
    },

    toggle(event, communityId) {
      event.preventDefault();

      this.communities = this.communities.map((community) =>
        community.id === communityId
          ? { ...community, selected: !community.selected }
          : community
      );
    },

    nextStep(event, redirectUrl) {
      if (this.nextStepSending) {
        return;
      }
      this.nextStepSending = true;
      props.onNextStep(
        event,
        {
          communities_ids: this._getSelectedCommunitiesIds(),
        },
        redirectUrl
      );
    },

    prevStep(event, redirectUrl) {
      props.onPrevStep(
        event,
        {
          communities_ids: this._getSelectedCommunitiesIds(),
        },
        redirectUrl
      );
    },

    _getSelectedCommunitiesIds() {
      return this.communities.reduce((result, community) => {
        if (community.selected) {
          result.push(community.id);
        }
        return result;
      }, []);
    },
  };
}
