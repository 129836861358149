export default function questionsList(props) {
  return {
    frameworkQuestionAnswers: [],
    mostInterestedTopicsQuestionAnswers: [],
    frameworkQuestionAnswersStates: {},
    nextStepEnabled: false,
    nextStepSending: false,
    specialAnswerValue: null,

    init(dataset, watch) {
      const currentStepValue = JSON.parse(dataset.currentStepValue);

      this.specialAnswerValue = parseInt(dataset.specialAnswerValue, 10);

      this.frameworkQuestionAnswers =
        currentStepValue.framework_question_answers;
      this.mostInterestedTopicsQuestionAnswers =
        currentStepValue.most_interested_topics_question_answers;

      watch(
        "frameworkQuestionAnswers",
        this.frameworkQuestionAnswersChanged.bind(this)
      );

      this.frameworkQuestionAnswers =
        this._recalculateFrameworkQuestionAnswersDisabled(
          this.frameworkQuestionAnswers
        );
    },

    frameworkQuestionAnswersChanged(answers) {
      this.nextStepEnabled = this._recalculateNextStepEnabled(answers);
    },

    handleFrameworkQuestionChange(event, answerId) {
      event.preventDefault();

      let newFrameworkQuestionAnswers;

      newFrameworkQuestionAnswers = this._recalculateSelection(
        this.frameworkQuestionAnswers,
        answerId
      );

      newFrameworkQuestionAnswers =
        this._recalculateFrameworkQuestionAnswersDisabled(
          newFrameworkQuestionAnswers
        );

      this.frameworkQuestionAnswers = newFrameworkQuestionAnswers;
    },

    handleMostInterestedTopicsQuestionChange(event, answerId) {
      event.preventDefault();

      this.mostInterestedTopicsQuestionAnswers = this._recalculateSelection(
        this.mostInterestedTopicsQuestionAnswers,
        answerId
      );
    },

    nextStep(event, redirectUrl) {
      if (this.nextStepSending) {
        return;
      }
      this.nextStepSending = true;
      props.onNextStep(
        event,
        {
          frameworkQuestion: this._getSelectedAnswersValues(
            this.frameworkQuestionAnswers
          ),
          mostInterestedTopicsQuestion: this._getSelectedAnswersValues(
            this.mostInterestedTopicsQuestionAnswers
          ),
        },
        redirectUrl
      );
    },

    _recalculateNextStepEnabled(answers) {
      const selectedAnswers = this._getSelectedAnswersValues(answers);

      if (selectedAnswers.length === 0) {
        return false;
      }

      if (selectedAnswers.length === 1) {
        if (selectedAnswers.includes(this.specialAnswerValue)) {
          return true;
        }
        return false;
      }

      if (selectedAnswers.length > 1) {
        return true;
      }
    },

    _recalculateFrameworkQuestionAnswersDisabled(answers) {
      const selectedAnswers = this._getSelectedAnswersValues(answers);
      const selectedCount = selectedAnswers.length;

      return answers.map((answer) => {
        let disabled;

        if (selectedCount === 0) {
          disabled = false;
        }
        if (selectedCount === 1) {
          if (selectedAnswers.includes(this.specialAnswerValue)) {
            disabled = answer.value !== this.specialAnswerValue;
          } else {
            disabled = answer.value === this.specialAnswerValue;
          }
        }
        if (selectedCount > 1) {
          disabled = !selectedAnswers.includes(answer.value);
        }

        return { ...answer, disabled };
      });
    },

    _recalculateSelection(answers, answerId) {
      return answers.map((answer) =>
        answer.id === answerId
          ? { ...answer, selected: !answer.selected }
          : answer
      );
    },

    _getSelectedAnswersValues(answers) {
      return answers.reduce((result, answer) => {
        if (answer.selected) {
          result.push(answer.value);
        }
        return result;
      }, []);
    },
  };
}
