import ApplicationController from "./application_controller";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../stylesheets/tailwind.config";

const FORM_STEPS = {
  DYNAMIC_FORM: "dynamic_form",
  PAYMENT: "payment",
  CONFIRMATION: "confirmation",
};

export default class extends ApplicationController {
  static targets = [
    // common
    "currentStepContainer",
    // dynamic form step
    "dynamicFormContainer",
    // payment form step
    "paymentForm",
    "paymentCardholderName",
    "paymentCardholderNameError",
    "paymentCardElement",
    "paymentCardError",
    "paymentSubmitButton",
  ];

  static values = {
    // common
    applicationId: String,
    currentStepName: String,
    nextStepName: String,
    stepsData: Object,
  };

  connect() {
    // it is necessary to call StimulusReflex.register(this) in the base class to be able to use this.stimulate()
    // https://docs.stimulusreflex.com/rtfm/reflexes#calling-a-reflex-in-a-stimulus-controller
    super.connect();

    this.initializeCurrentStep();
  }

  afterReflex() {
    this.initializeCurrentStep();
  }

  nextStep(stepData) {
    this.stimulate(
      "CertificationApplicationReflex#next_step",
      this.applicationIdValue,
      this.nextStepNameValue,
      { ...this.stepsDataValue, ...stepData }
    );
  }

  initializeCurrentStep() {
    switch (this.currentStepNameValue) {
      case FORM_STEPS.DYNAMIC_FORM:
        const container = this.dynamicFormContainerTarget;

        createWidget(container.dataset.formId, {
          container: container,
          hidden: {
            application_id: this.applicationIdValue,
            email: container.dataset.email,
            team_name: container.dataset.teamName,
            team_id: container.dataset.teamId,
            name: container.dataset.name,
          },
          onSubmit: (response) => {
            this.nextStep({
              [FORM_STEPS.DYNAMIC_FORM]: {
                response_id: response.responseId,
              },
            });
          },
        });
        break;

      case FORM_STEPS.PAYMENT:
        // Accept a card payment guide link:
        // https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements

        let stripeMeta = document.querySelector('meta[name="stripe-key"]');
        if (stripeMeta === null) {
          return;
        }

        let stripeKey = stripeMeta.getAttribute("content");
        this.stripe = Stripe(stripeKey);
        let elements = this.stripe.elements();

        const fullConfig = resolveConfig(tailwindConfig);

        // The Style object: https://stripe.com/docs/js/appendix/style
        var style = {
          base: {
            color: this.customColorToString(fullConfig.theme.textColor["brand-surface-600"]),
            iconColor: this.customColorToString(fullConfig.theme.textColor["brand-primary-300"]),
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            "::placeholder": {
              color: this.customColorToString(fullConfig.theme.textColor["brand-surface-500"]),
            },
          },
        };

        const cardElement = elements.create("card", { style });
        cardElement.mount(this.paymentCardElementTarget);

        this.setCardChangeHandler(cardElement);
        this.setCardholderInputHandler();
        this.setFormSubmitHandler(cardElement);
        break;

      default:
    }
  }

  setFormSubmitHandler(cardElement) {
    this.paymentFormTarget.addEventListener("submit", (event) => {
      event.preventDefault();

      if (!this.isCardholderNameFilled()) {
        this.showCardholderNameError("Name on card is required.");
        return;
      }

      this.onPaymentSubmit(cardElement);
    });
  }

  onPaymentSubmit(cardElement) {
    const paymentIntent = this.paymentFormTarget.dataset.paymentIntent;
    const cardholderName = this.paymentCardholderNameTarget.value.trim();
    const paymentMethod = {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardholderName,
        },
      },
    };

    this.clearCardError();
    this.setLoading(true);
    this.stripe
      .confirmCardPayment(paymentIntent, paymentMethod)
      .then((result) => {
        if (result.error) {
          this.showCardError(result.error.message);
          this.setLoading(false);
        } else if (result.paymentIntent.status === "succeeded") {
          this.nextStep({
            [FORM_STEPS.PAYMENT]: {
              payment_intent_id: result.paymentIntent.id,
            },
          });
        }
      });
  }

  /** Payment step UI helpers **/

  setCardholderInputHandler() {
    this.paymentCardholderNameTarget.addEventListener("input", () => {
      this.clearCardholderNameError();
    });
  }

  setCardChangeHandler(cardElement) {
    cardElement.on("change", ({ error }) => {
      if (error) {
        this.showCardError(error.message);
      } else {
        this.clearCardError();
      }
    });
  }

  isCardholderNameFilled() {
    return !!this.paymentCardholderNameTarget.value.trim();
  }

  showCardholderNameError(text) {
    this.paymentCardholderNameErrorTarget.textContent = text;
    this.paymentCardholderNameTarget.focus();
  }

  clearCardholderNameError() {
    this.paymentCardholderNameErrorTarget.textContent = "";
  }

  showCardError(text) {
    this.paymentCardErrorTarget.textContent = text;
  }

  clearCardError() {
    this.paymentCardErrorTarget.textContent = "";
  }

  setLoading(isLoading) {
    const submitButton = this.paymentSubmitButtonTarget;
    if (isLoading) {
      submitButton.disabled = true;
      submitButton.classList.add("payment-step__submit--disabled");
    } else {
      submitButton.disabled = false;
      submitButton.classList.remove("payment-step__submit--disabled");
    }
  }

  customColorToString(colorFunction) {
    return colorFunction({
      opacityVariable: undefined,
      opacityValue: undefined
    });
  }
}
