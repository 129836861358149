// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

/* This uses the following `data-` parameters to customize the modal.
 * You can globally change the defaults below or override select
 * options in the HTML.
 *
 * data-body:     Secondary text for the modal. "This action cannot be undone"
 *                by default.
 * data-commit:   The 'confirm' button text. "Confirm" by default.
 * data-cancel:   The 'cancel' button text. "Cancel" by default.
 * data-color:    The tailwind color to base the modal off of.
 *                You may have to safelist the background and text color
 *                with purgecss.
*/
const defaults = {
  body: 'This action cannot be undone.',
  commit: 'Confirm',
  cancel: 'Cancel',
  color: 'red',
}

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1);

  var confirm = element.dataset.confirm
  var body = element.dataset.body || defaults.body
  var commit = element.dataset.commit || defaults.commit
  var cancel = element.dataset.cancel || defaults.cancel
  var color = element.dataset.color || defaults.color

  var modal = `
	<div id="${id}" class="confirm-modal">
		<div class="confirm-modal__wrapper">
			<div class="confirm-modal__overlay" aria-hidden="true">
				<div class="confirm-modal__overlay-bg"></div>
			</div>

			<div class="confirm-modal__content-wrapper" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
				<div class="confirm-modal__close-wrapper">
					<button type="button" data-behavior="cancel" class="confirm-modal__close-button">
						<span class="sr-only">Close</span>

            <svg class="h-3 w-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.0382 0.000262539C14.7899 0.00612251 14.554 0.110034 14.3821 0.289284L7.99979 6.67162L1.61746 0.289284C1.52979 0.19917 1.42494 0.127564 1.3091 0.0787066C1.19326 0.0298494 1.06879 0.00473482 0.943072 0.00485019C0.756242 0.00508158 0.573723 0.0610024 0.418829 0.165469C0.263935 0.269936 0.1437 0.418206 0.0734817 0.591338C0.00326365 0.76447 -0.0137489 0.954605 0.0246172 1.13745C0.0629833 1.3203 0.154986 1.48756 0.288873 1.61787L6.67121 8.0002L0.288873 14.3825C0.198701 14.4691 0.12671 14.5728 0.0771153 14.6876C0.0275209 14.8023 0.00132056 14.9258 4.86458e-05 15.0508C-0.00122327 15.1758 0.0224587 15.2998 0.0697079 15.4155C0.116957 15.5312 0.186824 15.6364 0.275215 15.7248C0.363607 15.8132 0.468748 15.883 0.584479 15.9303C0.700211 15.9775 0.824207 16.0012 0.949205 15.9999C1.0742 15.9987 1.19769 15.9725 1.31244 15.9229C1.42718 15.8733 1.53088 15.8013 1.61746 15.7111L7.99979 9.32879L14.3821 15.7111C14.4687 15.8013 14.5724 15.8733 14.6871 15.9229C14.8019 15.9725 14.9254 15.9987 15.0504 16C15.1754 16.0012 15.2994 15.9775 15.4151 15.9303C15.5308 15.883 15.636 15.8132 15.7244 15.7248C15.8128 15.6364 15.8826 15.5313 15.9299 15.4155C15.9771 15.2998 16.0008 15.1758 15.9995 15.0508C15.9983 14.9258 15.9721 14.8023 15.9225 14.6876C15.8729 14.5728 15.8009 14.4691 15.7107 14.3825L9.32838 8.0002L15.7107 1.61787C15.8474 1.48677 15.9413 1.31741 15.9801 1.13201C16.0189 0.946604 16.0007 0.753808 15.928 0.578907C15.8553 0.404006 15.7314 0.255164 15.5726 0.151904C15.4138 0.0486441 15.2275 -0.00421388 15.0382 0.000262539Z" fill="#87888C"/>
            </svg>
					</button>
				</div>

				<div class="confirm-modal__content">
					<div class="confirm-modal__text-wrapper">
						<h3 class="confirm-modal__title" id="modal-headline">
							${confirm}
						</h3>

            <p class="confirm-modal__text">
              ${body}
            </p>
					</div>
				</div>

				<div class="confirm-modal__actions">
					<button type="button" data-behavior="cancel" data-focus class="confirm-modal__button confirm-modal__button--cancel">
						${cancel}
					</button>
					<button type="button" data-behavior="commit" class="confirm-modal__button confirm-modal__button--${color}">
						${commit}
					</button>
				</div>
			</div>
		</div>
	</div>`

  document.body.insertAdjacentHTML('afterbegin', modal)

  var modal = document.getElementById(id)
  element.dataset.confirmModal = `#${id}`

  modal.addEventListener("keyup", (event) => {
    if(event.key === "Escape") {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    }
  })

  // Pick up both the 'X - close' and the cancel buttons
  var cancelButton = document.querySelectorAll("[data-behavior='cancel']");
  cancelButton.forEach(function(btn) {
    btn.addEventListener("click", (event) => {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    })
  })

  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => { return true }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute("data-confirm-modal")
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-focus]").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
