import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  replyToIdea(){
    window.scrollTo(0,document.body.scrollHeight);
    let editor = document.querySelector("#main_form trix-editor");
    if (editor != undefined) {
      editor.focus();
    }
  }
}
