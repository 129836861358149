/* eslint-disable dot-notation */
const defaultTailwindColors = require("tailwindcss/colors");
const defaultColor = require("./defaultColors");

const disabled = {
  disabledText: defaultColor["im-primary-gray"]["300"],
  disabledTextFocus: defaultColor["im-primary-gray"]["300"],
  disabledTextHover: defaultColor["im-primary-gray"]["300"],
  disabledBg: defaultColor["im-primary-gray"]["100"],
  disabledBgFocus: defaultColor["im-primary-gray"]["100"],
  disabledBgHover: defaultColor["im-primary-gray"]["100"],
};
// transparent change to none because tailwindcss-themer skip alpha chanel
// https://github.com/RyanClementsHax/tailwindcss-themer/blob/main/docs/themingColors.md#opacity

const disabledSimple = {
  disabledText: defaultColor["im-primary-gray"]["300"],
  disabledTextFocus: defaultColor["im-primary-gray"]["300"],
  disabledTextHover: defaultColor["im-primary-gray"]["300"],
  disabledBg: "none",
  disabledBgFocus: "none",
  disabledBgHover: "none",
};

const kinds = [
  {
    type: "create",
    text: defaultColor["im-primary-blue"]["300"],
    textFocus: defaultColor["im-primary-blue"]["300"],
    textHover: defaultColor["im-primary-blue"]["300"],
    bg: defaultColor["im-primary-blue"]["200"],
    bgFocus: defaultColor["create-button-hover"],
    bgHover: defaultColor["create-button-hover"],
    ...disabled,
  },
  {
    type: "general",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultColor["im-primary-blue"]["300"],
    bgFocus: defaultColor["im-primary-blue"]["400"],
    bgHover: defaultColor["im-primary-blue"]["400"],
    ...disabled,
  },
  {
    type: "confirmation",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultColor["im-misc-free"],
    bgFocus: defaultColor["im-misc-green"]["300"],
    bgHover: defaultColor["im-misc-green"]["300"],
    ...disabled,
  },
  {
    type: "secondary",
    text: defaultColor["im-primary-gray"]["600"],
    textFocus: defaultColor["im-primary-gray"]["600"],
    textHover: defaultColor["im-primary-gray"]["600"],
    bg: defaultColor["im-primary-gray"]["200"],
    bgFocus: defaultColor["im-primary-gray"]["300"],
    bgHover: defaultColor["im-primary-gray"]["300"],
    ...disabled,
  },
  {
    type: "pro",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultColor["im-misc-pro"],
    bgFocus: defaultColor["im-misc-pro-dark"],
    bgHover: defaultColor["im-misc-pro-dark"],
    ...disabled,
  },
  {
    type: "free",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultColor["im-misc-green"]["300"],
    bgFocus: defaultTailwindColors.emerald[700],
    bgHover: defaultTailwindColors.emerald[700],
    ...disabled,
  },
  {
    type: "dark-mode",
    text: defaultColor["im-primary-gray"]["300"],
    textFocus: defaultColor["im-primary-gray"]["600"],
    textHover: defaultColor["im-primary-gray"]["600"],
    bg: defaultColor["im-dark"]["100"],
    bgFocus: defaultTailwindColors.gray[300],
    bgHover: defaultTailwindColors.gray[300],
    ...disabled,
  },
  {
    type: "delete",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultTailwindColors.red[500],
    bgFocus: defaultTailwindColors.red[600],
    bgHover: defaultTailwindColors.red[600],
    ...disabled,
  },
  {
    type: "blaze",
    text: defaultColor["im-misc-white"],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: defaultColor["warning"]["700"],
    ...disabled,
  },
  {
    type: "simple-delete",
    text: defaultTailwindColors.red[500],
    textFocus: defaultColor["im-misc-white"],
    textHover: defaultColor["im-misc-white"],
    bg: "none",
    bgFocus: defaultTailwindColors.red[600],
    bgHover: defaultTailwindColors.red[600],
    ...disabledSimple,
  },
  {
    type: "simple-secondary",
    text: defaultColor["im-primary-gray"]["400"],
    textFocus: defaultColor["im-primary-gray"]["400"],
    textHover: defaultColor["im-primary-gray"]["400"],
    bg: "none",
    bgFocus: defaultColor["im-primary-gray"]["200"],
    bgHover: defaultColor["im-primary-gray"]["200"],
    ...disabledSimple,
  },
  {
    type: "calendar",
    text: defaultColor["im-primary-gray"]["600"],
    textFocus: defaultColor["im-primary-gray"]["600"],
    textHover: defaultColor["im-primary-gray"]["600"],
    bg: defaultColor["im-misc-white"],
    bgFocus: defaultColor["im-primary-gray"]["100"],
    bgHover: defaultColor["im-primary-gray"]["100"],
    border: defaultColor["im-primary-gray"]["200"],
    borderFocus: defaultColor["im-primary-gray"]["300"],
    borderHover: defaultColor["im-primary-gray"]["300"],
    ...disabledSimple,
  },
];

module.exports = kinds;
