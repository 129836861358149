import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use";

export default class extends Controller {
  static targets = ["overlay", "panel"];

  connect(){
    this.activeOverlayClasses = (this.data.get('activeOverlay') || 'active').split(' ');
    this.inactiveOverlayClasses = (this.data.get('inactiveOverlay') || 'inactive').split(' ');
    this.activePanelClasses = (this.data.get('activePanel') || 'active').split(' ');
    this.inactivePanelClasses = (this.data.get('inactivePanel') || 'inactive').split(' ');
    this.bodyTag = document.querySelector('body')

    useDispatch(this);
  }

  open(e) {
    e.preventDefault();

    this.overlayTarget.classList.remove(...this.inactiveOverlayClasses);
    this.overlayTarget.classList.add(...this.activeOverlayClasses);
    this.panelTarget.classList.remove(...this.inactivePanelClasses);
    this.panelTarget.classList.add(...this.activePanelClasses);

    this.bodyTag.classList.add('slide-over-opened');
  }

  close(e) {
    e.preventDefault();

    this.overlayTarget.classList.remove(...this.activeOverlayClasses);
    this.overlayTarget.classList.add(...this.inactiveOverlayClasses);
    this.panelTarget.classList.remove(...this.activePanelClasses);
    this.panelTarget.classList.add(...this.inactivePanelClasses);

    setTimeout(() => {
      this.bodyTag.classList.remove('slide-over-opened');
      this.dispatch('closed');
    }, 300)
  }
}
