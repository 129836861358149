import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["sticky"]

	connect() {
		this.setStickyHeight(this.stickyTarget)

		document.addEventListener("turbolinks:load", ()=>{
			this.setStickyHeight(this.stickyTarget)
		})

		window.addEventListener("scroll", ()=>{
			this.setStickyHeight(this.stickyTarget)
		})
	}

	setStickyHeight(el) {
		this.stickyHeight = el.offsetHeight
		this.windowHeight = window.innerHeight
		this.navHeight = document.querySelector('body > .main > nav:first-child').offsetHeight
		this.bufferHeight = 24
		this.downDeviation = this.stickyHeight - this.windowHeight + this.bufferHeight
		this.upDeviation = this.stickyHeight - this.windowHeight + this.navHeight
		this.scrollDirection = "down"
		this.scrollPosition = 0
		if (this.stickyHeight < this.windowHeight) {
			this.stickyTarget.style.top = `${ this.navHeight + this.bufferHeight }px`
		} else {
			this.stickyTarget.style.top = `-${this.downDeviation}px`
		}
	}

	onScroll(e) {
		// if ((document.body.getBoundingClientRect()).top > this.scrollPosition) {
		// 	this.stickyTarget.style.top = 'auto'
		// 	this.stickyTarget.style.bottom = `-${this.upDeviation}px`
		// 	this.scrollDirection = "up"
		// } else {
		// 	this.stickyTarget.style.bottom = 'auto'
		// 	this.stickyTarget.style.top = `-${this.downDeviation}px`
		// 	this.scrollDirection = "down"
		// }
		// this.scrollPosition = (document.body.getBoundingClientRect()).top
	}
}
