import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "wrapper", "switch", "switchon", "switchoff" ]

  connect() {
    if ( this.wrapperTarget.getAttribute("value") == "true" )
      this.turnOn()
    else
      this.turnOff()
  }

  toggle(event) {
    event.preventDefault()

    if ( this.wrapperTarget.getAttribute("value") == "true" )
      this.turnOff()
    else
      this.turnOn()
  }

  turnOn() {
    this.wrapperTarget.classList.remove("bg-gray-200")
    this.wrapperTarget.classList.add("bg-custom-green-400")

    this.switchTarget.classList.remove("translate-x-0")
    this.switchTarget.classList.add("translate-x-5")

    this.switchonTarget.classList.remove( ...("opacity-0 ease-out duration-100").split(' ') )
    this.switchonTarget.classList.add( ...("opacity-100 ease-in duration-200").split(' ') )

    this.switchoffTarget.classList.remove( ...("opacity-100 ease-in duration-200").split(' ') )
    this.switchoffTarget.classList.add( ...("opacity-0 ease-out duration-100").split(' ') )

    this.wrapperTarget.setAttribute("value", true)
  }

  turnOff() {
    this.wrapperTarget.classList.add("bg-gray-200")
    this.wrapperTarget.classList.remove("bg-custom-green-400")

    this.switchTarget.classList.add( "translate-x-0")
    this.switchTarget.classList.remove("translate-x-5")

    this.switchonTarget.classList.add( ...("opacity-0 ease-out duration-100").split(' ') )
    this.switchonTarget.classList.remove( ...("opacity-100 ease-in duration-200").split(' ') )

    this.switchoffTarget.classList.add( ...("opacity-100 ease-in duration-200").split(' ') )
    this.switchoffTarget.classList.remove( ...("opacity-0 ease-out duration-100").split(' ') )

    this.wrapperTarget.setAttribute("value", false)
  }

}
