import { snakeCase } from "lodash-es/string";
import { transformKeys } from "../helpers";
import { makeRequest } from "./helpers";

const nextStep = (args = {}) => {
  const data = transformKeys(args.data, snakeCase);
  const sanitizedArgs = { ...args, data };

  makeRequest({ url: "/onboarding/next_step", ...sanitizedArgs });
};

const previousStep = (args = {}) => {
  const data = transformKeys(args.data, snakeCase);
  const sanitizedArgs = { ...args, data };

  makeRequest({ url: "/onboarding/previous_step", ...sanitizedArgs });
};

const finish = (args = {}) => {
  const data = transformKeys(args.data, snakeCase);
  const sanitizedArgs = { ...args, data };

  makeRequest({ url: "/onboarding/finish", ...sanitizedArgs });
};

export default { nextStep, previousStep, finish };
