import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["popover", "selected", "option", "value"]

    connect() {
        this.handleClick();
        this.handleClickOutSide();
    }

    togglePopover() {
        this.popoverTarget.classList.toggle('hidden');
    }

    handleClick() {
        this.optionTargets.forEach((optionTarget)=>{
            optionTarget.addEventListener('click', () => {
                this.updateSelectedValue(optionTarget)
                this.toggleStyles(optionTarget);
            });
        });
    }

    handleClickOutSide() {
        document.addEventListener('click', (event) => {
            let isClickInside = this.element.contains(event.target);
            if (!isClickInside) this.popoverTarget.classList.add('hidden');
        });
    }

    toggleStyles(element) {
        if(!element) return;

        this.optionTargets.forEach((optionTarget)=> optionTarget.querySelector('.custom-select-menu-icon').classList.add("hidden"));
        element.querySelector('.custom-select-menu-icon').classList.remove("hidden");

        this.optionTargets.forEach((optionTarget)=> optionTarget.querySelector('.custom-select-menu-text').classList.add("font-normal"));
        this.optionTargets.forEach((optionTarget)=> optionTarget.querySelector('.custom-select-menu-text').classList.remove("font-semibold"));
        element.querySelector('.custom-select-menu-text').classList.add("font-semibold");
    }

    updateSelectedValue(element) {
        element.querySelector('.custom-select-menu-text').classList.remove("font-semibold");

        let markup = element.querySelector('.custom-select-menu-markup').innerHTML;
        let value = element.dataset.customSelectMenuId;

        this.selectedTarget.innerHTML = markup;
        this.valueTarget.value = value;
    }

}
