import ApplicationController from './application_controller';

export default class extends ApplicationController {
  toogle() {
    this.stimulate('BookmarkSaveReflex#toggle').then(() => {
      window.dispatchEvent(
        new CustomEvent('bookmark-save:callReflex:success')
      );
    });
  }
}
